/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
/* eslint-disable no-empty */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable react/jsx-key */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable */
import React, { useState , useEffect, useRef } from 'react';
import { ReactComponent as Alerticon } from '../../assets/images/no-records-found.svg';
import { customerStyles } from '../../customerStyles';
import { ActiveMailing } from '../../api/user';
import { printEPOHTML } from '../../javascript/print';
import { useHistory} from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import {
    RACModalCard,
    Grid,
    RACButton,
    Typography,
    Card,
    CardContent,
    CircularProgress
} from '@rentacenter/racstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { agreementGlobalStyles } from '../agreementGlobalStyles';
//import InfiniteScroll from 'react-infinite-scroll-component'; 
//import { History } from 'history';


export default function ActiveMailingLable() {
    const classes = customerStyles();
    const agreementGlobalStyle = agreementGlobalStyles();
    const [ValidateIsOpen, setValidateIsOpen] = useState(false);
    const [Addresspopup, setAddresspopup] = useState(false);
    const [printpopup, setprintpopup] = useState(false);
    let [valueforActiveMail, setvalueforActiveMail] = useState<any>([]);
    const [errorpopup, seterrorpopup] = useState(false);
    const [somethingWentWrongOpen, setsomethingWentWrongOpen] = useState(false);
    const [hasMore, sethasMore] = useState(true);
    const [fetchDataLoader, setfetchDataLoader] = useState(false);
    const [offset, setoffset] = useState(1);
    const [NomoreDataOtherReq, setNomoreDataOtherReq] = useState(false);
    const [printerloader, setprinterloader] = useState(false)
    const [printerenable, setprinterenable] = useState(false)
    const [loader,setloader] = useState(false)
    const history = useHistory();


    useEffect(() => {
        setValidateIsOpen(true);
        activemailingapi();
    }, [])


   let count = 1

    async function activemailingapi() {
        debugger;

        if(count == 1){
        setloader(true)
         }
        setprinterloader(true)
        // eslint-disable-next-line no-console
        console.log('Inside the api call')
        const payload = {
            storeNumber: window.sessionStorage.getItem("storeNumber"),
            limit: "200",
            offset: count.toString(),
            activeMailing: true
        }
        // eslint-disable-next-line no-console
        console.log('Payload', payload)
        const response = await ActiveMailing(payload);

        if (response?.status === 200) {

            if (response.data?.updateAddress?.value?.length == 200) {
                count++;
                valueforActiveMail = valueforActiveMail.concat(response.data.updateAddress.value);
                setvalueforActiveMail(valueforActiveMail)
                activemailingapi()
                setloader(false)
                setprinterloader(true)
                setprinterenable(false)
            }
            else {
                setvalueforActiveMail(valueforActiveMail.concat(response.data.updateAddress.value))
                seterrorpopup(false);
                setloader(false)
                setprinterloader(false)
                setprinterenable(true)
            }
            

        }
        else {
            seterrorpopup(true);
            setsomethingWentWrongOpen(true);
            setloader(false);
        }

        // eslint-disable-next-line no-console
        console.log(response, 'response')
        console.log(response?.data?.updateAddress?.value, 'Value should be printed')

    }

    function activemailingonclick() {
        setValidateIsOpen(false);
        // eslint-disable-next-line no-console
        console.log('Inside the function ');
        setAddresspopup(true);
    }


    const pageload= () =>{   
        setAddresspopup(false);
        history.push({
            pathname: `/dashboard/homepage`,
          });
    }

    const pageloadoncloseIcon= () =>{   
        setValidateIsOpen(false);
        history.push({
            pathname: `/dashboard/homepage`,
          });
    }

    const pageloadcloseIcononAddress = () =>{
        setAddresspopup(false);
        history.push({
            pathname: `/dashboard/homepage`,
          });
    }


    const somethingWentWrongPopup = () => {
        return (
            <div
                id="initialpayment"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                aria-hidden="true"
            >
                <Grid className={agreementGlobalStyle.textCenter}>
                    <Alerticon></Alerticon>
                    <Typography className={agreementGlobalStyle.formLabel}>
                        Something went Wrong
                    </Typography>
                </Grid>
                <Grid
                    item
                    md={12}
                    className={`${classes.mt3} ${agreementGlobalStyle.pb4} ${classes.textCenter}`}
                >
                    <RACButton
                        className={agreementGlobalStyle.mx1}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setsomethingWentWrongOpen(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </div>
        );
    };

    const htmlactivemailinglabel = useRef<HTMLDivElement>(null);
    const printHtmlfunction = useReactToPrint({

        content: () => htmlactivemailinglabel.current,
        pageStyle: `
        @media print {
          @page {
           margin-top: 60px !important;
          }
        }
      `

    })

    const customerAddressPopup = () => {
        return (
            <>
                <div>
                    {loader ? <div style={{ width: '96%', padding: '2%', float: 'left' , marginTop: '5px' , marginBottom : '42px'}}>
                        <div style={{ width: '33.333%', float: 'left' }}></div>
                        <div style={{ width: '33.333%', float: 'left' }}><CircularProgress style={{ marginLeft: '150%' }} ></CircularProgress></div>
                        <div style={{ width: '33.333%', float: 'left' }}></div>
                    </div> :
                        <Grid
                            className={`${classes.spacerMT4} ${classes.floatLeft}`}
                        >
                            <Grid container className="mb-12 px-2" style  = {{marginBottom: '60px'}}>
                                <Card className='card' style={{ width: '100%' }}>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid container spacing={3}>

                                                {valueforActiveMail.map((customer, index) => (
                                                    <Grid item xs={4} key={index}>
                                                        <Typography style = {{overflowWrap:'break-word'}}>{customer.lastName.toUpperCase() + ', ' + customer.firstName.toUpperCase()}  </Typography>
                                                        <Typography style={{ color: 'GrayText' , overflowWrap:'break-word' }}>{(customer.address1.toUpperCase() )+ ' ' + ( customer.address2 != null ? customer.address2.toUpperCase(): '')}</Typography>
                                                        <Typography style={{ color: 'GrayText' , overflowWrap:'break-word' }}>{customer.city.toUpperCase() + ', ' + customer.state.toUpperCase() + ' ' + customer.postalCode}</Typography>
                                                        {/* Add additional Typography components for more properties */}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>}

                    <Grid style={{
                        position: 'absolute',
                        bottom: '7px',
                        width: '98%',
                        backgroundColor: '#fff',
                        overflowX: 'hidden'
                    }} >
                        <RACButton
                            style={{
                                float: 'right', marginTop: '21px',
                                marginRight: '22px'
                            }}
                            size="small"
                            variant="outlined"
                            isRounded={false}
                            onClick={() => pageload()}
                        >
                            Cancel
                        </RACButton>
                        <RACButton
                            style={{ marginTop: '25px', marginRight: '15px', float: 'right' }}
                            disabled={printerenable ? false : true}
                            loading={printerloader ? true : false}
                            isRounded={false}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                printHtmlfunction()
                            }}
                        >
                            Print
                        </RACButton>

                    </Grid>
                </div >


               
                    <div style={{ display: 'none' }}>
                        <div id="activeMailing" ref={htmlactivemailinglabel}>
                            <table style={{ width: '8.5in' }}>
                                <tbody>
                                    <tr style={{ display: 'flex', flexWrap: 'wrap', columnGap: '19%' }}>
                                        {valueforActiveMail.map((customer, index) => (
                                            <>
                                                <td style={{ width: '18%', height: '110px', maxHeight: '115px', minHeight: '110px', pageBreakInside: 'avoid' }}>
                                                    <p style={{ marginBottom:'4px',marginTop:'2px', fontSize: '12px', fontFamily: 'Arial, sans-serif', fontWeight: "bold",  whiteSpace: 'nowrap', overflow: 'hidden', width: '200px'}}>{customer.lastName.toUpperCase() + ', ' + customer.firstName}</p>
                                                    <p style={{ marginBottom:'4px',marginTop:'2px', color: 'GrayText', fontFamily: 'Arial, sans-serif', fontSize: "11px", whiteSpace: 'nowrap', overflow: 'hidden', width: '200px'}}>{customer.address1 }</p>
                                                    <p style={{ marginBottom:'4px',marginTop:'2px', color: 'GrayText', fontFamily: 'Arial, sans-serif', fontSize: "11px", whiteSpace: 'nowrap', overflow: 'hidden', width: '200px'}}> {customer.address2} </p>
                                                    <p style={{ marginBottom:'4px',marginTop:'2px', color: 'GrayText', fontFamily: 'Arial, sans-serif', fontSize: "11px", whiteSpace: 'nowrap', overflow: 'hidden', width: '200px' }}>{customer.city + ', ' + customer.state + ' ' + customer.postalCode}</p>
                                                </td>
                                            </>

                                        ))}
                                    </tr>
                                </tbody>
                            </table>
                        </div > </div> 
            </>

        );
    };

    const printerAlertPopup = () => {
        return (
            <>
                <Grid
                    className={`${classes.spacerMT4}  ${classes.floatLeft}`}
                >
                    <Grid className={classes.textCenter}>
                        <Typography style={{ fontSize: '19px', fontFamily: 'OpenSans-bold' }}>
                            When printing the labels, only place Avery 5960 template pages in the printer
                        </Typography>
                    </Grid>
                    <Grid>
                        <RACButton style={{ marginTop: "20px", marginLeft: "250px" }}
                            variant="contained"
                            color="primary"
                            id="assignAlertBtn"
                            onClick={() => {
                                activemailingonclick()
                            }}
                        >
                            Ok
                        </RACButton>

                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <>
            <RACModalCard
                isOpen={ValidateIsOpen}
                maxWidth="sm"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => pageloadoncloseIcon()}
                // eslint-disable-next-line react/no-children-prop
                children={printerAlertPopup()}
            />
            <RACModalCard
                isOpen={Addresspopup}
                maxWidth="md"
                closeIcon={true}
                borderRadius="20px"
                onClose={() => pageloadcloseIcononAddress()}
                // eslint-disable-next-line react/no-children-prop
                children={customerAddressPopup()}
                title="Print Active Mailing Labels"
            />

            <RACModalCard
                isOpen={somethingWentWrongOpen}
                closeIcon={false}
                maxWidth="xs"
                borderRadius={"15px"}
            >
                {somethingWentWrongPopup()}
            </RACModalCard>

        </>

    )
}
