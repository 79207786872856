/* eslint-disable no-console */
import React, { createContext } from 'react';
import { Router } from 'react-router';
import { History } from 'history';
import {
  RACThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { InContainerAuthProvider } from '../context/auth/InContainerAuthProvider';
import { StandaloneAuthProvider } from '../context/auth/StandaloneAuthProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
import { CustomPropInterface } from '../../src/index';
import { FeatureFlagContextProvider } from '../context/featureFlagContext';

interface Props {
  history: History;
  storeNumber: string;
  customProps?: CustomPropInterface;
  user?: any;
}

const generateClassName = createGenerateClassName({
  seed: 'RAC-DASHBOARD-',
});
export const ContainerContext = createContext<CustomPropInterface | undefined>(
  undefined
);

// eslint-disable @typescript-eslint/no-empty-function
export const App = ({ history, storeNumber, customProps, user }: Props) => {
  console.log('Dashboard repo....', user);
  const enableLogs = window.sessionStorage.getItem('enableLogs');
  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
  const { isRenderedByContainer } = window;

  /* eslint-disable no-console */
  console.log('app dashboard', history);
  /* eslint-disable no-console */
  console.log('app dashboard store number', storeNumber);
  {
    /* eslint-disable-next-line no-irregular-whitespace */
  }
  return (
    <div>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <ContainerContext.Provider value={customProps}>
            <FeatureFlagContextProvider>
              <div className="container">
                <ErrorBoundary>
                  <Router history={history}>
                    {isRenderedByContainer ? (
                      <InContainerAuthProvider>
                        <AuthGuard />
                      </InContainerAuthProvider>
                    ) : (
                      <StandaloneAuthProvider>
                        <AuthGuard />
                      </StandaloneAuthProvider>
                    )}
                  </Router>
                </ErrorBoundary>
              </div>
            </FeatureFlagContextProvider>
          </ContainerContext.Provider>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
