/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import {
  makeStyles,
  Card,
  Grid,
  Typography,
  CardContent,
} from '@rentacenter/racstrap';
import Calender from './DapCalendar/Calender';
import { customerStyles } from './DapCalendar/customerStyles';
import { DapCalendarStyles } from './DapCalendar/DapCalendarStyles';
import Tab from './DapCalendar/Tab';
import Task from './DapCalendar/Task';
//UI
const useClasses = makeStyles((theme: any) => ({
  w100: {
    width: '100%',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '16px!important',
    marginBottom: '10px!important',
  },
  titleColor: {
    color: '#60647F',
  },
  infoTextStyle: {
    color: '#A5A6A6',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  mb4: {
    marginBottom: theme.typography.pxToRem(24),
  },

  mb5: {
    marginBottom: theme.typography.pxToRem(48),
  },
  my4: {
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },

  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  pt3: {
    paddingTop: theme.typography.pxToRem(16),
  },
  pt2: {
    paddingTop: theme.typography.pxToRem(8),
  },
  pt1: {
    paddingTop: theme.typography.pxToRem(4),
  },
}));
export default function Upcoming(props?: any) {
  const classes = useClasses();
  const [tab, setTab] = useState('calender')
  const classes1 = DapCalendarStyles()
  const customerStyleClasses = customerStyles();


  return (
    <>
      {/* <Grid  container > */}
      <Grid item className={`${classes.px2} ${classes.w100}`}>
        {/* <Card className={`${classes.w100} ${classes.card}`}> */}
        <Card
          className={`${classes1.cardForDashboard} ${customerStyleClasses.w100}`}
          style={{ borderRadius: '1rem' }}
        >
          <CardContent
            className={`${classes1.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes1.py0}`} style={{ padding: '0px' }}
          >
            {/* <CardContent>
            <Typography
              variant="h6"
              className={`${classes.title} ${classes.titleColor}`}
            >
              {props.name}
            </Typography>

            <Grid className={`${classes.infoTextStyle} ${classes.my4}`}>
              <Typography>Coming Soon!</Typography>
            </Grid>
            <Grid className={`${classes.infoTextStyle} ${classes.mb5}`}>
              <Typography>
                We are currently working on this feature and will launch soon!
              </Typography>
            </Grid>
          </CardContent> */}
            <Tab setTab={setTab} />
            {tab == 'calender' ? <Calender /> : <Task />}
            {/* <Calender /> */}
          </CardContent>
        </Card>
      </Grid>
      {/* </Grid> */}
    </>
  );
}
