/* eslint-disable prettier/prettier */
/* eslint-disable no-cond-assign */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ReactComponent as FSCUpdateClose } from '../../assets/images/fsc-update-close.svg';
import {
    makeStyles,
    Card,
    Grid,
    Typography,
    CardContent
} from '@rentacenter/racstrap';
import { GetFscContest } from '../../api/user'
import moment from 'moment';
import Marquee from "react-fast-marquee";
//UI
const useClasses = makeStyles((theme: any) => ({
    w100: {
        width: '100%',
    },
    mb2: {
        marginBottom: theme.typography.pxToRem(8),
    },
    title: {
        color: '#212529',
        fontFamily: 'OpenSans-bold!important',
        fontSize: '16px!important',
        marginBottom: '10px!important',
    },
    titleColor: {
        color: 'gray',
    },
    infoTextStyle: {
        color: 'gray',
        fontSize: theme.typography.pxToRem(14),
        textAlign: 'center',
    },
    mb3: {
        marginBottom: theme.typography.pxToRem(16),
    },
    mb4: {
        marginBottom: theme.typography.pxToRem(24),
    },

    mb5: {
        marginBottom: theme.typography.pxToRem(48),
    },
    my4: {
        marginTop: theme.typography.pxToRem(24),
        marginBottom: theme.typography.pxToRem(24),
    },
    card: {
        borderRadius: '16px!important',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
        width: '100%',
        overflow: 'inherit',
    },
    px2: {
        paddingRight: theme.typography.pxToRem(8),
        paddingLeft: theme.typography.pxToRem(8),
    },

    mt2: {
        marginTop: theme.typography.pxToRem(8),
    },
    mt3: {
        marginTop: theme.typography.pxToRem(16),
    },
    pt3: {
        paddingTop: theme.typography.pxToRem(16),
    },
    pt2: {
        paddingTop: theme.typography.pxToRem(8),
    },
    pt1: {
        paddingTop: theme.typography.pxToRem(4),
    },
    bgCard: {
        // backgroundColor: '#FFA61E',
        color: '#fff',
        borderRadius: '1rem',
        float: 'left',
        padding: '10px !important',
        height: '100%',
        width: 'inherit',
    },
    readMore: {
        color: '#fff',
        marginLeft: '5px',
        fontSize: '16px',
        marginTop: '3px',
        fontFamily: 'OpenSans-regular',
        cursor: 'pointer',
        textDecoration: 'underline',
        float: 'left',
    },
    fscUpdateClose: {
        cursor: 'pointer',
        float: 'left',
        marginTop: '2px',
    },
    alertIcon: {
        verticalAlign: 'middle',
        float: 'left',
        marginTop: '5px',
        marginRight: '4px',
    },
    fscUpdateFont: {
        fontSize: '16px',
        float: 'left',
        marginLeft: '5px',
        marginTop: '4px',
    },
    fscUpdateMessage: {
        float: 'left',
        fontFamily: 'OpenSans-regular',
        fontSize: '16px',
        marginLeft: '5px',
        marginTop: '4px',
    },
    spacerMargin: {
        margin: theme.typography.pxToRem(8),
    },
    modalFscMessage: {
        //eslint-disable-next-line sonarjs/no-duplicate-string
        fontFamily: 'OpenSans-semibold',
        fontSize: '16px',
        paddingBottom: '10px',
        paddingLeft: '13px',
    },
    fscModalTitle: {
        paddingLeft: '10px',
        fontSize: '20px',
        marginTop: '25px',
    },
    col1: {
        width: '30%',
        float: 'left',
    },
    col2: {
        width: '30%',
        float: 'left',
        marginLeft: '35px',
    },
    SDTitle: {
        fontFamily: 'OpenSans-semibold',
        fontSize: '16px',
        marginLeft: '13px',
    },
    EDTitle: {
        fontFamily: 'OpenSans-semibold',
        fontSize: '16px',
        marginLeft: '10px',
    },
    STDate: {
        fontFamily: 'OpenSans-semibold',
        fontSize: '16px',
        width: '124px',
        padding: '6px',
        marginLeft: '13px',
        background: '#d8d8fd',
        borderRadius: '5px',
        color: '#0303dd',
        marginBottom: '20px',
    },
    ENDate: {
        fontFamily: 'OpenSans-semibold',
        fontSize: '16px',
        width: '124px',
        padding: '6px',
        marginLeft: '11px',
        background: '#d8d8fd',
        borderRadius: '5px',
        color: '#0303dd',
        marginBottom: '20px',
    },
    calenderIcon: {
        float: 'left',
        marginTop: '4px',
        marginRight: '5px',
    },
    row: {
        display: "flex",
        flexWrap: "wrap",
        marginTop: "0",
        marginRight: "-0.75rem",
    },
}));
export default function FSCContest() {
    const [fscUpdateResponse, setFscUpdateResponse] = useState<any>();
    useEffect(() => {
        pageLoad()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const pageLoad = async () => {
        try {
            const storeNumber = window.sessionStorage.getItem('storeNumber');
            if (storeNumber && (typeof storeNumber == 'number' ||
                (typeof storeNumber == "string" && storeNumber.trim() != '' &&
                    !isNaN(Number(storeNumber)) && Number.isInteger(Number(storeNumber))))) {

                const fscUpdateMessage: any =
                    await GetFscContest({ storeNumber: storeNumber, type: "CONTEST" });
                const updatedArray: any = [];
                const operationCommunicationId: any = [];
                fscUpdateMessage?.data?.communicationRecords?.map(async (value: any) => {
                    if (moment(value.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY') <= moment().format('MM/DD/YYYY')) {
                        operationCommunicationId?.push(value?.operationCommunicationId)
                    }
                })
                const requestPayloadArray: any = []
                operationCommunicationId.map((value: any) => {
                    const payload = {
                        storeNumber: storeNumber,
                        type: "CONTEST",
                        operationCommunicationId: 'operationCommunicationId'
                    }
                    payload.operationCommunicationId = value.toString()
                    requestPayloadArray.push(payload)

                })
                const fscUpdateImg: any = await Promise.all(requestPayloadArray.map((val: any) => GetFscContest(val)))
                const data = fscUpdateImg.map((el: any) => el.data.communicationRecords)
                const flatArr = data.flat(Infinity)


                //   fscUpdateImg?.data?.map((data:any)=>{
                flatArr?.map(async (value: any) => {
                    value.message = value.message.replace("<p>", "<p style='margin-top:0;margin-bottom:0;'>")
                    updatedArray.push(value);
                })
                //   })
                // eslint-disable-next-line sonarjs/no-unused-collection
                setFscUpdateResponse({ ...fscUpdateResponse, fscUpdateMessage: updatedArray })
            }
        } catch (e) {
            console.log(e);
        }
    }
    const bgcolor: any = (priorityType: any) => {
        if (priorityType == "HIGH") {
            return '#E85C5C';
        }
        if (priorityType == "MEDIUM") {
            return '#FFA61E';
        }
        if (priorityType == "LOW") {
            return '#00BD26';
        }
    }
    const classes = useClasses();
    const fscUpdateCloseClick = (val: any) => {
        const fscUpdateRes = fscUpdateResponse?.fscUpdateMessage?.filter((e: any) => {
            return (
                e?.operationCommunicationId !== val
            )
        }
        )
        setFscUpdateResponse({ ...fscUpdateResponse, fscUpdateMessage: fscUpdateRes })
    };
    const fscUpdateGrid: any = () => {
        if (fscUpdateResponse !== undefined && fscUpdateResponse !== '' && fscUpdateResponse !== null &&
            fscUpdateResponse?.fscUpdateMessage !== undefined &&
            fscUpdateResponse?.fscUpdateMessage !== '' && fscUpdateResponse?.fscUpdateMessage !== null) {
            return fscUpdateResponse?.fscUpdateMessage?.map((value: any, index: any) => {
                return (
                    <>
                        <Grid key={index}>
                            <Card className={`${classes.w100} ${classes.card}`}>
                                <CardContent className={classes.bgCard} style={{ marginTop: "2px", marginBottom: "2px", backgroundColor: bgcolor(value?.prorityLevel) }}>
                                    <Grid className={classes.row}>
                                        {value.base64data !== undefined && value.base64data !== '' && value.base64data !== null ?
                                            <Grid style={{ width: "5%", marginTop: "0%", marginBottom: "-5px" }}>
                                                <img src={`data:image/png;base64,${value.base64data}`} style={{
                                                    width: "100%", height: "31px",
                                                    objectFit: 'fill'
                                                }}></img>
                                            </Grid> : null}
                                        <Grid style={{ width: "92%" }}>
                                            <Marquee gradient={false} pauseOnHover={true} >
                                                <Typography className={classes.fscUpdateMessage}>

                                                    <div dangerouslySetInnerHTML={{
                                                        __html: value?.message?.length > 165
                                                            ? value?.message.substring(0, 165) + '...'
                                                            : value?.message
                                                    }} />
                                                </Typography>
                                            </Marquee>
                                        </Grid>
                                        <Grid style={{ width: "3%" }}>
                                            <FSCUpdateClose
                                                className={classes.fscUpdateClose}
                                                onClick={() =>
                                                    fscUpdateCloseClick(value?.operationCommunicationId)
                                                }
                                            ></FSCUpdateClose>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </>
                )
            })
        }
        else {
            null
        }
    }
    return (
        <> <Grid item className={`${classes.px2} ${classes.w100}`}>
            {fscUpdateResponse !== undefined &&
                fscUpdateResponse !== '' &&
                fscUpdateResponse !== null &&
                fscUpdateResponse?.fscUpdateMessage !== undefined &&
                fscUpdateResponse?.fscUpdateMessage !== null &&
                fscUpdateResponse?.fscUpdateMessage !== ''
                ? fscUpdateGrid() : null
            }
            {/* eslint-disable-next-line react/no-children-prop */}
        </Grid>
        </>
    );
}