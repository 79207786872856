/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable react/no-children-prop */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Expandicon } from '../../assets/images/fullscreen-mode.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/No-records.svg';
import {
  makeStyles,
  Card,
  Grid,
  Typography,
  CardContent,
  RACModalCard,
  CircularProgress,
  RACButton,
  Icon,
} from '@rentacenter/racstrap';
import { GetCustomerDetail, getTextMessage } from '../../api/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
// import Erroricon from '../../assets/images/erroricon.png';

//UI
const useClasses = makeStyles((theme: any) => ({
  w100: {
    width: '100%',
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '16px!important',
    marginBottom: '10px!important',
  },
  titleColor: {
    color: '#60647F',
  },
  infoTextStyle: {
    color: '#A5A6A6',
    fontSize: theme.typography.pxToRem(13),
    textAlign: 'center',
  },
  my4: {
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
  },
  mb5: {
    marginBottom: theme.typography.pxToRem(48),
  },
  pt2: {
    paddingTop: theme.typography.pxToRem(8),
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  cardHeight: {
    height: theme.typography.pxToRem(271),
    '&:hover': {
      '& $RACexpandIcon': {
        display: 'block'
      }
    }
  },
  RACcardHeightModal: {
    marginTop: '3px !important',
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    paddingLeft: theme.typography.pxToRem(15),
    paddingRight: theme.typography.pxToRem(15),
  },
  RACPaddingBottom: {
    paddingBottom: '4px !important'
  },
  RACName: {
    fontSize: theme.typography.pxToRem(14),
    color: '#000',
    fontFamily: 'OpenSans-semibold',
    marginTop: theme.typography.pxToRem(4),
  },
  RACText: {
    marginTop: theme.typography.pxToRem(6),
    fontSize: theme.typography.pxToRem(14),
    width: '100% !important',
    // textAlign: 'justify',
  },
  RACDate: {
    fontSize: theme.typography.pxToRem(14),
    fontFamily: 'OpenSans-semibold',
    paddingTop: theme.typography.pxToRem(0),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    color: '#212529'
  },
  RACwidgetuser: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    marginTop: theme.typography.pxToRem(4),
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  PastDueColor: {
    color: '#dd4f89',
  },
  AMTextColor: {
    color: '#FFA61E',
  },
  transactionSpacing: {
    alignItems: 'center',
    marginBottom: theme.typography.pxToRem(15),
    padding: '5px',
    marginTop: '2px',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
    '&:firstchild': {
      display: 'block'
    }
  },
  firstchild: {
    borderTopLeftRadius: '5px'
  },
  fixedheadertabletext: {
    overflowY: 'auto',
    maxHeight: theme.typography.pxToRem(235),
    borderTopLeftRadius: theme.typography.pxToRem(2),
    borderBottomLeftRadius: theme.typography.pxToRem(2),
    marginTop: theme.typography.pxToRem(20),
    margin: theme.typography.pxToRem(2)
  },
  RACexpandIcon: {
    position: 'absolute',
    right: '0.5rem',
    cursor: 'pointer',
    display: 'none',
  },
  RACcardHeight: {
    height: theme.typography.pxToRem(303),
  },
  listgroup: {
    display: 'flex',
    width: '100%',
    height: theme.typography.pxToRem(197),
    flexDirection: 'column',
    paddingLeft: 0,
    marginBottom: 0,
    borderRadius: '0.25rem'
  },
  listgroupitem: {
    position: 'relative',
    display: 'block',
    // padding: '0.5rem 1rem',
    color: '#212529',
    textDecoration: 'none',
    backgroundColor: '#fff',
    cursor: 'pointer',
    marginBottom: theme.typography.pxToRem(8)
  },
  mb2: {
    marginbottom: '0.5rem !important'
  },
  racpadbrpink: {
    borderLeft: '3px solid #dd4f89 !important',
    lineHeight: 1.2
  },
  racpadbryellow: {
    borderLeft: '3px solid #FFA61E !important',
    lineHeight: 1.2
  },
  widgetMinheight: {
    height: '195px',
    overflow: 'auto',
    marginBottom: '.5rem'
  },
  RACexpandIconclose: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
  },
  RACcardText: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0px 1px 4px 0px  #0000013',
    position: 'relative',
    maxHeight: theme.typography.pxToRem(303),
    '&:hover': {
      '& $RACexpandIcon': {
        display: 'block',
      },
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  circularLoader: {
    marginTop: theme.typography.pxToRem(75),
  },
  textSpaceNorecords: {
    textAlign: 'center',
    width: '100%',
  },
  textcenterNorecords: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(60),
    width: '100%',
    fontFamily: 'OpenSans-regular',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  racErrorIcon: {
    width: '50px',
  },
  textcenterImage: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(20),
    width: '100%',
    fontFamily: 'OpenSans-semibold',
    color: '#A5A6A6',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  fontSemiBold: {
    fontFamily: 'OpenSans-semibold'
  },
  dispFlex: {
    display: 'flex',
    float: 'right'
  },
  PastDueColorForSP: {
    backgroundColor: '#dd4f89',
    width: '10px !important',
    height: '10px !important',
    float: 'left',
    marginTop: '5px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  AMTextColorforAT: {
    backgroundColor: '#FFA61E',
    width: '10px !important',
    height: '10px !important',
    float: 'left',
    marginTop: '5px',
    borderRadius: '50%',
    marginRight: '5px'
  },
  fontCol: {
    fontSize: '13px',
    color: '#4A5174',
    fontFamily: 'OpenSans-semibold'
  },
  ml2: {
    marginLeft: '8px'
  },
  childOne: {
    display: 'block',
  },
  childTwo: {
    display: 'block',
    marginLeft: '8px'
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(20),
    position: 'absolute',
    marginLeft: '310px',
  },
  icon1: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(20),
    position: 'absolute',
    marginLeft: '315px',
    top: '16px',
  },
  legendStyle: {
    float: 'left',
    marginLeft: '10px'
  },
  legendStyleModal: {
    float: 'left',
    marginLeft: '10px',
    marginBottom: '10px'
  }
}));

export default function AMtextResponse() {
  const classes = useClasses();
  const history = useHistory()
  const [textResponseModal, setTextResponseModal] = useState(false);
  const [textResponseData, setTextResponseData] = useState<any>()
  const [amLoader, setAMLoader] = useState(false)
  const [invalidRedirect, setInvalidRedirect] = useState(false)
  const [masterLoader, setMasterLoader] = useState(false)

  useEffect(() => {
    PageLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const PageLoad = async () => {
    setAMLoader(false)
    const response = await getTextMessage()
    setAMLoader(true)
    console.log('AM response data', response.data)
    // setTextResponseData(response.data != undefined && response.data.inboundMessages != undefined && response.data.inboundMessages.length > 0 ? response.data : [])
    filterMsg(response.data != undefined && response.data.inboundMessages != undefined && response.data.inboundMessages.length > 0 ? response.data : null)
    // filterMsg(mockData)
  }

  const filterMsg = (props: any) => {

    if (props) {
      const outBoundMsg = getMsgFilter(props.outboundMessages)
      const inBoundMsg = getMsgFilter(props.inboundMessages)
      const actualRes: any = []
      inBoundMsg.forEach((element: any) => {
        outBoundMsg.forEach((element1: any) => {
          if (element.parentTextMessageId == element1.textMessageId) {
            actualRes.push(element)
          }
        });
      });
      props.inboundMessages = actualRes

      console.log('actualRes', actualRes)
      setTextResponseData(props)

    } else {
      setTextResponseData([])
    }
  }
  const getMsgFilter = (props: any) => {
    const uniqueCustIn = [...new Set(props.map((item: any) => item.transactionId))];
    console.log(uniqueCustIn)

    const obj: any = [];
    //Get the unique customer message
    // eslint-disable-next-line no-var
    for (var i = 0; i < uniqueCustIn.length; i++) {
      console.log('i', uniqueCustIn[i])
      const obj1: any = {}
      obj1.customerId = uniqueCustIn[i];
      obj1.messages = [];
      // eslint-disable-next-line sonarjs/no-identical-functions
      props.forEach((el: any) => {
        if (el.transactionId == uniqueCustIn[i]) {
          obj1.messages.push(el)
        }
      })
      obj.push(obj1)
    }
    //Now we have all unique customerud with messages
    console.log('obj', obj)
    //Need to push only the latest messages
    const data: any = []

    // eslint-disable-next-line sonarjs/no-identical-functions
    const sortMessages: any = (data: any) => {
      // eslint-disable-next-line sonarjs/no-identical-functions
      const resOut = data.sort(function (a: any, b: any) {
        const c: any = new Date(a.messageDate);
        const d: any = new Date(b.messageDate);
        return d - c;
      });
      return resOut[0]
    }
    // eslint-disable-next-line sonarjs/no-identical-functions
    obj.forEach((el: any) => {
      const res: any = sortMessages(el.messages)
      console.log('sorted messages', res);
      data.push(res)
    })

    console.log('data out', data);
    return data;

  }
  const textResponsePopup = () => {
    return (
      <Grid container spacing={0}>
        <Icon onClick={PageLoad} classes={{ root: classes.icon1 }}>
          <FontAwesomeIcon icon={faSync} />
        </Icon>
        <Expandicon
          className={classes.RACexpandIconclose}
          data-bs-toggle='modal'
          data-bs-target='#TextResponse'
          onClick={() => setTextResponseModal(false)}
          data-testid='expandTextClose'
          key='gridcomponentIcon'
        ></Expandicon>
        <Grid className={classes.legendStyleModal}>
          <Grid style={{ float: 'right', display: 'flex' }}>
            <Grid style={{ marginRight: '10px' }}><span className={classes.PastDueColorForSP}></span><span className={classes.fontCol}>Past Due Response</span></Grid>
            <Grid style={{ marginRight: '13px' }}><span className={classes.AMTextColorforAT}></span><span className={classes.fontCol}>Account Management Response</span></Grid>
          </Grid>
        </Grid>
        {expandTextResponseModal()}
      </Grid>
    )
  }

  const messageClick = async (_index: any) => {
    setMasterLoader(true)
    const obj = textResponseData.inboundMessages[_index]
    const filteredData = textResponseData != undefined && textResponseData.outboundMessages != undefined && textResponseData.outboundMessages.length > 0 ? textResponseData.outboundMessages.filter(
      (item: any) =>
        item.textMessageId === obj.parentTextMessageId
    ) : ''
    if (filteredData[0] != undefined && filteredData[0].callBackParams != undefined && filteredData[0].callBackParams.globalCustomerId != undefined && filteredData[0].callBackParams.globalCustomerId != '') {
      const response = await GetCustomerDetail(filteredData[0].callBackParams.globalCustomerId)
      setMasterLoader(false)
      if (response.data != undefined && response.data.customerId != undefined && response.data.customerId != '') {
        history.push({
          pathname: `/am/customer/${response.data.customerId}`
        })
      }
      else {
        setMasterLoader(false)
        setInvalidRedirect(true)
      }
    }
    else {
      setMasterLoader(false)
      setInvalidRedirect(true)
    }
  }

  const expandTextResponseModal = () => {
    return textResponseData != undefined && textResponseData.inboundMessages != undefined && textResponseData.inboundMessages.length > 0 ? textResponseData.inboundMessages.map((obj: any, index: any) => {
      return (

        <>
          <Grid
            item
            className={`${classes.px2}  ${classes.w100}`}
            key=''
            onClick={() => messageClick(index)}
          >

            <Grid item md={12} className={` ${classes.RACcardText} ${classes.mb2}`}>
              <Grid className={`${classes.transactionSpacing} ${obj.messageType !== '2WAYTXT' ? classes.racpadbrpink : classes.racpadbryellow} ${classes.mb2}`}>
                <CardContent className={`${classes.RACcardHeightModal}  ${classes.RACPaddingBottom} `}>
                  {/* <Typography variant="subtitle2" className={`${classes.RACwidgetuser} ${obj.messageType !== '2WAYTXT' ? classes.PastDueColor : classes.AMTextColor}`}>
      {obj.messageType == '2WAYTXT' ? 'Account Management Text Response' : 'Past Due Reponse'}
    </Typography> */}

                  <Typography variant="body2" className={classes.RACName}>
                    {MessengerName(obj)}
                    <Typography className={classes.RACDate}>
                      {moment(obj.messageDate).format('MM/DD/YYYY')}
                    </Typography>
                  </Typography><Grid container spacing={0} className={classes.RACText}>
                    <Grid item md={8}>
                      {obj.message}
                    </Grid>
                    <Grid item md={4}>
                      <Typography className={classes.RACDate}>{moment(obj.messageDate).format('hh:mm A')}</Typography>
                    </Grid>
                  </Grid>

                </CardContent>
              </Grid>
            </Grid>
          </Grid></>
      )
    }) : null
  }

  const MessengerName = (obj: any) => {
    const filteredData = textResponseData != undefined && textResponseData.outboundMessages != undefined && textResponseData.outboundMessages.length > 0 ? textResponseData.outboundMessages.filter(
      (item: any) =>
        item.textMessageId === obj.parentTextMessageId
    ) : ''
    return ((filteredData[0] != undefined && filteredData != undefined && filteredData[0].callBackParams != undefined && filteredData[0].callBackParams.firstName != undefined ? filteredData[0].callBackParams.firstName : '') + ' ' + (filteredData[0] != undefined && filteredData != undefined && filteredData[0].callBackParams != undefined && filteredData[0].callBackParams.lastName != undefined ? filteredData[0].callBackParams.lastName : ''))
  }

  const ErrorClose = () => {
    setInvalidRedirect(false)
  }

  const openInvalidPopup = () => {
    return (
      <Grid>
        <Grid className={classes.textCenter}>
          <AlertIcon className={classes.racErrorIcon}></AlertIcon>
          <Typography className={classes.racErrorFunction}>
            Customer data is Invalid.
          </Typography>
        </Grid>
        <Grid className={classes.textCenter}>
          <RACButton
            autoFocus
            variant="contained"
            color="primary"
            onClick={ErrorClose}
          >
            OK
          </RACButton>
        </Grid>
      </Grid>
    )
  }


  return (
    <>
      {masterLoader ? <Grid
        style={{
          position: 'fixed',
          background: '#f7f5f5',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          opacity: 0.6,
          zIndex: 1050,
          textAlign: 'center',
          margin: '0px 0px',
        }}
      >
        <Grid
          style={{
            display: 'block',
            position: 'fixed',
            zIndex: 9999999,
            top: '40%',
            right: '50%',
          }}
        >
          <CircularProgress />
        </Grid>
      </Grid> : null}
      <Grid item className={`${classes.px2}  ${classes.w100}`}>

        <Card
          className={`${classes.w100}  ${classes.card}  ${classes.cardHeight}`}
        >

          {textResponseData != undefined && textResponseData.inboundMessages != undefined && textResponseData.inboundMessages.length > 0 ?
            <Expandicon
              className={classes.RACexpandIcon}
              data-bs-toggle='modal'
              data-bs-target='#TextResponse'
              onClick={() => setTextResponseModal(true)}
              data-testid='expandtextResponse'
              key='gridcomponentIcon'
            ></Expandicon>
            : null}
          <Typography
            variant="h6"
            style={{ marginLeft: '10px', marginTop: '10px' }}
            className={`${classes.title}`}
          >
            <Icon onClick={PageLoad} classes={{ root: classes.icon }}>
              <FontAwesomeIcon icon={faSync} />
            </Icon>
            Account Management Text Response
          </Typography>
          <Grid className={classes.legendStyle}>
            <Grid style={{ float: 'right', display: 'flex' }}>
              <Grid style={{ marginRight: '10px' }}><span className={classes.PastDueColorForSP}></span><span className={classes.fontCol}>Past Due Response</span></Grid>
              <Grid style={{ marginRight: '13px' }}><span className={classes.AMTextColorforAT}></span><span className={classes.fontCol}>Account Management Response</span></Grid>
            </Grid>
          </Grid>
          <CardContent>

            {/* AM Content starts */}
            <Grid item md={12} className={`${classes.fixedheadertabletext} ${classes.widgetMinheight}`}>
              {textResponseData != undefined && textResponseData.inboundMessages != undefined && textResponseData.inboundMessages.length > 0 && amLoader ? textResponseData.inboundMessages.map((obj: any, index: any) => {
                return (
                  <Grid className={`${classes.listgroupitem}`} key='' onClick={() => messageClick(index)}
                    aria-current='true'>
                    <Grid className={`${classes.transactionSpacing} ${obj.messageType !== '2WAYTXT' ? classes.racpadbrpink : classes.racpadbryellow} ${classes.mb2}`}>
                      <CardContent className={`${classes.RACcardHeightModal}  ${classes.RACPaddingBottom} `}>
                        {/* <Typography variant="subtitle2" className={`${classes.RACwidgetuser} ${obj.messageType !== '2WAYTXT' ? classes.PastDueColor : classes.AMTextColor}`}>
                          {obj.messageType == '2WAYTXT' ? 'Account Management Text Response' : 'Past Due Reponse'}
                        </Typography> */}
                        <Typography variant="body2" className={classes.RACName}>
                          {MessengerName(obj)}
                          <Typography className={classes.RACDate}>{moment(obj.messageDate).format('MM/DD/YYYY')}</Typography>
                        </Typography>
                        <Grid container spacing={0} className={classes.RACText}>
                          <Grid item md={8}>
                            {obj.message}
                          </Grid>
                          <Grid item md={4}>
                            <Typography className={classes.RACDate}>{moment(obj.messageDate).format('hh:mm A')}</Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Grid>
                  </Grid>
                )
              }) : !amLoader ? <Grid className={`${classes.circularLoader} ${classes.textCenter} ${classes.w100}`}>
                <CircularProgress />
              </Grid> : <Grid className={classes.textSpaceNorecords}>
                <Grid className={classes.textcenterNorecords}><InfoIcon className={classes.racErrorIcon}>
                </InfoIcon></Grid>
                <Grid className={classes.textcenterImage}>No Records Found</Grid>
              </Grid>}
            </Grid>
            {/* AM Content ends */}
          </CardContent>
        </Card>
        <RACModalCard
          isOpen={textResponseModal}
          maxWidth="sm"
          dialogOptionalProps={{ open: textResponseModal, disableBackdropClick: false }}
          onClose={() => setTextResponseModal(false)}
          closeIcon={false}
          //eslint-disable-next-line react/no-children-prop
          children={textResponsePopup()}
          title="Account Management Text Response"
          borderRadius="20px !important"
        />
        <RACModalCard
          closeIcon={true}
          borderRadius='20px'
          isOpen={invalidRedirect}
          onClose={ErrorClose}
          maxWidth={"xs"}
          title={''}
          children={openInvalidPopup()} />
      </Grid>
    </>
  );
}
