import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { ApiUrls, appConfig } from '../config/app-config';

const storeNumber = window.sessionStorage.getItem('storeNumber');

export async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  apiUrl: ApiUrls[keyof ApiUrls] = appConfig.apiUrls.micro,
  needAccessToken = false
) {
  const jwtToken = await getJWToken();
  const headers: any = {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    'Content-Type': 'application/json; charset=UTF-8',
    storeNumber: storeNumber,
  };
  if (needAccessToken) {
    const accessToken =
      window.localStorage.getItem('ac') || (await getAccessToken());
    headers.accesstoken = accessToken;
  }
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }
  return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response.data
  );
}

export async function client1(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: any,
  // access token is required for accessing AM APIs, but not for general ones (e.g. user APIs)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  needAccessToken = true
) {
  const jwtToken = await getJWToken();
  const accessToken =
    window.localStorage.getItem('ac') || (await getAccessToken());

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8',
    AccessToken: accessToken,
    storeNumber: storeNumber,
  };

  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
    (response) => response
  );
}

export async function clientCall(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: any,
  // access token is required for accessing AM APIs, but not for general ones (e.g. user APIs)
  AccessToken?: string,
  needAccessToken = true
) {
  try {
    const jwtToken = await getJWToken();
    // eslint-disable-next-line no-console
    console.log('StoreNumber in client', storeNumber);
    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storeNumber: window.sessionStorage.getItem('storeNumber'),
    };

    if (needAccessToken) {
      const accessToken =
        window.localStorage.getItem('ac') || (await getAccessToken());

      headers.accesstoken = accessToken;
    }
    if (jwtToken) {
      headers.Authorization = `Bearer ${jwtToken}`;
    }

    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };

    if (body) {
      requestConfig.data = JSON.stringify(body);
    }

    return await axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response) => {
        // eslint-disable-next-line no-console
        console.log('api call response positive', response);
        return response;
      }
    );
  } catch (e: any) {
    return e.response;
  }
}
export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
