/* eslint-disable prettier/prettier */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { DashboardContextProvider } from '../context/DashboardContext';
//import { PermissionsProvider } from '../context/permission/PermissionsProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import SecondFactor from '../components/SecondFactorAuthentication/TwoFactorPopup';
import { DashboardComponent } from '../components/ReactComponents/DashboardComponent';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    <UserFetcher />
    <DashboardContextProvider>
    <div data-testid={routesTestId}>
      <Switch>
        <Route exact path={AppRoute.Index}>
          <Redirect to={AppRoute.DashboardComponent} />
        </Route>
        <Route exact path={AppRoute.ActiveMailing}>
            <DashboardComponent />
        </Route>
        <Route path={AppRoute.DashboardComponent}>
          <DashboardComponent />
        </Route>
        <Route path={AppRoute.TwoFactor} key="/pinauth">
          <SecondFactor />
        </Route>
      </Switch> 
    </div>
    </DashboardContextProvider>
  </UserProvider>
);
