/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-console */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-nested-template-literals */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useContext } from 'react';
import {
    Card,
    Grid,
    CardContent,
    ListItem,
    List,
    Box,
} from '@rentacenter/racstrap';
import { customerStyles } from './customerStyles';
import { DapCalendarStyles } from './DapCalendarStyles';
import { FeatureFlagContext } from '../../../context/featureFlagContext';
enum tabList {
    CALENDAR,
    GOALS
}
function Tab(props: any) {
    const customerStyleClasses = customerStyles();
    const classes = DapCalendarStyles();
    const { taskandGoals } = useContext(FeatureFlagContext)
    const [activetab, setactivetab] = useState(
        {
            calender: true,
            tasks: false,
            goals: false
        }

    );

    const { setTab } = props;

    return (
        <Grid container>
            <Card
                className={`${classes.cardForDashboard} ${customerStyleClasses.w100} ${customerStyleClasses.borderRadiusZero}`}
            >
                {/* <Card className={`${classes.w100} ${classes.card1}`}> */}

                <CardContent
                    className={`${classes.headerTab} ${customerStyleClasses.floatLeft} ${customerStyleClasses.w100} ${classes.py0}`}
                >
                    <Grid
                        item
                        className={`${customerStyleClasses.floatLeft} ${customerStyleClasses.w100}`}
                    >
                        <Box className={customerStyleClasses.floatLeft}>
                            <List
                                className={`${classes.racpadTab} ${classes.py0}`}
                                id="CustTab"
                                role="tabList"
                                style={{ justifyContent: 'space-between' }}
                            >
                                <ListItem
                                    className={
                                        activetab.calender
                                            ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                            : `${classes.navLinkItem} ${classes.p3}`
                                    }
                                    style={{ color: 'black' }}
                                    id="Customerinfo-tab"
                                    data-testid="customerInfoTab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#customerinfo"
                                    role="tab"
                                    aria-controls="Customerinfo-tab"
                                    aria-selected="true"
                                    onClick={() => { setactivetab({ ...activetab, calender: true, tasks: false, goals: false }); setTab('calender') }}                                 >
                                    Upcoming Events
                                </ListItem>
                                {taskandGoals == '1' ?
                                    <ListItem
                                        className={
                                            activetab.tasks
                                                ? `${classes.navLinkActive} ${classes.navLinkItem}`
                                                : `${classes.navLinkItem} ${classes.p3}`
                                        }
                                        style={{ color: 'black' }}
                                        id="Customerinfo-tab"
                                        data-testid="customerInfoTab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#customerinfo"
                                        role="tab"
                                        aria-controls="Customerinfo-tab"
                                        aria-selected="true"
                                        onClick={() => { setactivetab({ ...activetab, calender: false, tasks: true, goals: false }); setTab('Task') }}                                    >
                                        Upcoming Tasks
                                    </ListItem> : null}

                            </List>

                        </Box>
                    </Grid>
                </CardContent>

            </Card>
        </Grid>)
}

export default Tab;