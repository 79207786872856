/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-dupe-keys */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { makeStyles } from "@rentacenter/racstrap";
export const fieldDeviceStyles = () => {
  const useClasses = makeStyles((theme) => ({
    DAPcardwidgetmain: {
      alignItems: 'center!important',
      height: 'auto!important'
    },
    [theme.breakpoints.between(850, 1281)]: {
      positionRelative: {
        position: 'relative'
      },
      spacingDAC: {
        paddingRight: '0px'
      },
      leftContainer: {
        width: '60%'
      },
      rightContainer: {
        width: '40%'
      },
      legendstyles: {
        width: '100% !important'
      },
      DAPresponsivefilter: {
        height: '100%'
      },
      legendstyles: {
        width: '100% !important',
        marginTop: '10px'
      },
      eventtimecontainer: {
        width: '20% !important'
      },
      eventitemcontainer: {
        width: '76% !important'
      },
      responsiveeventcontainer: {
        display: 'inline-block !important',
        width: '100%'
      },
      DAPcardwidget: {
        width: '49% !important',
        fontSize: '13px !important'
      },
      w100Px: {
        width: '95px !important',
        fontSize: '11px !important'
      },
      DAPeventcardfont: {
        fontSize: '13px !important'
      },
      DAPcardwidgetmain: {
        marginTop: '20px',
        height: 'auto !important',
        alignItems: 'center!important'
      },
      DAPlunchbreak: {
        width: '80%!important'
      },
      breaktext: {
        width: '15%!important'
      },
      DAPextraevents: {
        paddingLeft: '5px'
      },
      popupcard: {
        width: '50%!important'
      },
      eventCard: {
        width: "49% !important",
        height: "70px !important",
      },
      salesleadPopupTbl: {
        "& thead": {
          "& tr": {
            "& th": {
              "&:nth-child(6)": {
                display: "none",
              },
              "&:nth-child(9)": {
                display: "none",
              },
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td": {
              "&:nth-child(6)": {
                display: "none",
              },
              "&:nth-child(9)": {
                display: "none",
              },
            },
          },
        },
      },
      widthtab50: {
        width: "50% !important",
      },
      widthtab100: {
        maxWidth: "100% !important",
        flexBasis: "100% !important",
      },
      dTabNone: {
        display: "none",
      },
      eventTimeContainer: {
        width: "18% !important",
      },
      eventCardContainer: {
        width: "75% !important",
      },
      ev: {
        "& > :nth-last-child(-n+2)": {
          display: "none !important",
        },
      },

      listgroupitemTab: {
        width: "100% !important",
      },
      wTab50: {
        width: "50% !important",
      },
      tabFlexWrap: {
        display: "flex !important",
        flexWrap: "wrap !important",
      },
      meTab4: {
        marginRight: "24px",
      },
      salesGrid: {
        "& thead": {
          "& tr": {
            "& th:nth-last-child(-n+2)": {
              display: "none",
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td:nth-last-child(-n+2)": {
              display: "none",
            },
          },
        },
      },
    },
    [theme.breakpoints.between(599, 850)]: {
      positionRelative: {
        position: 'relative'
      },
      leftContainer: {
        width: '100%'
      },
      rightContainer: {
        width: '100%'
      },
      DAPresponsivefilter: {
        display: 'inline-block',
        height: '100%'
      },
      legendstyles: {
        width: '100% !important',
        marginTop: '10px'
      },
      responsiveeventcontainer: {
        display: 'inline-block !important',
        width: '100%'
      },
      eventtimecontainer: {
        width: '100% !important',
        height: 'auto !important'
      },
      eventitemcontainer: {
        width: '96% !important'
      },
      DAPcardwidget: {
        width: '45% !important',
        fontSize: '12px !important'
      },
      w100Px: {
        width: '90px !important',
        fontSize: '11px !important',
      },
      DAPcardwidget: {
        display: 'inline-block !important',
        height: 'auto !important'
      },
      DAPmargintopcustom: {
        marginTop: '20px !important',
        marginBottom: '10px',
        whiteSpace: 'nowrap'
      },
      DAPsmallcardwidget: {
        width: '48% !important'
      },
      DAPeventcardparent: {
        height: '100% !important'
      },
      DAPcardwidgetmain: {
        height: 'auto !important',
        alignItems: 'center!important'
      },
      breaktext: {
        width: '20%!important'
      },
      DAPeventcardfont: {
        fontSize: '12px !important'
      },
      DAPextraevents: {
        paddingLeft: '5px!important'
      },
      popupcard: {
        width: '50%!important'
      },
      badgefontsize: {
        fontSize: '11px',
        width: '96px'
      },
      fontsize13: {
        fontSize: '13px!important'
      },
      eventnamewidth: {
        width: '55%!important'
      },
      eventbadgewidth: {
        width: '45%!important'
      },
      timeSize:{
        fontSize:'12px'
      },
      w77Px:{
        width: "75px !important",
      },
      eventCard: {
        width: "49% !important",
        height: "70px !important",
      },
      salesleadPopupTbl: {
        "& thead": {
          "& tr": {
            "& th:nth-last-child(-n+6)": {
              display: "none",
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td:nth-last-child(-n+6)": {
              display: "none",
            },
          },
        },
      },
      widthtab50: {
        width: "100% !important",
      },
      dTabNone: {
        display: "none",
      },
      eventCardContainer: {
        width: "69% !important",
      },
      ev: {
        "& > :nth-last-child(-n+3)": {
          display: "none !important",
        },
      },
      listgroupitemTab: {
        width: "100% !important",
      },
      wTab50: {
        width: "100% !important",
      },
      salesGrid: {
        "& thead": {
          "& tr": {
            "& th:nth-last-child(-n+4)": {
              display: "none",
            },
          },
        },
        "& tbody": {
          "& tr": {
            "& td:nth-last-child(-n+4)": {
              display: "none",
            },
          },
        },
      },
      popCalCard: {
        width: "48% !important",
      },
    },
    eventCard: {
      width: "24%",
    },
    positionRelative: {
      position: "relative",
    },
    positionAbsolute: {
      position: "absolute",
    },
    actionsList: {
      bottom: "60px",
      right: "0px",
      backgroundColor: `#fff`,
      width: "227px",
      borderRadius: "8px",
      boxShadow: "0 1px 4px 0 rgba(0,0,0,.074)",
      border: "1px solid #EAF0F4",
      "& li:hover": {
        backgroundColor: `#DBE6FF`,
        "& a": {
          textDecoration: "none !important",
          display: "block",
          width: "100%",
          color: "#000",
        },
      },
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useClasses();
};
