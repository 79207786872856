/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import OnlineTransaction from './OnlineTransactionComponent';
import Gridcomponent from './SalesLead';
import UpcomingEvents from './upcoming';
import StoreActionItem from '../ReactComponents/storeAction';
import AMtextResponse from '../ReactComponents/AMtextResponse';
import CashManagementPopups from '../ReactComponents/CashManagementPopups';
import { Grid, Card, Typography, CardContent, RACModalCard, RACButton } from '@rentacenter/racstrap';
import { makeStyles, CircularProgress } from '@rentacenter/racstrap';
import StatsBar from './StatsBar';
import { DapCalendarStyles } from './DapCalendar/DapCalendarStyles';
import { GetStatsStatus, getmenuandstore } from '../../api/user';
import { useState } from 'react';
import { FeatureFlagContext } from '../../context/featureFlagContext';
import FSCUpdate from './FscUpdates';
import FSCContest from './FscContest';
import { UserStateContext } from '../../context/user/user-contexts'
import { fieldDeviceStyles } from '../fieldDeviceStyles';
import ActiveMailingLable from './ActiveMailing';

const useClasses = makeStyles((theme: any) => ({
  spacerMR2: {
    marginRight: theme.typography.pxToRem(8),
  },
  foc: {
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '1px 1px 6px 3px #80bdff',
    },
  },
  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  floatLeft: {
    float: 'left',
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  w100: {
    width: '100%',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '16px!important',
    marginBottom: '10px!important',
  },
  titleColor: {
    color: '#60647F',
  },
  infoTextStyle: {
    color: '#A5A6A6',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  mb4: {
    marginBottom: theme.typography.pxToRem(24),
  },

  mb5: {
    marginBottom: theme.typography.pxToRem(48),
  },
  my4: {
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  pt3: {
    paddingTop: theme.typography.pxToRem(16),
  },
  pt2: {
    paddingTop: theme.typography.pxToRem(8),
  },
  pt1: {
    paddingTop: theme.typography.pxToRem(4),
  },

}));
export function DashboardComponent() {
  const classes2 = DapCalendarStyles()
  const classes1 = fieldDeviceStyles();
  const UserStateContextData = useContext(UserStateContext);
  const classes = useClasses();
  const history = useHistory();


  const urlparams = window.location.href;
  console.log('urlparams', urlparams)
  const activemailing = urlparams.includes('/activemailing')
  console.log('activemailing', activemailing)

  // PS_02
  const { fieldDeviceParamKey,
    setfieldDeviceParamKey, setpilotStoreParamKey, setUpcomingDapParamKey, setTaskandGoals, setCustomerSurveyParamKey, setCustomerVerification } = useContext(FeatureFlagContext)

  // const user =  useUserStateContext();
  // const userThunk =  useUserThunksContext();
  const [statsParamValue, setStatsParamValue] = useState('');
  const [storeActionItemParamKey, setStoreActionItemParamKey] = useState('');
  const [salesLeadParamKey, setSalesLeadParamKey] = useState('');
  const [amTextResponseParamKey, setAMTextResponseParamKey] = useState('');
  const [onlineTransactionParamKey, setOnlineTransactionParamKey] = useState('');

  const [loader, setLoader] = useState(false)
  const [hiddenLoader, sethiddenLoader] = useState(true)
  const [isCashmgmtisPresent, setIsCashmgmtisPresent] = useState(false)
  const [hasPinConfigured, setHasPinConfigured] = useState(false)
  // useEffect(() => {console.log("UserCONTEXTS userindex", user)},[user])
  // useEffect(() => {console.log("UserCONTEXTS userDetails", userThunk)},[userThunk])
  useEffect(() => {
    const PageLoad = async () => {
      const storeId = window.sessionStorage.getItem('storeNumber')
      const payload: any = {
        storeNumbers: [window.sessionStorage.getItem('storeNumber')],
        paramKeyNames: ['Stats', 'StoreActionItem', 'DapUpcomingEvents', 'SalesLead', 'AMTextResponse', 'OnlineTransaction', 'FieldDevice', 'PilotStore', 'TaskandGoals', 'CustomerSurvey', 'CustomerVerificationOptimization'],
      };
      // console.log("UserCONTEXTS user", user);
      // console.log("UserCONTEXTS user", userThunk);
      // // const response = await GetStatsStatus(payload);
      // PS_03
      const response: any = await Promise.all([

        ((storeId && storeId != null && storeId != undefined && storeId != '' && storeId != 'null' && storeId != 'undefined') ? GetStatsStatus(payload) : Promise.resolve()), // Conditionally call GetStatsStatus
        getmenuandstore('MENU')])
      sethiddenLoader(false)
      checkPermissionForCashmgmt(response[1]?.data?.menuConfig || [])
      checkHasPinConfigured(response)
      checkPermissionForCashmgmt(response[1]?.data?.menuConfig || [])
      checkHasPinConfigured(response)
      // setStatsParamValue(
      //   response != undefined &&
      //     response.data != undefined &&
      //     response.data.storeProfileResponse != undefined &&
      //     response.data.storeProfileResponse.configDetails != undefined &&
      //     response.data.storeProfileResponse.configDetails.length > 0&&
      //     response.data.storeProfileResponse.configDetails[0] != undefined &&
      //     response.data.storeProfileResponse.configDetails[0].configDetails !=
      //     undefined &&
      //     response.data.storeProfileResponse.configDetails[0].configDetails.length > 0 &&
      //     response.data.storeProfileResponse.configDetails[0]
      //       .configDetails[0] != undefined &&
      //     response.data.storeProfileResponse.configDetails[0].configDetails[0]
      //       .paramValue != undefined
      //     ? response.data.storeProfileResponse.configDetails[0].configDetails[0]
      //       .paramValue
      //     : ''
      // );
      setStatsParamValue(response[0]?.data?.storeProfileResponse?.configDetails[0]?.configDetails?.filter((el: any) => el.paramKeyName == 'Stats')[0].paramValue)
      setStoreActionItemParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'StoreActionItem')[0].paramValue);
      setUpcomingDapParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'DapUpcomingEvents')[0].paramValue);
      setfieldDeviceParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'FieldDevice')[0].paramValue);
      setSalesLeadParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'SalesLead')[0].paramValue)
      setAMTextResponseParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'AMTextResponse')[0].paramValue)
      setOnlineTransactionParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'OnlineTransaction')[0].paramValue)
      setpilotStoreParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'PilotStore')[0].paramValue)
      setTaskandGoals(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'TaskandGoals')[0].paramValue)
      setCustomerSurveyParamKey(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'CustomerSurvey')[0].paramValue)
      // PS_04
      setCustomerVerification(response[0].data.storeProfileResponse.configDetails[0].configDetails.filter((el: any) => el.paramKeyName == 'CustomerVerificationOptimization')[0].paramValue)
    };
    PageLoad();
  }, []);
  const checkPermissionForCashmgmt = (menu) => {
    if (menu?.length > 0) {
      const isCashmgmtIsPresent = menu?.some((el: any) => el?.appComponentRefCode.replace(' ', '').toLowerCase() == 'cashmanagement')
      setIsCashmgmtisPresent(isCashmgmtIsPresent)
    }
  }
  const checkHasPinConfigured = (menuDetails: any) => {
    const menu = menuDetails[1]?.data?.menuConfig || []
    const { isPinConfigured } = menuDetails[1]?.data?.coworkerProfile || false
    if (menu?.length > 0) {
      const isPinmgmtIsPresent = menu?.some((el: any) => el?.appSubComponentRefCode.replace(' ', '').toLowerCase() == 'pinmanagement')
      if (isPinmgmtIsPresent && !isPinConfigured) {
        setHasPinConfigured(isPinmgmtIsPresent)
      }
    }
  }


  function UpcomingTemplate(props: any) {
    // const classes = useClasses();
    return (
      <>
        {/* <Grid  container > */}
        <Grid item className={`${classes.px2} ${classes.w100}`}>
          <Card className={`${classes.w100} ${classes.card}`} style={{ height: '270px' }}>
            <CardContent>
              <Typography
                variant="h6"
                className={`${classes.title} ${classes.titleColor}`}
              >
                {props.name}
              </Typography>
              {!loader ?
                <div>
                  <Grid className={`${classes.infoTextStyle} ${classes.my4}`}>
                    <Typography>Coming Soon!</Typography>
                  </Grid>
                  <Grid className={`${classes.infoTextStyle} ${classes.mb5}`}>
                    <Typography>
                      We are currently working on this feature and will launch soon!
                    </Typography>
                  </Grid>
                </div> : <div className={classes2.Norecords}>
                  <CircularProgress style={{ marginTop: '40px' }} />
                </div>}
            </CardContent>
          </Card>
        </Grid>
        {/* </Grid> */}
      </>
    );
  }
  const pinConfigureAckpopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid
          item
          md={12}
          className="text-center"
          style={{ textAlign: 'center' }}
        >
          <Typography
            style={{ marginTop: '1%', marginBottom: '1%' }}
          >{'Your account does not have a current PIN #. Please click OK to set your PIN'}</Typography>
        </Grid>
        <Grid
          container
          className="text-center mt-4"
          style={{ justifyContent: 'center', marginTop: '20px' }}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            color="primary"
            variant="contained"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              setHasPinConfigured(false);
              history.push({
                pathname: `/storemgmt/pinmanagement`,
              });
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid>
        <RACModalCard
          isOpen={hasPinConfigured}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => {
            setHasPinConfigured(false)
          }}
          // eslint-disable-next-line react/no-children-prop
          children={pinConfigureAckpopup()}
        />
      </Grid>
      {isCashmgmtisPresent && <CashManagementPopups />}
      <FSCUpdate />
      <FSCContest />
      {hiddenLoader === true ? (
        <Grid
          style={{
            position: 'fixed',
            background: '#f7f5f5',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            opacity: 0.6,
            zIndex: 2000,
            textAlign: 'center',
            margin: '0px 0px',
          }}
        >
          <Grid
            style={{
              display: 'block',
              position: 'fixed',
              zIndex: 9999999,
              top: '40%',
              right: '50%',
            }}
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : null}
      <Grid
        container
        data-testid="Gridrender"
        className={classes.mt3}
        key={'listgroupkey'}
      >
        {statsParamValue == '1' ? (
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            sm={12}
            xs={12}
            className={`${classes.floatLeft} ${classes.mb3} ${classes1.widthtab100}`}
            key={'contestupdates'}
          >
            <StatsBar />
          </Grid>
        ) : null}
        <Grid
          item
          lg={12}
          xl={12}
          md={12}
          sm={12}
          xs={12}
          className={`${classes.floatLeft} ${classes.mb3} ${classes1.widthtab100}`}
          key={'componentupcoming'}
        >
          {hiddenLoader ? null : <UpcomingEvents />}
        </Grid>
        {salesLeadParamKey == '1' ? <Grid
          item
          lg={8}
          xl={8}
          md={12}
          sm={12}
          xs={12}
          className={`${classes.px2} ${classes.mb3} ${classes1.widthtab100}`}
          key={'listgroupkeys'}
        >
          <Gridcomponent />
        </Grid> : null}
        {onlineTransactionParamKey == '1' ? <OnlineTransaction />
          : null}


        <Grid
          item
          lg={8}
          xl={8}
          md={12}
          sm={12}
          xs={12}
          className={`${classes.floatLeft} ${classes.mb3} ${classes1.widthtab100}`}
          key={'Store Action'}
        >
          {storeActionItemParamKey == '1' ? <StoreActionItem /> : null}
        </Grid>
        {amTextResponseParamKey == '1' ? <Grid
          item
          lg={4}
          xl={4}
          sm={12}
          md={12}
          xs={12}
          className={`${classes.floatLeft} ${classes.mb3} ${classes1.widthtab100}`}
          key={'Text Response'}
        >
          <AMtextResponse />
        </Grid> : null}

        {activemailing ? <ActiveMailingLable /> : null}

      </Grid>
    </>
  );
}

