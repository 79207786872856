/* eslint-disable prettier/prettier */
/* eslint-disable react/display-name */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/no-all-duplicated-branches */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    Card,
    CardContent,
    RACDatePicker,
    RACButton,
    RACSelect,
    RACTextbox,
    RACBadge,
    RACTableCell,
    List,
    ListItem,
    RACModalCard,
    RACTable,
    RACTableRow,
    Box,
    CircularProgress
} from "@rentacenter/racstrap";
import { ReactComponent as Racfont } from '../../../assets/images/RAC-Banner.svg';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';
import { ReactComponent as Images } from '../../../assets/images/pdf-icon.svg';
import { DapCalendarStyles } from "./DapCalendarStyles";
import { getPrintDocument } from '../../../api/user';
const PaddingConst = '0px 8px 0px 8px';
const Constinter = '1px solid  #545454';
const borderRadiuss = '3px';
const borderbotttom = '1px solid #545454';
const borderrr = '1px solid #DFDFDF';
const borderrbottommm = '1px dashed #545454';
const borderalone = '1px solid   #545454';

const ReprintReceiptCode = React.memo((props: any) => {
    const storeDetails = sessionStorage.getItem('storeDetails') || '';
    const encodeStoreDetails = Buffer.from(storeDetails, 'base64');
    const decodestoreDetails: any = JSON.parse(
        encodeStoreDetails.toString('ascii')
    );
    console.log('props data..', props)
    const classes = DapCalendarStyles();
    const [qrCodeUrl, setQrCodeUrl] = useState<any>('')

    useEffect(() => {
        if (props.data.currentappointmentDetails?.agreement?.agreementNumber) {
            getQrcodeFn()
        }
    }, [props.data.currentappointmentDetails?.agreement?.agreementNumber])

    const getQrcodeFn = async () => {
        const payLoad: any = {
            identifier: props.data.currentappointmentDetails?.agreement?.agreementNumber,
            documentStatus: 'signed',
            actionType: 'qrcode',
            identifierType: 'AGR',
        };
        const getPrintDocumentRes = await getPrintDocument(payLoad)
        setQrCodeUrl(getPrintDocumentRes?.data[0]?.documentUrl)
    }
    const normalizeinput = (phonenum: any) => {
        if (phonenum == '') return phonenum;
        if (!phonenum) return phonenum;
        const currentValue = phonenum.replace(/[^\d]/g, '');

        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`;
    };


    const bindChecklist = (source?: any) => {
        let data: any = props.data.itemdata;
        if (data !== undefined && data !== null && data.length > 0) {
            if (
                source == "ResendVideo"
            ) {

                const lineItem = {
                    inventoryId: "",
                    inventoryNumber: "",
                    inventoryDesc: "",
                    serialNumber: "",
                    modelNumber: "",
                };
                // if (agreementInfoData?.agreementWorkflow?.isWelcomeVideoSent == "1") {
                //   const activityDesc =
                //     agreementInfoData?.welcomeVideoInfo?.activityDesc?.split(" ");
                //   if (
                //     agreementInfoData?.welcomeVideoInfo?.activityRefCode == "SSKYES"
                //   ) {
                //     lineItem.inventoryDesc = `Introduction video sent ${agreementInfoData.welcomeVideoInfo.activityDate} to email ${activityDesc[4]}.`;
                //     //setrepeatContent(true);
                //   } else {
                //     lineItem.inventoryDesc = `Introduction video texted ${agreementInfoData.welcomeVideoInfo.activityDate
                //       } to phone number ${agreementInfoData?.welcomeVideoInfo?.activityDesc?.replace(
                //         /\D/g,
                //         ""
                //       )}.`;
                //     //setrepeatContent(true);
                //   }
                // } else {
                //   lineItem.inventoryDesc = "The Introduction video has not been sent.";
                //   //setrepeatContent(true);
                // }

                lineItem.inventoryDesc = ""
                data.push(lineItem);
                console.log("agreementInfoData if data", data);
            } else {
                data = props.data.itemdata;
                console.log("agreementInfoData else data", data);
            }
            const uniquedesc = [
                ...new Map(
                    data.map((item: any) => [item.inventoryDesc, item])
                ).values(),
            ];
            return uniquedesc.map((value: any, index: any) => {
                return (
                    <tr key={index} style={{ width: "100%" }}>
                        <td
                            style={{
                                width: "15px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value.inventoryDesc
                                ? <span
                                    style={{
                                        padding: PaddingConst,
                                        borderRadius: borderRadiuss,
                                        border: Constinter,
                                        marginRight: "10px",
                                    }}
                                />
                                : qrCodeUrl && source ? <span
                                    style={{
                                        padding: PaddingConst,
                                        borderRadius: borderRadiuss,
                                        border: Constinter,
                                        marginRight: "10px",
                                    }}
                                /> : ''}

                        </td>
                        <td
                            style={{
                                width: "300px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value.inventoryDesc
                                ? value.inventoryDesc
                                : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
                        </td>
                        <td
                            style={{
                                width: "300px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value !== undefined &&
                                value.serialNumber !== undefined &&
                                value.serialNumber !== null &&
                                value.serialNumber != ""
                                ? value.serialNumber
                                : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
                        </td>
                        <td
                            style={{
                                width: "300px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value.inventoryNumber ?
                                value.inventoryNumber
                                :
                                qrCodeUrl !== undefined && source ?
                                    <img style={{ width: "26%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeUrl}`} alt="QR Code" />
                                    : ''
                            }

                        </td>
                    </tr>
                );
            });
        }
    };
    const bindSwitchOutdata = (source?: any) => {
        const data: any = [];
        const itemdata1: any = {};
        if (props?.data?.inventorydescswitchin) {
            itemdata1.inventoryDesc = props?.data?.inventorydescswitchin;
            itemdata1.serialNumber = props?.data?.inventoryserialnumswitchin;
            itemdata1.inventoryNumber = props?.data?.inventorynumberswitchinitem;
            data.push(itemdata1)
            const lineItem = {
                inventoryNumber: "",
                inventoryDesc: "",
                serialNumber: "",
            };
            lineItem.inventoryDesc = ""
            data.push(lineItem);
            const uniquedesc = [
                ...new Map(
                    data.map((item: any) => [item.inventoryDesc, item])
                ).values(),
            ];
            return uniquedesc.map((value: any, index: any) => {
                return (
                    <tr key={index} style={{ width: "100%" }}>
                        <td
                            style={{
                                width: "15px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value.inventoryDesc
                                ? <span
                                    style={{
                                        padding: PaddingConst,
                                        borderRadius: borderRadiuss,
                                        border: Constinter,
                                        marginRight: "10px",
                                    }}
                                />
                                : qrCodeUrl && source ? <span
                                    style={{
                                        padding: PaddingConst,
                                        borderRadius: borderRadiuss,
                                        border: Constinter,
                                        marginRight: "10px",
                                    }}
                                /> : ''}

                        </td>
                        <td
                            style={{
                                width: "300px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value.inventoryDesc
                                ? value.inventoryDesc
                                : qrCodeUrl && source ? "Did you have a chance to watch the customized video that we sent you about how your new agreement works?" : ''}
                        </td>
                        <td
                            style={{
                                width: "300px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value !== undefined &&
                                value.serialNumber !== undefined &&
                                value.serialNumber !== null &&
                                value.serialNumber != ""
                                ? value.serialNumber
                                : qrCodeUrl && source ? <a onClick={() => { window.open(qrCodeUrl, '_blank'); }} style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }}>RACPAD Welcome Video</a> : ''}
                        </td>
                        <td
                            style={{
                                width: "300px",
                                paddingLeft: "12px",
                                paddingRight: "12px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                            }}
                        >
                            {value.inventoryNumber ?
                                value.inventoryNumber
                                :
                                qrCodeUrl !== undefined && source ?
                                    <img style={{ width: "26%" }} src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrCodeUrl}`} alt="QR Code" />
                                    : ''
                            }

                        </td>
                    </tr>
                );
            });
        }
    };

    const phoneNumberFormat = (phonenum: any) => {
        if (phonenum == '') return phonenum;
        if (!phonenum) return phonenum;
        const currentValue = phonenum.replace(/[^\d]/g, '');

        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`;
    };
    const phoneNumberFormat9prefix = (phonenum: any) => {
        if (phonenum == '') return phonenum;
        if (!phonenum) return phonenum;
        const currentValue = phonenum.replace(/[^\d]/g, '');

        return `9 (${currentValue.slice(0, 3)}) ${currentValue.slice(
            3,
            6
        )}-${currentValue.slice(6, 10)}`;
    };
    return (
        <Grid className={classes.hide}>
            {/* delivery receipt inner html starts here */}
            <div
                style={{
                    borderTop: '10px solid #2179FE',
                    margin: '0px',
                    width: '100%',
                    float: 'left',
                }}
                id="DeliveryReceiptPrintHTML"
            >
                <div
                    style={{
                        width: '100%',
                        fontFamily: 'Arial',
                        margin: '10px auto 0px auto',
                    }}
                >
                    {/*first part starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <div
                            style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                        >
                            <div style={{ width: '20.33%', float: 'left' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                    Delivery Receipt
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '13.33%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Logo style={{ width: '100%', height: '100%' }}></Logo>
                            </div>
                            <div></div>
                            <table
                                style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    {/*child-parent of first widget*/}
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryDate}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Time:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryTime}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/*first part ends here*/}
                        {/*second part starts here */}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <div style={{ width: '33.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        RAC Store
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storename}
                                    </p>
                                    <>
                                        {props.data.StoreAddressline2 != undefined &&
                                            props.data.StoreAddressline2 != null &&
                                            props.data.StoreAddressline2 != '' ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                            </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1}
                                            </p>
                                        )}
                                    </>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storecity + ',' + props.data.storezip}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.Storephonenum)}{' '}
                                    </p>
                                </div>
                                <div style={{ width: '30.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        Customer
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.CustomerName}{' '}
                                    </p>
                                    {props.data.CustomerAddressline2 !== undefined &&
                                        props.data.CustomerAddressline2 !== null &&
                                        props.data.CustomerAddressline2 !== '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1}
                                        </p>
                                    )}

                                    {props.data.customerplus4 != undefined &&
                                        props.data.customerplus4 != null &&
                                        props.data.customerplus4 != '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip}
                                        </p>
                                    )}
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.CustomerPhoneNumber)}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '36.33%',
                                        float: 'left',
                                        marginTop: '12px',
                                    }}
                                >
                                    <table>
                                        <thead />
                                        <tbody style={{ width: '100%', float: 'left' }}>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Delivery Date:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.DeliveryDate}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Agreement # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Customer # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}{' '}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Next Payment Due:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.nextpaymentinfo.nextamountdue !== undefined &&
                                                        props.data.nextpaymentinfo.nextamountdue !== null
                                                        ? '$' + ' ' + props.data.nextpaymentinfo.nextamountdue
                                                        : ''}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/*second part ends here */}
                        {/*3rd part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginTop: '15px' }}
                            >
                                <h5
                                    style={{
                                        textTransform: 'uppercase',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        margin: '0px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Items
                                </h5>
                            </div>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                    <thead>
                                        <tr style={{ width: '100%' }}>
                                            <th
                                                style={{
                                                    width: '15px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            ></th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Description of Property
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Serial #
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Item #
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bindSwitchOutdata('ResendVideo')}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                                marginBottom: '10px',
                            }}
                        >
                            <div style={{ width: '100%', float: 'left' }}>
                                <p
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                        textAlign: 'center',
                                        textDecorationLine: 'underline',
                                    }}
                                >
                                    Before merchandise is taken from the customer&apos; home,
                                    please check the condition of the following areas and note
                                    any damage:
                                </p>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '10px',
                                    marginTop: '30px',
                                }}
                            >
                                <div style={{ width: '15%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Wall(s)
                                    </span>
                                </div>
                                <div style={{ width: '15%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        {' '}
                                        Floors
                                    </span>
                                </div>
                                <div style={{ width: '22%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Door(s)/Doorway(s)
                                    </span>
                                </div>
                                <div style={{ width: '17%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Stairs
                                    </span>
                                </div>
                                <div style={{ width: '8%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Other
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: '15%',
                                        float: 'left',
                                        marginTop: '12px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    <span
                                        style={{
                                            borderBottom: '1px solid#545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                <div style={{ width: '40%', float: 'left' }}>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        If any boxes were checked, explain damage:
                                    </p>
                                </div>
                                <div
                                    style={{ width: '60%', float: 'right', marginTop: '20px' }}
                                >
                                    <span
                                        style={{
                                            borderBottom: '1px solid#545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '12px',
                                        marginTop: 10,
                                        marginBottom: 10,
                                        backgroundColor: "#E0EAFF"
                                    }}
                                >
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="flexCheckDefault-7"
                                        checked={false}

                                    />
                                    <label
                                        htmlFor="flexCheckDefault-6"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 13,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                                    </label>
                                </div>
                                <div style={{ width: '100%', float: 'left' }}>
                                    <h5
                                        style={{
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            marginTop: '0px',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Accept / Decline (Check one)
                                    </h5>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        I acknowledge that Rent-A-Center has delivered, installed
                                        and demonstrated, to my satisfaction, the rental property
                                        listed above.
                                    </span>
                                </div>
                                <div
                                    style={{ width: '100%', float: 'left', marginTop: '10px' }}
                                >
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', marginBottom: '10px' }}>
                                        I decline delivery by Rent-A-Center of the rental property
                                        listed above.
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/*-fourth part ends*/}
                        {/*fifth part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginBottom: '20px',
                                marginTop: '10px',
                            }}
                        >
                            <table style={{ width: '100%', float: 'left' }}>
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Customer Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                style={{ width: '100%', float: 'left', marginTop: '25px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Co-Worker Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            {/*fifth part ends*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* delivery receipt inner html ends here */}

            {/* delivery checklist inner html starts here */}
            <div
                style={{
                    width: '90%',
                    fontFamily: 'Arial',
                    margin: '10px auto 0px auto',
                }}
                id="DeliveryChecklistPdf"
            >
                {/*first part starts here*/}
                <div style={{ width: '100%', float: 'left' }}>
                    <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
                        <div style={{ width: '30%', float: 'left' }}>
                            <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                Delivery Checklist
                            </h5>
                        </div>
                        <div
                            style={{
                                width: '31%',
                                marginLeft: '2%',
                                marginRight: '2%',
                                float: 'left',
                                height: '100px',
                                marginBottom: '10px',
                            }}
                        >
                            <Logo style={{ width: '100%', height: '100%' }}></Logo>
                        </div>
                        <div></div>
                        <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                            <thead />
                            <tbody style={{ width: '100%', float: 'right' }}>
                                {/*child-parent of first widget*/}
                                <tr
                                    style={{ width: '42%', float: 'left', textAlign: 'left' }}
                                >
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Delivery Date:
                                    </td>
                                </tr>
                                <tr
                                    style={{ width: '58%', float: 'left', textAlign: 'left' }}
                                >
                                    <td>
                                        <span
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.DeliveryDate}
                                        </span>
                                    </td>
                                </tr>
                                <tr
                                    style={{ width: '43%', float: 'left', textAlign: 'left' }}
                                >
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Delivery Time:
                                    </td>
                                </tr>
                                <tr
                                    style={{ width: '57%', float: 'left', textAlign: 'left' }}
                                >
                                    <td>
                                        <span
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.DeliveryTime}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*first part ends here*/}
                {/*second part starts here */}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        borderBottom: borderrr,
                    }}
                >
                    <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                        <div style={{ width: '33.33%', float: 'left' }}>
                            <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                RAC Store
                            </h5>
                            <p
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '13px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                {props.data.storename}
                            </p>
                            <>
                                {props.data.StoreAddressline2 != undefined &&
                                    props.data.StoreAddressline2 != null &&
                                    props.data.StoreAddressline2 != '' ? (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                    </p>
                                ) : (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.StoreAddressline1}
                                    </p>
                                )}
                            </>
                            <p
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '13px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                {' '}
                                {props.data.storecity + ',' + props.data.storezip}{' '}
                            </p>
                            <p
                                style={{
                                    fontSize: '13px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                {normalizeinput(props.data.Storephonenum)}
                            </p>
                        </div>
                        <div style={{ width: '30.33%', float: 'left' }}>
                            <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                Customer
                            </h5>
                            <p
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '13px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                {props.data.CustomerName}
                            </p>
                            {props.data.CustomerAddressline2 !== undefined &&
                                props.data.CustomerAddressline2 !== null &&
                                props.data.CustomerAddressline2 !== '' ? (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.data.CustomerAddressline1}
                                </p>
                            )}
                            {props.data.customerplus4 != undefined &&
                                props.data.customerplus4 != null &&
                                props.data.customerplus4 != '' ? (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '13px',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {props.data.customercity + ',' + props.data.customerzip}
                                </p>
                            )}
                            <p
                                style={{
                                    fontSize: '13px',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                {normalizeinput(props.data.CustomerPhoneNumber)}
                            </p>
                        </div>
                        <div
                            style={{ width: '36.33%', float: 'left', marginTop: '12px' }}
                        >
                            <table>
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{ width: '50%', textAlign: 'left', float: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '40%', textAlign: 'left', float: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.DeliveryDate}
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Agreement # :
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                                            {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Customer # :
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '40%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}{' '}
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Next Payment Due:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '40%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.nextpaymentinfo.nextamountdue !== undefined &&
                                                props.data.nextpaymentinfo.nextamountdue !== null
                                                ? '$' + ' ' + props.data.nextpaymentinfo.nextamountdue
                                                : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*second part ends here */}
                {/*3rd part starts*/}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        borderBottom: borderrr,
                    }}
                >
                    <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
                        <h5
                            style={{
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontSize: '14px',
                                margin: '0px',
                                marginBottom: '10px',
                            }}
                        >
                            Delivery Checklist
                        </h5>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                        <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                            <thead>
                                <tr style={{ width: '100%' }}>
                                    <th
                                        style={{
                                            width: '15px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    ></th>
                                    <th
                                        style={{
                                            width: '300px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Description of Property
                                    </th>
                                    <th
                                        style={{
                                            width: '300px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Serial #
                                    </th>
                                    <th
                                        style={{
                                            width: '300px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Item #
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bindSwitchOutdata('ResendVideo')}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*3rd part ends*/}
                {/*fourth part starts*/}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        marginBottom: '400px',
                        marginTop: '10px',
                    }}
                >
                    <h5
                        style={{
                            fontSize: '14px',
                            textDecoration: 'underline',
                            float: 'left',
                        }}
                    >
                        Delivery Information
                    </h5>
                    <table style={{ width: '100%', float: 'left' }}>
                        <thead />
                        <tbody
                            style={{ width: '100%', marginTop: '20px', float: 'left' }}
                        >
                            <tr
                                style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}
                            >
                                <td
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    CoWorker Signature:
                                </td>
                            </tr>
                            <tr
                                style={{
                                    width: '28%',
                                    float: 'left',
                                    paddingRight: '25px',
                                    whiteSpace: 'nowrap',
                                    marginLeft: '8px',
                                }}
                            >
                                <td
                                    style={{
                                        marginTop: '18px',
                                        color: '#656565',
                                        borderBottom: borderbotttom,
                                        width: '100%',
                                        float: 'left',
                                    }}
                                />
                            </tr>
                            <tr
                                style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}
                            >
                                <td
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '13px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Date:
                                </td>
                            </tr>
                            <tr
                                style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}
                            >
                                <td
                                    style={{
                                        marginTop: '15px',
                                        color: '#656565',
                                        borderBottom: borderbotttom,
                                        width: '100%',
                                        float: 'left',
                                    }}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/*fourth part ends*/}
                {/*fifth part starts*/}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        borderBottom: borderrr,
                    }}
                >
                    <div style={{ width: '100%', float: 'left' }}>
                        <h5
                            style={{
                                textAlign: 'center',
                                fontSize: '20px',
                                marginTop: '0px',
                                marginBottom: '10px',
                            }}
                        >
                            For Office Use Only
                        </h5>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                        <span
                            style={{
                                fontSize: '14px',
                                marginTop: '10px',
                                marginBottom: '10px',
                                fontStyle: 'italic',
                            }}
                        >
                            I acknowledge that Rent-A-Center has delivered, installed and
                            demonstrated, to my satisfaction, the rental property listed
                            above.
                        </span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ width: '100%', float: 'left', marginTop: '20px' }}>
                            <span
                                style={{
                                    fontSize: '14px',
                                    marginBottom: '10px',
                                    textDecoration: 'underline',
                                }}
                            >
                                Customer Call back survey
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            marginTop: '20px',
                            marginBottom: '30px',
                        }}
                    >
                        <div style={{ width: '50%', float: 'left' }}>
                            <div style={{ width: '35%', float: 'left' }}>
                                <span
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Date of Call Back
                                </span>
                            </div>
                            <div style={{ width: '65%', float: 'right' }}>
                                <span
                                    style={{
                                        marginTop: '12px',
                                        color: '#656565',
                                        borderBottom: borderbotttom,
                                        width: '80%',
                                        float: 'left',
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                            <div style={{ width: '30%', float: 'left' }}>
                                <span
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Mgr/Asst Initials
                                </span>
                            </div>
                            <div style={{ width: '70%', float: 'right' }}>
                                <span
                                    style={{
                                        marginTop: '12px',
                                        color: '#656565',
                                        borderBottom: borderrbottommm,
                                        width: '100%',
                                        float: 'left',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*fifth part ends*/}
                {/*letter format starts*/}
                <div style={{ width: '100%', fontSize: '14px', float: 'left' }}>
                    <p
                        style={{
                            fontSize: '14px',
                            marginBottom: '5px',
                            lineHeight: '26px',
                        }}
                    >
                        Hello Mr./Mrs./Ms., this is{' '}
                        <span
                            style={{
                                marginTop: '12px',
                                color: '#656565',
                                borderBottom: borderrbottommm,
                                paddingRight: '135px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            }}
                        />
                        , the manager of the Rent-A-Center in{' '}
                        <span
                            style={{
                                marginTop: '12px',
                                color: '#656565',
                                borderBottom: borderrbottommm,
                                paddingRight: '135px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            }}
                        />
                        I want to welcome you as a customer and ask you a few questions
                        about the delivery we made to you the other day. Is this a
                        convenient time for you to answer a few questions?
                    </p>
                    <p style={{ fontStyle: 'italic', marginTop: '5px' }}>
                        {' '}
                        (If yes, continue with survey. If no, ask for a convenient time to
                        call back)
                    </p>
                </div>
                {/*letter format ends*/}
                {/*grid starts here*/}
                <div style={{ width: '100%', float: 'left' }}>
                    <table style={{ marginBottom: '10px', fontSize: '14px' }}>
                        <tbody>
                            <tr style={{ width: '100%' }}>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    1. Was the delivery on time ?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    2. Was the unit&apos; operation explained to your
                                    satisfaction ?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    3. Have we answered all of your questions regarding the
                                    rental agreement?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    3. Have we answered all of your questions regarding the
                                    rental agreement?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    4. Can you tell me when your next renewal is due and the
                                    amount you will pay?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    5. Did we explain our customer referral program?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    6. Did you take advantage of our Benefits Plus program when
                                    you rented your item?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        paddingLeft: '30px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                        marginRight: '20px',
                                    }}
                                >
                                    If No explain to them the benefits &amp; close the deal.
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    7. Do you have any other questions or is there anything I
                                    can do for you?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '5px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ width: '100%', float: 'left' }}>
                    <p style={{ fontSize: '14px', marginBottom: '5px' }}>Notes :</p>
                    <span
                        style={{
                            marginTop: '20px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            width: '100%',
                            float: 'left',
                        }}
                    />
                    <span
                        style={{
                            marginTop: '30px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            width: '100%',
                            float: 'left',
                        }}
                    />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            marginTop: '20px',
                            marginBottom: '20px',
                        }}
                    >
                        <p style={{ fontSize: '14px', marginBottom: '5px' }}>
                            Thank you for your time and please do not hesitate to call us at
                            Rent-A-Center with any questions.
                        </p>
                    </div>
                </div>
                {/*grid ends here*/}
            </div>
            {/* delivery checklist inner html ends here */}
            {/* pickup receipt innerhtml starts here */}
            <div
                style={{
                    borderTop: '10px solid #2179FE',
                    margin: '0px',
                    width: '100%',
                    float: 'left',
                }}
                id="pickupreciptPrintHTML"
            >
                <div
                    style={{
                        width: '100%',
                        fontFamily: 'Arial',
                        margin: '10px auto 0px auto',
                    }}
                >
                    {/*first part starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <div
                            style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                        >
                            <div style={{ width: '20.33%', float: 'left' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                    Pickup Receipt
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '13.33%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Logo style={{ width: '100%', height: '100%' }}></Logo>
                            </div>
                            <div></div>
                            <table
                                style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    {/*child-parent of first widget*/}
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Pickup Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryDate}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Pickup Time:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryTime}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/*first part ends here*/}
                        {/*second part starts here */}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <div style={{ width: '33.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        RAC Store
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storename}
                                    </p>
                                    <>
                                        {props.data.StoreAddressline2 != undefined &&
                                            props.data.StoreAddressline2 != null &&
                                            props.data.StoreAddressline2 != '' ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                            </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1}
                                            </p>
                                        )}
                                    </>

                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storecity + ',' + props.data.storezip}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.Storephonenum)}{' '}
                                    </p>
                                </div>
                                <div style={{ width: '30.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        Customer
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.CustomerName}{' '}
                                    </p>
                                    {props.data.CustomerAddressline2 !== undefined &&
                                        props.data.CustomerAddressline2 !== null &&
                                        props.data.CustomerAddressline2 !== '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1}
                                        </p>
                                    )}
                                    {props.data.customerplus4 != undefined &&
                                        props.data.customerplus4 != null &&
                                        props.data.customerplus4 != '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip}
                                        </p>
                                    )}
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.CustomerPhoneNumber)}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '36.33%',
                                        float: 'left',
                                        marginTop: '12px',
                                    }}
                                >
                                    <table>
                                        <thead />
                                        <tbody style={{ width: '100%', float: 'left' }}>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Date:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.DeliveryDate}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Agreement # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Customer # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}{' '}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Back Rent:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {/* {'$' + ' ' + NextPaymentDate} */}$ 0.00
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/*second part ends here */}
                        {/*3rd part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginTop: '15px' }}
                            >
                                <h5
                                    style={{
                                        textTransform: 'uppercase',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        margin: '0px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    ITEMS
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '100px',
                                }}
                            >
                                <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                    <thead>
                                        <tr style={{ width: '100%' }}>
                                            <th
                                                style={{
                                                    width: '15px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            ></th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Description of Property
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Serial #
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Item #
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bindChecklist()}
                                        {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: PaddingConst,
                              borderRadius: borderRadiuss,
                              border: Constinter,
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*3rd part ends*/}

                        {/*fourth part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginBottom: '20px',
                                marginTop: '10px',
                            }}
                        >
                            <table style={{ width: '100%', float: 'left' }}>
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Employee Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '15px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div
                            style={{
                                width: '13.33%',
                                marginLeft: '2%',
                                marginRight: '2%',
                                float: 'left',
                                height: '100px',
                                marginBottom: '10px',
                            }}
                        >
                            {/* /> */}
                            <Racfont style={{ width: '700%', height: '75%' }}></Racfont>
                        </div>
                        <div>{/* 5th part ends */}</div>
                    </div>
                </div>
                {/* pickup receipt Inner html ends here */}
            </div>
            {/* pickup receipt inner htnl ends here */}
            {/* pickup checklist inner html starts here */}
            <div
                style={{
                    borderTop: '10px solid #2179FE',
                    margin: '0px',
                    width: '100%',
                    float: 'left',
                }}
                id="pickupchecklistprintHTML"
            >
                <div
                    style={{
                        width: '100%',
                        fontFamily: 'Arial',
                        margin: '10px auto 0px auto',
                    }}
                >
                    {/*first part starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <div
                            style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                        >
                            <div style={{ width: '20.33%', float: 'left' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                    Pickup Checklist
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '13.33%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Logo style={{ width: '100%', height: '100%' }}></Logo>
                            </div>
                            <div></div>
                            <table
                                style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    {/*child-parent of first widget*/}
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Pickup Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryDate}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Pickup Time:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryTime}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/*first part ends here*/}
                        {/*second part starts here */}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <div style={{ width: '33.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        RAC Store
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storename}
                                    </p>
                                    <>
                                        {props.data.StoreAddressline2 != undefined &&
                                            props.data.StoreAddressline2 != null &&
                                            props.data.StoreAddressline2 != '' ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                            </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1}
                                            </p>
                                        )}
                                    </>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storecity + ',' + props.data.storezip}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.Storephonenum)}{' '}
                                    </p>
                                </div>
                                <div style={{ width: '30.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        Customer
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.CustomerName}{' '}
                                    </p>
                                    {props.data.CustomerAddressline2 !== undefined &&
                                        props.data.CustomerAddressline2 !== null &&
                                        props.data.CustomerAddressline2 !== '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1}
                                        </p>
                                    )}
                                    {props.data.customerplus4 != undefined &&
                                        props.data.customerplus4 != null &&
                                        props.data.customerplus4 != '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip}
                                        </p>
                                    )}
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.CustomerPhoneNumber)}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '36.33%',
                                        float: 'left',
                                        marginTop: '12px',
                                    }}
                                >
                                    <table>
                                        <thead />
                                        <tbody style={{ width: '100%', float: 'left' }}>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Date:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.DeliveryDate}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Agreement # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Customer # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}{' '}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Due Date:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.nextpaymentinfo.nextduedate !== undefined &&
                                                        props.data.nextpaymentinfo.nextduedate !== null
                                                        ? props.data.nextpaymentinfo.nextduedate
                                                        : ''}10-11-2023
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Back Rent:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    $0.00
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/*second part ends here */}
                        {/*3rd part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginTop: '15px' }}
                            >
                                <h5
                                    style={{
                                        textTransform: 'uppercase',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        margin: '0px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    PICKUP CHECKLIST
                                </h5>
                            </div>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                    <thead>
                                        <tr style={{ width: '100%' }}>
                                            <th
                                                style={{
                                                    width: '15px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            ></th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Description of Property
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Serial #
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Item #
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr style={{ width: '100%' }}>
                        <td
                          style={{
                            width: '15px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          <span
                            style={{
                              padding: '0px 8px 0px 8px',
                              borderRadius: '3px',
                              border: '1px solid  #545454',
                              marginRight: '10px',
                            }}
                          />
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorydesc}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventoryseialnum}
                        </td>
                        <td
                          style={{
                            width: '300px',
                            paddingLeft: '12px',
                            paddingRight: '12px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                          }}
                        >
                          {inventorynumber}
                        </td>
                      </tr> */}
                                        {bindChecklist()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*3rd part ends*/}
                        {/* fourth part starts here */}
                        <table>
                            <thead />
                            <tbody>
                                <tr>
                                    <td>
                                        <div
                                            style={{
                                                width: '100%',
                                                float: 'left',
                                                marginBottom: '250px',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <h5
                                                style={{
                                                    fontSize: '14px',
                                                    textDecoration: 'underline',
                                                    float: 'left',
                                                }}
                                            >
                                                Pickup Information
                                            </h5>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {/* fourth part ends here */}

                        {/*fifth part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                                marginBottom: '10px',
                            }}
                        >
                            <div style={{ width: '100%', float: 'left' }}>
                                <p
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                        textAlign: 'center',
                                        textDecorationLine: 'underline',
                                    }}
                                >
                                    Before merchandise is taken from the customer&apos; home,
                                    please check the condition of the following areas and note
                                    any damage:
                                </p>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '10px',
                                    marginTop: '30px',
                                }}
                            >
                                <div style={{ width: '15%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Wall(s)
                                    </span>
                                </div>
                                <div style={{ width: '15%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        {' '}
                                        Floors
                                    </span>
                                </div>
                                <div style={{ width: '22%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Door(s)/Doorway(s)
                                    </span>
                                </div>
                                <div style={{ width: '17%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Stairs
                                    </span>
                                </div>
                                <div style={{ width: '8%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Other
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: '15%',
                                        float: 'left',
                                        marginTop: '12px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    <span
                                        style={{
                                            borderBottom: '1px solid#545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                <div style={{ width: '40%', float: 'left' }}>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        If any boxes were checked, explain damage:
                                    </p>
                                </div>
                                <div
                                    style={{ width: '60%', float: 'right', marginTop: '20px' }}
                                >
                                    <span
                                        style={{
                                            borderBottom: '1px solid#545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*-fifth part ends*/}
                        {/*sixth part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginBottom: '20px',
                                marginTop: '10px',
                            }}
                        >
                            <table style={{ width: '100%', float: 'left' }}>
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Customer Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                style={{ width: '100%', float: 'left', marginTop: '25px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Co-Worker Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            {/*sixth part ends*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* pickup checklist inner html ends here */}
            <div
                style={{
                    width: '90%',
                    fontFamily: 'Arial',
                    margin: '10px auto 0px auto',
                }}
                id="DeliveryChecklistrentalPdf"
            >
                {/*first part starts here*/}
                <div style={{ width: '100%', float: 'left' }}>
                    <div style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}>
                        <div style={{ width: '30%', float: 'left' }}>
                            <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                Delivery Checklist
                            </h5>
                        </div>
                        <div
                            style={{
                                width: '31%',
                                marginLeft: '2%',
                                marginRight: '2%',
                                float: 'left',
                                height: '100px',
                            }}
                        >
                            <Logo style={{ width: '100%', height: '100%' }}></Logo>
                        </div>
                        <div></div>
                        <table style={{ width: '35%', float: 'left', marginTop: '30px' }}>
                            <thead />
                            <tbody style={{ width: '100%', float: 'right' }}>
                                {/*child-parent of first widget*/}
                                <tr style={{ width: '42%', float: 'left', textAlign: 'left' }}>
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Delivery Date:
                                    </td>
                                </tr>
                                <tr style={{ width: '58%', float: 'left', textAlign: 'left' }}>
                                    <td>
                                        <span
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                borderBottom: '1px solid #545454',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.DeliveryDate}
                                        </span>
                                    </td>
                                </tr>
                                <tr style={{ width: '43%', float: 'left', textAlign: 'left' }}>
                                    <td
                                        style={{
                                            marginBottom: '0.2rem',
                                            color: '#111111',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Delivery Time:
                                    </td>
                                </tr>
                                <tr style={{ width: '57%', float: 'left', textAlign: 'left' }}>
                                    <td>
                                        <span
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                borderBottom: '1px solid #545454',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.DeliveryTime}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*first part ends here*/}
                {/*second part starts here */}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        borderBottom: borderrr,
                    }}
                >
                    <div style={{ width: '100%', float: 'left' }}>
                        <div style={{ width: '33.33%', float: 'left' }}>
                            <h5 style={{ fontSize: '12px' }}>RAC Store</h5>
                            <p
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    marginBottom: '10px',
                                }}
                            >
                                {props.data.storename}
                            </p>
                            <>
                                {props.data.StoreAddressline2 != undefined &&
                                    props.data.StoreAddressline2 != null &&
                                    props.data.StoreAddressline2 != '' ? (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '12px',
                                            marginTop: '8px',
                                            marginBottom: 'px',
                                        }}
                                    >
                                        {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                    </p>
                                ) : (
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '12px',
                                            marginTop: '8px',
                                            marginBottom: '8px',
                                        }}
                                    >
                                        {props.data.StoreAddressline1}
                                    </p>
                                )}
                            </>
                            <p
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }}
                            >
                                {' '}
                                {props.data.storecity + ',' + props.data.storezip}{' '}
                            </p>
                            <p
                                style={{
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }}
                            >
                                {normalizeinput(props.data.Storephonenum)}                            </p>
                        </div>
                        <div style={{ width: '30.33%', float: 'left' }}>
                            <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>Customer</h5>
                            <p
                                style={{
                                    textTransform: 'uppercase',
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }}
                            >
                                {props.data.CustomerName}
                            </p>
                            {props.data.CustomerAddressline2 !== undefined &&
                                props.data.CustomerAddressline2 !== null &&
                                props.data.CustomerAddressline2 !== '' ? (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '12px',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '12px',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {props.data.CustomerAddressline1}
                                </p>
                            )}
                            {props.data.customerplus4 != undefined &&
                                props.data.customerplus4 != null &&
                                props.data.customerplus4 != '' ? (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '12px',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                </p>
                            ) : (
                                <p
                                    style={{
                                        textTransform: 'uppercase',
                                        fontSize: '12px',
                                        marginTop: '8px',
                                        marginBottom: '8px',
                                    }}
                                >
                                    {props.data.ustomercity + ',' + props.data.customerzip}
                                </p>
                            )}
                            <p
                                style={{
                                    fontSize: '12px',
                                    marginTop: '8px',
                                    marginBottom: '8px',
                                }}
                            >
                                {normalizeinput(props.data.CustomerPhoneNumber)}
                            </p>
                        </div>
                        <div style={{ width: '36.33%', float: 'left', marginTop: '12px' }}>
                            <table>
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr style={{ width: '50%', textAlign: 'left', float: 'left' }}>
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Date:
                                        </td>
                                    </tr>
                                    <tr style={{ width: '40%', textAlign: 'left', float: 'left' }}>
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {props.data.DeliveryDate}
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Agreement # :
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '50%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Customer # :
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '40%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}{' '}
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '51%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '12px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Next Payment Due:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '40%',
                                            marginTop: '10px',
                                            textAlign: 'left',
                                            float: 'left',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '5px',
                                                color: '#656565',
                                                fontSize: '13px',
                                            }}
                                        >
                                            {props.data.nextpaymentinfo.nextamountdue !== undefined &&
                                                props.data.nextpaymentinfo.nextamountdue !== null
                                                ? '$' + ' ' + props.data.nextpaymentinfo.nextamountdue
                                                : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/*second part ends here */}
                {/*3rd part starts*/}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        borderBottom: borderrr,
                    }}
                >
                    <div style={{ width: '100%', float: 'left', marginTop: '15px' }}>
                        <h5
                            style={{
                                textTransform: 'uppercase',
                                textAlign: 'center',
                                fontSize: '13px',
                                margin: '0px',
                                marginBottom: '10px',
                            }}
                        >
                            Delivery Checklist
                        </h5>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '10px' }}>
                        <table style={{ marginBottom: '20px', fontSize: '11px' }}>
                            <thead>
                                <tr style={{ width: '100%' }}>
                                    <th
                                        style={{
                                            width: '15px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    ></th>
                                    <th
                                        style={{
                                            width: '300px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Description of Property
                                    </th>
                                    <th
                                        style={{
                                            width: '300px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Serial #
                                    </th>
                                    <th
                                        style={{
                                            width: '300px',
                                            textAlign: 'left',
                                            paddingLeft: '12px',
                                            paddingRight: '12px',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                        }}
                                    >
                                        Item #
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bindChecklist('ResendVideo')}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/*3rd part ends*/}
                {/*fourth part starts*/}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        marginBottom: '4px',
                        marginTop: '4px',
                    }}
                >
                    <div
                        style={{
                            fontSize: '12px',
                            textDecoration: 'underline',
                            float: 'left',
                        }}
                    >
                        Delivery Information
                    </div>
                    <table style={{ width: '100%', float: 'left' }}>
                        <thead />
                        <tbody style={{ width: '100%', marginTop: '4px', float: 'left' }}>
                            <tr style={{ width: '18%', float: 'left', whiteSpace: 'nowrap' }}>
                                <td
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '11px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    CoWorker Signature:
                                </td>
                            </tr>
                            <tr
                                style={{
                                    width: '28%',
                                    float: 'left',
                                    paddingRight: '25px',
                                    whiteSpace: 'nowrap',
                                    marginLeft: '8px',
                                }}
                            >
                                <td
                                    style={{
                                        marginTop: '18px',
                                        color: '#656565',
                                        borderBottom: borderbotttom,
                                        width: '100%',
                                        float: 'left',
                                    }}
                                />
                            </tr>
                            <tr style={{ width: '8%', float: 'left', whiteSpace: 'nowrap' }}>
                                <td
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '11px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Date:
                                </td>
                            </tr>
                            <tr style={{ width: '32%', float: 'left', whiteSpace: 'nowrap' }}>
                                <td
                                    style={{
                                        marginTop: '15px',
                                        color: '#656565',
                                        borderBottom: borderbotttom,
                                        width: '100%',
                                        float: 'left',
                                    }}
                                />
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/*fourth part ends*/}
                {/*fifth part starts*/}
                <div
                    style={{
                        width: '100%',
                        float: 'left',
                        borderBottom: borderrr,
                    }}
                >
                    <div style={{ width: '100%', float: 'left' }}>
                        <h5
                            style={{
                                textAlign: 'center',
                                fontSize: '18px',
                                marginTop: '0px',
                                marginBottom: '5px',
                            }}
                        >
                            For Office Use Only
                        </h5>
                    </div>
                    <div style={{ width: '100%', float: 'left' }}>
                        <span
                            style={{
                                fontSize: '12px',
                                marginTop: '5px',
                                marginBottom: '5px',
                                fontStyle: 'italic',
                            }}
                        >
                            I acknowledge that Rent-A-Center has delivered, installed and
                            demonstrated, to my satisfaction, the rental property listed above.
                        </span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ width: '100%', float: 'left', marginTop: '10px' }}>
                            <span
                                style={{
                                    fontSize: '12px',
                                    marginBottom: '10px',
                                    textDecoration: 'underline',
                                }}
                            >
                                Customer Call back survey
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            marginTop: '15px',
                            marginBottom: '18px',
                        }}
                    >
                        <div style={{ width: '50%', float: 'left' }}>
                            <div style={{ width: '35%', float: 'left' }}>
                                <span
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Date of Call Back
                                </span>
                            </div>
                            <div style={{ width: '65%', float: 'right' }}>
                                <span
                                    style={{
                                        marginTop: '12px',
                                        color: '#656565',
                                        borderBottom: borderbotttom,
                                        width: '80%',
                                        float: 'left',
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                            <div style={{ width: '30%', float: 'left' }}>
                                <span
                                    style={{
                                        marginBottom: '0.2rem',
                                        color: '#111111',
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Mgr/Asst Initials
                                </span>
                            </div>
                            <div style={{ width: '70%', float: 'right' }}>
                                <span
                                    style={{
                                        marginTop: '12px',
                                        color: '#656565',
                                        borderBottom: borderrbottommm,
                                        width: '100%',
                                        float: 'left',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/*fifth part ends*/}
                {/*letter format starts*/}
                <div style={{ width: '100%', fontSize: '12px', float: 'left' }}>
                    <p
                        style={{
                            fontSize: '12px',
                            marginBottom: '4px',
                            lineHeight: '26px',
                        }}
                    >
                        Hello Mr./Mrs./Ms., this is{' '}
                        <span
                            style={{
                                marginTop: '12px',
                                color: '#656565',
                                borderBottom: borderrbottommm,
                                paddingRight: '135px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            }}
                        />
                        , the manager of the Rent-A-Center in{' '}
                        <span
                            style={{
                                marginTop: '12px',
                                color: '#656565',
                                borderBottom: borderrbottommm,
                                paddingRight: '135px',
                                marginLeft: '10px',
                                marginRight: '10px',
                            }}
                        />
                        I want to welcome you as a customer and ask you a few questions about
                        the delivery we made to you the other day. Is this a convenient time
                        for you to answer a few questions?
                    </p>
                    <p style={{ fontStyle: 'italic', marginTop: '4px' }}>
                        {' '}
                        (If yes, continue with survey. If no, ask for a convenient time to
                        call back)
                    </p>
                </div>
                {/*letter format ends*/}
                {/*grid starts here*/}
                <div style={{ width: '100%', float: 'left' }}>
                    <table style={{ marginBottom: '2px', fontSize: '12px' }}>
                        <tbody>
                            <tr style={{ width: '100%' }}>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    1. Was the delivery on time ?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    2. Was the unit&apos; operation explained to your satisfaction ?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    3. Have we answered all of your questions regarding the rental
                                    agreement?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    3. Have we answered all of your questions regarding the rental
                                    agreement?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    4. Can you tell me when your next renewal is due and the amount
                                    you will pay?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    5. Did we explain our customer referral program?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    6. Did you take advantage of our Benefits Plus program when you
                                    rented your item?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        paddingLeft: '30px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                        marginRight: '20px',
                                    }}
                                >
                                    If No explain to them the benefits &amp; close the deal.
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    7. Do you have any other questions or is there anything I can do
                                    for you?
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    Yes
                                </td>
                                <td
                                    style={{
                                        width: '14%',
                                        paddingLeft: '12px',
                                        paddingRight: '12px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                    }}
                                >
                                    No
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ width: '100%', float: 'left' }}>
                    <p style={{ fontSize: '12px', marginBottom: '3px' }}>Notes :</p>
                    <span
                        style={{
                            marginTop: '12px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            width: '100%',
                            float: 'left',
                        }}
                    />
                    <span
                        style={{
                            marginTop: '18px',
                            color: '#656565',
                            borderBottom: borderbotttom,
                            width: '100%',
                            float: 'left',
                        }}
                    />
                </div>
                <div style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            // marginTop: '20px',
                            // marginBottom: '20px',
                        }}
                    >
                        <p style={{ fontSize: '11px' }}>
                            Thank you for your time and please do not hesitate to call us at
                            Rent-A-Center with any questions.
                        </p>
                    </div>
                </div>
                {/*grid ends here*/}
            </div>
            <div
                style={{
                    borderTop: '10px solid #2179FE',
                    margin: '0px',
                    width: '100%',
                    float: 'left',
                    paddingLeft: '12px'

                }}
                id="DeliveryReceiptrentalPrintHTML"
            >
                <div
                    style={{
                        width: '100%',
                        fontFamily: 'Arial',
                        margin: '10px auto 0px auto',
                    }}
                >
                    {/*first part starts here*/}
                    <div style={{ width: '100%', float: 'left' }}>
                        <div
                            style={{ width: '100%', fontFamily: 'Arial', float: 'left' }}
                        >
                            <div style={{ width: '20.33%', float: 'left' }}>
                                <h5 style={{ textTransform: 'uppercase', fontSize: '15px' }}>
                                    Delivery Receipt
                                </h5>
                            </div>
                            <div
                                style={{
                                    width: '13.33%',
                                    marginLeft: '2%',
                                    marginRight: '2%',
                                    float: 'left',
                                    height: '100px',
                                    marginBottom: '10px',
                                }}
                            >
                                <Logo style={{ width: '100%', height: '100%' }}></Logo>
                            </div>
                            <div></div>
                            <table
                                style={{ width: '62.33%', float: 'left', marginTop: '30px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    {/*child-parent of first widget*/}
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '24%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryDate}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '22%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Delivery Time:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{ width: '30%', float: 'left', textAlign: 'left' }}
                                    >
                                        <td>
                                            <span
                                                style={{
                                                    marginTop: '5px',
                                                    color: '#656565',
                                                    borderBottom: borderbotttom,
                                                    fontSize: '13px',
                                                }}
                                            >
                                                {props.data.DeliveryTime}
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        {/*first part ends here*/}
                        {/*second part starts here */}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <div style={{ width: '33.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        RAC Store
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storename}
                                    </p>
                                    <>
                                        {props.data.StoreAddressline2 != undefined &&
                                            props.data.StoreAddressline2 != null &&
                                            props.data.StoreAddressline2 != '' ? (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                            </p>
                                        ) : (
                                            <p
                                                style={{
                                                    textTransform: 'uppercase',
                                                    fontSize: '13px',
                                                    marginTop: '10px',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {props.data.StoreAddressline1}
                                            </p>
                                        )}
                                    </>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.storecity + ',' + props.data.storezip}
                                    </p>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.Storephonenum)}{' '}
                                    </p>
                                </div>
                                <div style={{ width: '30.33%', float: 'left' }}>
                                    <h5 style={{ fontSize: '13px', marginBottom: '10px' }}>
                                        Customer
                                    </h5>
                                    <p
                                        style={{
                                            textTransform: 'uppercase',
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {props.data.CustomerName}{' '}
                                    </p>
                                    {props.data.CustomerAddressline2 !== undefined &&
                                        props.data.CustomerAddressline2 !== null &&
                                        props.data.CustomerAddressline2 !== '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.CustomerAddressline1}
                                        </p>
                                    )}

                                    {props.data.customerplus4 != undefined &&
                                        props.data.customerplus4 != null &&
                                        props.data.customerplus4 != '' ? (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                textTransform: 'uppercase',
                                                fontSize: '13px',
                                                marginTop: '10px',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            {props.data.customercity + ',' + props.data.customerzip}
                                        </p>
                                    )}
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {normalizeinput(props.data.CustomerPhoneNumber)}
                                    </p>
                                </div>
                                <div
                                    style={{
                                        width: '36.33%',
                                        float: 'left',
                                        marginTop: '12px',
                                    }}
                                >
                                    <table>
                                        <thead />
                                        <tbody style={{ width: '100%', float: 'left' }}>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Delivery Date:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.DeliveryDate}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Agreement # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Customer # :
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.customerInfo?.customerId}{' '}
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '50%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginBottom: '0.2rem',
                                                        color: '#111111',
                                                        fontSize: '13px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Next Payment Due:
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    width: '40%',
                                                    marginTop: '10px',
                                                    textAlign: 'left',
                                                    float: 'left',
                                                }}
                                            >
                                                <td
                                                    style={{
                                                        marginTop: '5px',
                                                        color: '#656565',
                                                        fontSize: '13px',
                                                    }}
                                                >
                                                    {props.data.nextpaymentinfo.nextamountdue !== undefined &&
                                                        props.data.nextpaymentinfo.nextamountdue !== null
                                                        ? '$' + ' ' + props.data.nextpaymentinfo.nextamountdue
                                                        : ''}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {/*second part ends here */}
                        {/*3rd part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                            }}
                        >
                            <div
                                style={{ width: '100%', float: 'left', marginTop: '15px' }}
                            >
                                <h5
                                    style={{
                                        textTransform: 'uppercase',
                                        textAlign: 'center',
                                        fontSize: '13px',
                                        margin: '0px',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Items
                                </h5>
                            </div>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '10px' }}
                            >
                                <table style={{ marginBottom: '20px', fontSize: '12px' }}>
                                    <thead>
                                        <tr style={{ width: '100%' }}>
                                            <th
                                                style={{
                                                    width: '15px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            ></th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Description of Property
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Serial #
                                            </th>
                                            <th
                                                style={{
                                                    width: '300px',
                                                    textAlign: 'left',
                                                    paddingLeft: '12px',
                                                    paddingRight: '12px',
                                                    paddingTop: '5px',
                                                    paddingBottom: '5px',
                                                }}
                                            >
                                                Item #
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{bindChecklist('ResendVideo')}</tbody>
                                </table>
                            </div>
                        </div>
                        {/*3rd part ends*/}
                        {/*fourth part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: borderrr,
                                marginBottom: '10px',
                            }}
                        >
                            <div style={{ width: '100%', float: 'left' }}>
                                <p
                                    style={{
                                        fontSize: '14px',
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                        textAlign: 'center',
                                        textDecorationLine: 'underline',
                                    }}
                                >
                                    Before merchandise is taken from the customer&apos; home,
                                    please check the condition of the following areas and note
                                    any damage:
                                </p>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginBottom: '10px',
                                    marginTop: '30px',
                                }}
                            >
                                <div style={{ width: '15%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Wall(s)
                                    </span>
                                </div>
                                <div style={{ width: '15%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        {' '}
                                        Floors
                                    </span>
                                </div>
                                <div style={{ width: '22%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Door(s)/Doorway(s)
                                    </span>
                                </div>
                                <div style={{ width: '17%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Stairs
                                    </span>
                                </div>
                                <div style={{ width: '8%', float: 'left' }}>
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: borderalone,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', fontWeight: 'bold' }}>
                                        Other
                                    </span>
                                </div>
                                <div
                                    style={{
                                        width: '15%',
                                        float: 'left',
                                        marginTop: '12px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    <span
                                        style={{
                                            borderBottom: '1px solid#545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginTop: '10px',
                                    marginBottom: '10px',
                                }}
                            >
                                <div style={{ width: '40%', float: 'left' }}>
                                    <p
                                        style={{
                                            fontSize: '13px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        If any boxes were checked, explain damage:
                                    </p>
                                </div>
                                <div
                                    style={{ width: '60%', float: 'right', marginTop: '20px' }}
                                >
                                    <span
                                        style={{
                                            borderBottom: '1px solid#545454',
                                            width: '100%',
                                            float: 'left',
                                        }}
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    float: 'left',
                                    marginTop: '10px',
                                    marginBottom: '20px',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '12px',
                                        marginTop: 10,
                                        marginBottom: 10,
                                        backgroundColor: "#E0EAFF"
                                    }}
                                >
                                    <input
                                        style={{ height: 16, width: 16 }}
                                        type="checkbox"
                                        defaultValue=""
                                        id="flexCheckDefault-7"
                                        checked={false}

                                    />
                                    <label
                                        htmlFor="flexCheckDefault-6"
                                        style={{
                                            marginLeft: 10,
                                            fontFamily: 'arial',
                                            fontSize: 13,
                                            color: '#4A5174',
                                        }}
                                    >
                                        Your 100% satisfaction and knowledge of your agreement is very important to us. Please call the Store Manager at <a style={{ color: "#2468ff", textDecoration: 'underline', fontSize: "13px", cursor: 'pointer' }} href={`tel:${phoneNumberFormat9prefix(decodestoreDetails.workPhoneNumber)}`}>{phoneNumberFormat(decodestoreDetails.workPhoneNumber)}</a>
                                    </label>
                                </div>
                                <div style={{ width: '100%', float: 'left' }}>
                                    <h5
                                        style={{
                                            textAlign: 'left',
                                            fontSize: '14px',
                                            marginTop: '0px',
                                            textDecoration: 'underline',
                                        }}
                                    >
                                        Accept / Decline (Check one)
                                    </h5>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        float: 'left',
                                        marginBottom: '10px',
                                    }}
                                >
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            fontSize: '12px',
                                            marginTop: '10px',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        I acknowledge that Rent-A-Center has delivered, installed
                                        and demonstrated, to my satisfaction, the rental property
                                        listed above.
                                    </span>
                                </div>
                                <div
                                    style={{ width: '100%', float: 'left', marginTop: '10px' }}
                                >
                                    <span
                                        style={{
                                            padding: PaddingConst,
                                            borderRadius: borderRadiuss,
                                            border: Constinter,
                                            marginRight: '10px',
                                        }}
                                    />
                                    <span style={{ fontSize: '12px', marginBottom: '10px' }}>
                                        I decline delivery by Rent-A-Center of the rental property
                                        listed above.
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/*-fourth part ends*/}
                        {/*fifth part starts*/}
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginBottom: '20px',
                                marginTop: '10px',
                            }}
                        >
                            <table style={{ width: '100%', float: 'left' }}>
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Customer Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                style={{ width: '100%', float: 'left', marginTop: '25px' }}
                            >
                                <thead />
                                <tbody style={{ width: '100%', float: 'left' }}>
                                    <tr
                                        style={{
                                            width: '18%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Co-Worker Signature:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '28%',
                                            float: 'left',
                                            paddingRight: '25px',
                                            whiteSpace: 'nowrap',
                                            marginLeft: '8px',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '18px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                    <tr
                                        style={{
                                            width: '8%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginBottom: '0.2rem',
                                                color: '#111111',
                                                fontSize: '13px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            Date:
                                        </td>
                                    </tr>
                                    <tr
                                        style={{
                                            width: '32%',
                                            float: 'left',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        <td
                                            style={{
                                                marginTop: '15px',
                                                color: '#656565',
                                                borderBottom: borderbotttom,
                                                width: '100%',
                                                float: 'left',
                                            }}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                            {/*fifth part ends*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* delivery addendum inner html starts here */}
            <div id="deliveryaddendum">
                <meta charSet="UTF-8" />
                <title>Delivery Addendum And Confirmation</title>
                <div style={{ width: '100%', float: 'left' }}>
                    {/*Heading section starts here*/}
                    <div
                        style={{
                            width: '100%',
                            float: 'left',
                            textAlign: 'center',
                            marginBottom: '20px',
                        }}
                    >
                        <h3 style={{ fontWeight: 'bold' }}>
                            Delivery Addendum and Confirmation
                        </h3>
                    </div>
                    {/*Heading section ends here*/}
                    {/*detail section starts here*/}
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                        <div style={{ width: '50%', float: 'left' }}>
                            <label
                                style={{
                                    float: 'left',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                Date:
                            </label>
                            <span
                                style={{
                                    borderBottom: '1px solid #000',
                                    paddingBottom: '10px',
                                    width: '30%',
                                    float: 'left',
                                    marginLeft: '15px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                {props.data.DeliveryDate}
                            </span>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                            <label
                                style={{
                                    float: 'left',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}
                            >
                                Agreement Number:
                            </label>
                            <span
                                style={{
                                    borderBottom: '1px solid #000',
                                    paddingBottom: '10px',
                                    width: '42%',
                                    float: 'left',
                                    marginLeft: '15px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                {props.data.currentappointmentDetails && props.data.currentappointmentDetails?.agreement?.agreementNumber}
                            </span>
                        </div>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '30px' }}>
                        <div style={{ width: '50%', float: 'left' }}>
                            <div style={{ width: '20%', float: 'left' }}>
                                <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Lessor:
                                </label>
                            </div>
                            <div style={{ width: '80%', float: 'left' }}>
                                <span
                                    style={{
                                        borderBottom: '1px solid #000',
                                        paddingBottom: '10px',
                                        width: '80%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.data.storename}
                                </span>
                                {props.data.StoreAddressline2 !== undefined &&
                                    props.data.StoreAddressline2 !== null &&
                                    props.data.StoreAddressline2 !== '' ? (
                                    <span
                                        style={{
                                            borderBottom: '1px solid #000',
                                            paddingBottom: '10px',
                                            width: '80%',
                                            float: 'left',
                                            marginTop: '5px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                    </span>
                                ) : <span
                                    style={{
                                        borderBottom: '1px solid #000',
                                        paddingBottom: '10px',
                                        width: '80%',
                                        float: 'left',
                                        marginTop: '5px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.data.StoreAddressline1 + ' , ' + props.data.StoreAddressline2}
                                </span>
                                }

                                <span
                                    style={{
                                        borderBottom: '1px solid #000',
                                        paddingBottom: '10px',
                                        width: '80%',
                                        float: 'left',
                                        marginTop: '5px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.data.storecity + ',' + props.data.storezip}
                                </span>
                            </div>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                            <div style={{ width: '25%', float: 'left' }}>
                                <label style={{ fontWeight: 'bold', fontSize: '14px' }}>
                                    Consumer:
                                </label>
                            </div>
                            <div style={{ width: '65%', float: 'left' }}>
                                <span
                                    style={{
                                        borderBottom: '1px solid #000',
                                        paddingBottom: '10px',
                                        width: '91%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.data.CustomerName}
                                </span>
                                <span
                                    style={{
                                        borderBottom: '1px solid #000',
                                        paddingBottom: '10px',
                                        width: '91%',
                                        float: 'left',
                                        marginTop: '5px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.data.CustomerAddressline1 + ',' + props.data.CustomerAddressline2}
                                </span>
                                <span
                                    style={{
                                        borderBottom: '1px solid #000',
                                        paddingBottom: '10px',
                                        width: '91%',
                                        float: 'left',
                                        marginTop: '5px',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    {props.data.customercity + ',' + props.data.customerzip + '-' + props.data.customerplus4}
                                </span>
                            </div>
                        </div>
                    </div>
                    {/*detail section ends here*/}
                    {/*Agreement parah section starts here*/}
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                        <p
                            style={{
                                paddingRight: '70px',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                        >
                            Upon execution by you, this document will be incorporated into
                            and become part of the Rental-Purchase / Lease Purchase
                            Agreement referenced above ( the "Agreement"). By accepting
                            delivery and signing below, you are confirming that the
                            merchandise described below has been delivered and that the
                            serial number(s) listed correctly identifies the items listed on
                            your Agreement.
                        </p>
                    </div>
                    {/*Agreement parah section ends here*/}
                    {/*DESCRIPTION table section starts here*/}
                    <div style={{ width: '100%', float: 'left', marginBottom: '60px' }}>
                        <h4
                            style={{
                                padding: '5px',
                                fontSize: '16px',
                                marginBottom: '0px',
                                borderTop: '1px solid #000',
                                borderBottom: '1px solid #000',
                                borderLeft: '1px solid #000',
                                borderRight: '1px solid #000',
                            }}
                        >
                            {' '}
                            DESCRIPTION OF DELIVERED MERCHANDISE
                        </h4>
                        <table
                            style={{
                                width: '100%',
                                float: 'left',
                                borderBottom: '1px solid #000',
                                borderLeft: '1px solid #000',
                                borderRight: '1px solid #000',
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            paddingTop: '12px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Item #
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '12px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Item Description
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '12px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Serial #
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '12px',
                                            fontSize: '14px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        Model #
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        style={{
                                            paddingTop: '10px',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            paddingBottom: '25px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid #000',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            9999207978448
                                        </span>{' '}
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '10px',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            paddingBottom: '25px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid #000',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            MAYTAG 7.0 CF. ELECTRIC DRYER 15
                                        </span>
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '10px',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            paddingBottom: '25px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid #000',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            999922029478746059
                                        </span>
                                    </td>
                                    <td
                                        style={{
                                            paddingTop: '10px',
                                            fontWeight: 600,
                                            fontSize: '14px',
                                            paddingBottom: '25px',
                                        }}
                                    >
                                        <span
                                            style={{
                                                borderBottom: '1px solid #000',
                                                paddingBottom: '10px',
                                            }}
                                        >
                                            MEDC465HW
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/*DESCRIPTION table section ends here*/}
                    {/*PLEASE INITIAL section starts here*/}
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                        <label
                            style={{
                                marginBottom: '25px',
                                width: '100%',
                                float: 'left',
                                fontSize: '16px',
                                fontWeight: 600,
                            }}
                        >
                            PLEASE INITIAL ONE OF THE FOLLOWING:
                        </label>
                        <div
                            style={{
                                width: '100%',
                                float: 'left',
                                marginBottom: '15px',
                                fontSize: '14px',
                                fontWeight: 600,
                            }}
                        >
                            <span
                                style={{
                                    borderBottom: '1px solid #000',
                                    width: '45px',
                                    float: 'left',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            />
                            <label
                                style={{
                                    marginBottom: '10px',
                                    marginLeft: '15px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                I acknowledge that Rent-A-Center has delivered the rental
                                property listed above.
                            </label>
                        </div>
                        <div
                            style={{ width: '100%', float: 'left', marginBottom: '15px' }}
                        >
                            <span
                                style={{
                                    borderBottom: '1px solid #000',
                                    width: '45px',
                                    float: 'left',
                                    marginTop: '20px',
                                    marginBottom: '20px',
                                }}
                            />
                            <label
                                style={{
                                    marginBottom: '10px',
                                    marginLeft: '15px',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                }}
                            >
                                I decline delivery by Rent-A-Center of the rental property
                                listed above.
                            </label>
                        </div>
                        <p
                            style={{
                                fontWeight: 'bold',
                                marginBottom: '40px',
                                fontSize: '16px',
                            }}
                        >
                            YOU AGREE BY SIGNING THIS DELIVERY CONFIRMATION SUPPLEMENT THAT
                            (1) YOU HAVE READ IT, (2) THE INFORMATION ABOVE IS CORRECTLY
                            STATED, AND (3) YOU RECEIVED A COPY OF IT.
                        </p>
                    </div>
                    <div style={{ width: '100%', float: 'left', marginBottom: '20px' }}>
                        <div style={{ width: '50%', float: 'left' }}>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                            >
                                <label
                                    style={{
                                        borderTop: '1px solid #000',
                                        paddingTop: '10px',
                                        width: '75%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    CUSTOMER NAME (PRINT)
                                </label>
                            </div>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                            >
                                <label
                                    style={{
                                        borderTop: '1px solid #000',
                                        paddingTop: '10px',
                                        width: '75%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    CUSTOMER SIGNATURE
                                </label>
                            </div>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                            >
                                <label
                                    style={{
                                        borderTop: '1px solid #000',
                                        paddingTop: '10px',
                                        width: '35%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    DATE
                                </label>
                            </div>
                        </div>
                        <div style={{ width: '50%', float: 'left' }}>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                            >
                                <label
                                    style={{
                                        borderTop: '1px solid #000',
                                        paddingTop: '10px',
                                        width: '75%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    CUSTOMER NAME (PRINT)
                                </label>
                            </div>
                            <div
                                style={{ width: '100%', float: 'left', marginBottom: '40px' }}
                            >
                                <label
                                    style={{
                                        borderTop: '1px solid #000',
                                        paddingTop: '10px',
                                        width: '75%',
                                        float: 'left',
                                        fontSize: '14px',
                                        fontWeight: 600,
                                    }}
                                >
                                    CUSTOMER SIGNATURE
                                </label>
                            </div>
                        </div>
                    </div>
                    {/*PLEASE INITIAL section ends here*/}
                </div>
            </div>
            {/* delivery addendum inner html ends here */}
        </Grid>
    )
})

export default ReprintReceiptCode