/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable prettier/prettier */
/* eslint-disable  sonarjs/cognitive-complexity */
/* eslint-disable  sonarjs/no-duplicate-string*/
import React, { useEffect, useState } from 'react';
import {
  Grid,
  RACButton,
  RACModalCard,
  Typography,
} from '@rentacenter/racstrap';
import moment from 'moment';
import { getDrawerDetail, getConfigDetails } from '../../api/user';
import { useHistory } from 'react-router-dom';
import { customerStyles } from '../../customerStyles';
export default function CashManagementPopups() {
  const classes = customerStyles();
  const history = useHistory();
  useEffect(() => {
    PageLoad();
  }, []);

  const [successPopup, setSuccessPopup] = useState(false);
  const [successReconcilPopup, setSuccessReconcilPopup] = useState(false);
  const [popupText, setPopText] = useState('');
  const [navPath, setNavPath] = useState('/dashboard/homepage');

  const PageLoad = async () => {
    const payload: any = {
      storeNumbers: [sessionStorage.getItem('storeNumber')],
      paramKeyNames: ['MaxDrawerLimit', 'ReconciliationMaxLimit'],
    };
    const storeNumber = window.sessionStorage.getItem('storeNumber');
    const currentDate = moment().format('YYYY-MM-DD')
    const popUpDetails = await Promise.all([
      getDrawerDetail({
        storeNumber: storeNumber,
        businessDate: moment().format('YYYY-MM-DD'),
      }),
      getConfigDetails(payload),
      getDrawerDetail({
        storeNumber: storeNumber,
      }),
    ]);

    console.log(popUpDetails);

    const configDetailsResponse = popUpDetails[1].data;
    const drawerDetails = popUpDetails[0].data;
    const reconcilDetails = popUpDetails[2].data;
    console.log(configDetailsResponse);
    const configVal: any = {
      MaxDrawerLimit: '',
      ReconciliationMaxLimit: '',
    };

    // console.log(configValues)
    configDetailsResponse.storeProfileResponse.configDetails[0].configDetails.forEach(
      (el: any) => {
        if (el.paramKeyName == 'MaxDrawerLimit') {
          configVal.MaxDrawerLimit = el.paramValue;
        }
        if (el.paramKeyName == 'ReconciliationMaxLimit') {
          configVal.ReconciliationMaxLimit = el.paramValue;
        }
      }
    );
    const reconcilSummary = reconcilDetails.dropSummary &&  reconcilDetails.dropSummary.length > 0 ? reconcilDetails.dropSummary.filter((e : any) => e.bankDepositId == null):[];
    const sortArray =  reconcilSummary &&  reconcilSummary.length >0  ? reconcilSummary.sort((a: any, b: any) => Date.parse(a.activityDate) - Date.parse(b.activityDate)):[];
    const lastReconciledDays = sortArray.length > 0 ?  moment().diff(moment(sortArray[0].activityDate).format('YYYY-MM-DD'),'days') :0;
    console.log('lastReconciledDays', lastReconciledDays, sortArray )

    const totalPayment = (parseFloat(drawerDetails?.storeTransaction?.totalCash !== undefined &&
      drawerDetails?.storeTransaction?.totalCash !== null &&
      drawerDetails?.storeTransaction?.totalCash !== ''
        ? drawerDetails?.storeTransaction?.totalCash
        : '0') + parseFloat(drawerDetails?.storeTransaction?.totalNonCash !== undefined &&
          drawerDetails?.storeTransaction?.totalNonCash !== null &&
          drawerDetails?.storeTransaction?.totalNonCash !== ''
            ? drawerDetails?.storeTransaction?.totalNonCash: '0')).toFixed(2);
    
    console.log('Drawer Details and config', configVal, drawerDetails);
    if(drawerDetails !=='' && drawerDetails.storeTransaction.lastReconciledDate !== '' && Number(lastReconciledDays) >= Number(configVal.ReconciliationMaxLimit)){
      setPopText('Please Perform Drop Reconciliation Before Proceeding');
      setNavPath('/cashmgmt/reconcil');
      setSuccessReconcilPopup(true);
    }
    else if (
      parseFloat(
        configVal.MaxDrawerLimit !== undefined &&
          configVal.MaxDrawerLimit !== null &&
          configVal.MaxDrawerLimit !== ''
          ? configVal.MaxDrawerLimit
          : '0'
      ) <
      parseFloat(totalPayment)
    ) {

      const exceedAmount = ( parseFloat(totalPayment) - parseFloat(configVal.MaxDrawerLimit)).toFixed(2)
      setPopText(`Cash drawer amount exceeds the maximum allowable drawer amount by $ ${exceedAmount}. Do you want to perform a Drop?`);
      setNavPath('/cashmgmt/drop');
      setSuccessPopup(true);
    } else {
      setSuccessPopup(false);
      console.log('Inside else');
    }
  };

  const cashManagementPopup = () => {
    return (
      <Grid item md={12} className="px-2">
        <Grid
          item
          md={12}
          className="text-center"
          style={{ textAlign: 'center' }}
        >
          <Typography
            variant={'body2'}
            style={{ marginTop: '1%', marginBottom: '1%' }}
          >{popupText}</Typography>
        </Grid>
        <Grid
          container
          className="text-center mt-4"
          style={{ justifyContent: 'center', marginTop: '20px' }}
        >
          <RACButton
            className={`${classes.spacerMR2} ${classes.foc}`}
            size="small"
            color="primary"
            variant="contained"
            isRounded={false}
            style={{
              textTransform: 'none',
              // eslint-disable-next-line sonarjs/no-duplicate-string
              width: '100px !important',
              height: '35px',
            }}
            onClick={() => {
              setSuccessPopup(false);
              history.push({
                pathname: navPath,
              });
            }}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid>
        <RACModalCard
          isOpen={successPopup}
          maxWidth="xs"
          closeIcon={true}
          borderRadius="20px"
          onClose={() => {setSuccessPopup(false)
          }}
          // eslint-disable-next-line react/no-children-prop
          children={cashManagementPopup()}
        />
      </Grid>
      <Grid>
        <RACModalCard
          isOpen={successReconcilPopup}
          maxWidth="xs"
          closeIcon={false}
          borderRadius="20px"
          onClose={() => {setSuccessPopup(false)
          }}
          // eslint-disable-next-line react/no-children-prop
          children={cashManagementPopup()}
        />
      </Grid>
    </>
  );
}
