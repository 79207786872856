export const ProductType = 'RPDDASH';

export enum ProductPermissions {
  DASHBOARD = 'DASHBOARD',
}

const PENDING_VERIFICATION_STATUS = 'PENDING';

const DE_CONDITIONALLY_APPROVED_STATUS = 'DE-AV';

const DE_ENGINE_APPROVED_STATUS = 'DE-A';

const DE_APPROVAL_CHANGE_FLOW = true;

export {
  PENDING_VERIFICATION_STATUS,
  DE_CONDITIONALLY_APPROVED_STATUS,
  DE_APPROVAL_CHANGE_FLOW,
  DE_ENGINE_APPROVED_STATUS,
};
