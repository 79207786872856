import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './app/App';
import './index.css';

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}
export interface CustomPropInterface {
  GetRole: () => string;
  GetEmployeeId: () => string;
}
window.sessionStorage.getItem('storeNumber');
window.renderDashboard = (
  containerId: string,
  history: History,
  storeNumber: string,
  customProps?: CustomPropInterface
) => {
  const elem = document.getElementById(containerId);

  /* eslint-disable no-console */
  console.log('index dashboard', containerId);
  /* eslint-disable no-console */
  console.log('index dashboard storenumber', storeNumber);

  if (elem) {
    ReactDOM.render(
      <App
        history={history}
        storeNumber={storeNumber}
        customProps={customProps}
      />,
      elem
    );
  }
};

window.unmountDashboard = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });

    window.renderDashboard('root', defaultHistory, 'storeNumber');
  });
}
