/* eslint-disable */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { ReactComponent as UpwardArrow } from '../../assets/images/up-arrow.svg';
import { ReactComponent as DownArrow } from '../../assets/images/down-arrow.svg';
import { ReactComponent as Navigation } from '../../assets/images/navigator.svg';
import {
  makeStyles,
  Card,
  Grid,
  Typography,
  CardContent,
  RACModalCard,
  RACChip,
  RACTooltip,
} from '@rentacenter/racstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { featureFlags, getFreeTimeBalance, getStatsInfo } from '../../api/user';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

//UI
const useClasses = makeStyles((theme: any) => ({
  [theme.breakpoints.between(850, 1281)]: {
    dTabNone:{
      display:'none'
    },
  },
  [theme.breakpoints.between(599, 850)]: {
    dTabNone:{
      display:'none'
    },
  },
  w100: {
    width: '100%',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(8),
  },
  title: {
    color: '#212529',
    fontFamily: 'OpenSans-bold!important',
    fontSize: '16px!important',
    marginBottom: '10px!important',
  },
  titleColor: {
    color: 'gray',
  },
  infoTextStyle: {
    color: 'gray',
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  mb4: {
    marginBottom: theme.typography.pxToRem(24),
  },

  mb5: {
    marginBottom: theme.typography.pxToRem(48),
  },
  my4: {
    marginTop: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(24),
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },

  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  mt3: {
    marginTop: theme.typography.pxToRem(16),
  },
  pt3: {
    paddingTop: theme.typography.pxToRem(16),
  },
  pt2: {
    paddingTop: theme.typography.pxToRem(8),
  },
  pt1: {
    paddingTop: theme.typography.pxToRem(4),
  },
  statsBarContent: {
    color: '#fff',
    //eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    marginRight: '-0.75rem',
    marginLeft: '-0.75rem',
  },
  cardBackground: {
    background: '#2179FE',
  },
  totalAorContent: {
    float: 'left',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
    marginTop: '0.5rem',
    lineHeight: '28px',
    width: 'auto',
    fontSize: '16px',
  },
  col7: {
    flex: '0 0 auto',
    width: '49.3333333333%',
    maxWidth: '100%',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
  statsCount: {
    fontSize: '20px',
    fontFamily: 'OpenSans-semibold',
    float: 'left',
  },
  increaseBadge: {
    backgroundColor: '#5AE286',
    color: 'white',
    fontSize: '12px',
    padding: '1px 7px',
    borderRadius: '22px',
    margin: '0px 0px 0px 3px',
    float: 'left',
    marginTop: '5px',
    marginBottom: '5px',
  },
  decreaseBadge: {
    backgroundColor: '#ff4646',
    color: 'white',
    fontSize: '12px',
    padding: '1px 7px',
    borderRadius: '22px',
    margin: '0px 0px 0px 3px',
    float: 'left',
    marginTop: '5px',
    marginBottom: '5px',
  },
  arrowImage: {
    marginRight: '0.25rem',
  },
  dayFont: {
    fontSize: '12px',
    color: '#fff',
    display: 'block',
    float: 'left',
    fontFamily: 'OpenSans-regular',
    width: '100%',
  },
  col2: {
    flex: '0 0 auto',
    width: '16.6666666667%',
    maxWidth: '100%',
    marginTop: '0',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
  alignItems: {
    display: 'flex',
    flexWrap: 'wrap',
    marginRight: '-0.75rem',
    marginLeft: '-0.75rem',
    alignItems: 'center',
  },
  cardContent: {
    paddingTop: '0.5rem !important',
    paddingBottom: '0.5rem !important',
    flex: '1 1 auto',
  },
  deliveryCount: {
    fontSize: '20px',
    fontFamily: 'OpenSans-semibold',
    float: 'left',
    flex: '0 0 auto',
    width: '17.6666666667%',
    maxWidth: '100%',
    // paddingRight: '0.75rem',
    // paddingLeft: '0.75rem',
    marginTop: '2px',
  },
  deliveryDetails: {
    float: 'left',
    paddingRight: '0.75rem',
    // paddingLeft: '0.75rem',
    lineHeight: '28px',
    width: 'auto',
    fontSize: '16px',
  },
  col4: {
    flex: '0 0 auto',
    width: '33.3333333333%',
    maxWidth: '100%',
    marginTop: '0',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
  col9: {
    flex: '0 0 auto',
    width: '68%',
    maxWidth: '100%',
    // paddingRight: '0.75rem',
    // paddingLeft: '0.75rem',
  },
  statsCountMT2: {
    marginLeft: '1rem',
    fontSize: '20px',
    fontFamily: 'OpenSans-semibold',
    float: 'left',
  },
  statsPercent: {
    fontSize: '14px',
    fontFamily: 'OpenSans-regular',
    float: 'right',
    marginTop: '5px',
    marginLeft: '3px',
  },
  navImage: {
    marginLeft: '0.25rem',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  navImageDefault: {
    marginLeft: '0.25rem',
    verticalAlign: 'middle',
  },
  commitmentCount: {
    fontSize: '20px',
    fontFamily: 'OpenSans-semibold',
    float: 'left',
    flex: '0 0 auto',
    width: '14.3333333333%',
    maxWidth: '100%',
    // paddingRight: '0.75rem',
    // paddingLeft: '0.75rem',
    marginTop: '2px',
  },
  commitmentModalTitle: {
    paddingLeft: '10px',
    fontSize: '20px',
    marginTop: '15px',
  },
  modalGrid: {
    marginTop: '-30%',
  },
  textCenter: {
    textAlign: 'center',
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  //todays commitments
  gridClass: {
    width: '100%',
  },
  listGroup: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0',
    marginBottom: '0',
    borderRadius: '0.25rem',
  },
  row: {
    bsGutterX: '1.5rem',
    bsGutterY: '0',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: 'calc(var(--bs-gutter-y) * -1)',
    marginRight: 'calc(var(--bs-gutter-x) / -2)',
    marginLeft: 'calc(var(--bs-gutter-x) / -2)',
  },
  columnTimeslot: {
    width: '12%',
    textAlign: 'center',
  },
  timeslot: {
    marginTop: '22px',
  },
  columnEvents: {
    width: '19%',
    margin: '1.5%',
    marginTop: '0px',
    marginRight: '1.5%',
    marginBottom: '0px',
    marginLeft: '1.5%',
    cursor: 'pointer',
  },
  listGroupItem: {
    position: 'relative',
    display: 'block',
    padding: '0.5rem 1rem',
    color: '#212529',
    textDecoration: 'none',
  },
  listGroupItemAction: {
    width: '100%',
    textAlign: 'inherit',
    marginBottom: '13px',
  },
  border: {
    border: '0',
  },
  upcomingpanel: {
    borderLeft: '3px solid #2E31BE !important',
    background: '#F4F7FF',
  },
  upcomingpanel1: {
    borderLeft: '3px solid #ab0100 !important',
    background: '#FFF7F7',
  },
  colLg12: {
    flex: '0 0 auto',
    width: '100%',
  },
  colxl8: {
    flex: '0 0 auto',
    width: '66.6666666667%',
  },
  mb1: {
    //eslint-disable-next-line sonarjs/no-duplicate-string
    marginBottom: '0.25rem !important',
  },
  upcomingeventsUsername: {
    fontFamily: 'OpenSans-bold',
    fontSize: '14px',
    color: '#2E31BE',
    cursor: 'pointer',
  },
  upcomingeventsUsername1: {
    fontFamily: 'OpenSans-bold',
    fontSize: '14px',
    color: '#ab0100',
    cursor: 'pointer',
  },
  colXl4: {
    flex: '0 0 auto',
    width: '33.3333333333%',
  },
  upcomingeventDelivery: {
    background: '#FFA61E',
    float: 'left',
    marginTop: '4px',
    marginRight: '6px',
  },
  upcomingeventStatus: {
    width: '10px',
    height: '10px',
    display: 'inline-block',
    borderRadius: '50px',
  },
  upcomingeventCarryOut: {
    background: '#26C0C7',
    float: 'left',
    marginTop: '4px',
    marginRight: '6px',
  },
  fs12: {
    fontSize: '12px',
  },
  right: {
    float: 'right',
  },
  marginLeftCommitment: {
    marginLeft: '246px',
  },
  chipStyle: {
    padding: '0px 10px',
    float: 'right',
    cursor: 'pointer',
  },
  mailColor: {
    backgroundColor: '#FFA61E',
    color: 'white',
  },
  paymentColor: {
    backgroundColor: '#48C69A',
    color: 'white',
  },
  returnColor: {
    backgroundColor: '#BB2C5E',
    color: 'white',
  },
  payOtherStoreColor: {
    backgroundColor: '#B96AD6',
    color: 'white',
  },
  circleOpen: {
    width: '10px',
    height: '10px',
    float: 'left',
    borderRadius: '20px',
    marginTop: '4px',
    marginRight: '7px',
    background: '#2E31BE',
  },
  circleBroken: {
    width: '10px',
    height: '10px',
    float: 'left',
    borderRadius: '20px',
    marginTop: '4px',
    marginRight: '7px',
    background: '#ab0100',
  },
  floatLeft: {
    float: 'left',
  },
  me48: {
    marginRight: theme.typography.pxToRem(48),
  },
  w99Abslte: {
    width: '99%',
    position: 'absolute',
  },
  legendStyleModal: {
    marginBottom: '25px',
    position: 'sticky',
    top: 0,
    zIndex: 100,
  },
}));
//eslint-disable-next-line sonarjs/cognitive-complexity
export default function StatsBar() {
  const classes = useClasses();
  const [openCommitments, setOpenCommitments] = useState(false);
  const [skeleLoader, setSkeleLoader] = useState(false);
  const history = useHistory();
  const [statsBar, setstatsBar] = useState<any>();
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [groupdedstatsBar, setgroupdedstatsBar] = useState<any>();
  const [timeInterval, setTimeInterval] = useState(0);
  //cappedFreeTime starts
  const [cappedFreeTimeValues, setCappedFreeTimeValues] = useState<any>({
    cappedFreeTimePercentage: 0,
    cappedFreeTimeRegularFlag: false,
    cappedFreeTimeServiceFlag: false,
    cappedFreeTimeRetentionFlag: false,
    cappedFreeTimeInitPaymentFlag: false,
    cappedFreeTimePromoFlag: false,
    enableCappedFreeTime: false
  });
  const [remainingAmount, setRemainingAmount] = useState<number>(0);
  //capped End

  setTimeout(() => {
    setTimeInterval(timeInterval + 1);
  }, 300000);

  useEffect(() => {
    const PageLoad = async () => {
      /* eslint-disable no-console */
      console.log(
        'storenumber window',
        window,
        window.sessionStorage.getItem('storeNumber')
      );
      if (window.sessionStorage.getItem('storeNumber') != undefined) {
        setSkeleLoader(false);
        const currentDate = new Date();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Add 1 to month since it is zero-based
        const day = currentDate.getDate().toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const formattedDate = `${month}/${day}/${year}`;

        const cappedFreeTimeValuesLocal : any = {
          cappedFreeTimePercentage: 0,
          cappedFreeTimeRegularFlag: false,
          cappedFreeTimeServiceFlag: false,
          cappedFreeTimeRetentionFlag: false,
          cappedFreeTimeInitPaymentFlag: false,
          cappedFreeTimePromoFlag: false,
          enableCappedFreeTime: false
        };

        const bodyobj = {
          storeNumbers: [window.sessionStorage.getItem('storeNumber')],
  
          paramKeyNames: [
            "CappedFreeTimePercentage",
            "CappedFreeTimeRegular",
            "CappedFreeTimeService",
            "CappedFreeTimePromo",
            "CappedFreeTimeInitPayment",
            "CappedFreeTimeRetention",
            'CappedFreeTimeEnabled',
            'RacdbStoreStats'
          ]
        };
        const featureFlag = await featureFlags(bodyobj);
        debugger;
        const configDetails = featureFlag?.data?.storeProfileResponse?.configDetails;
        let enableCappedFreeTime: any;
        let dashboardStoreStats : string =  'N';
        if (configDetails && configDetails.length) {
          const firstConfigDetails = configDetails[0].configDetails;
          if (firstConfigDetails && firstConfigDetails.length) {
            firstConfigDetails.forEach((el) => {
              switch (el.paramKeyName) {
                case "CappedFreeTimePercentage":
                  updateCappedFreeTimeResponseValues(
                    'cappedFreeTimePercentage', Number(el.paramValue), setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.cappedFreeTimePercentage = Number(el.paramValue);
                  break;
                case "CappedFreeTimeRegular":
                  updateCappedFreeTimeResponseValues(
                    'cappedFreeTimeRegularFlag', Number(el.paramValue) > 0 ? true : false, setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.cappedFreeTimeRegularFlag = Number(el.paramValue) > 0 ? true : false;
                  break;
                case "CappedFreeTimeService":
                  updateCappedFreeTimeResponseValues(
                    'cappedFreeTimeServiceFlag', Number(el.paramValue) > 0 ? true : false, setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.cappedFreeTimeServiceFlag = Number(el.paramValue) > 0 ? true : false;
                  break;
                case "CappedFreeTimeRetention":
                  updateCappedFreeTimeResponseValues(
                    'cappedFreeTimeRetentionFlag', Number(el.paramValue) > 0 ? true : false, setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.cappedFreeTimeRetentionFlag = Number(el.paramValue) > 0 ? true : false;
                  break;
                case "CappedFreeTimeInitPayment":
                  updateCappedFreeTimeResponseValues(
                    'cappedFreeTimeInitPaymentFlag', Number(el.paramValue) > 0 ? true : false, setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.cappedFreeTimeInitPaymentFlag = Number(el.paramValue) > 0 ? true : false;
                  break;
                case "CappedFreeTimePromo":
                  updateCappedFreeTimeResponseValues(
                    'cappedFreeTimePromoFlag', Number(el.paramValue) > 0 ? true : false, setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.cappedFreeTimePromoFlag = Number(el.paramValue) > 0 ? true : false;
                  break;
                case "CappedFreeTimeEnabled":
                  updateCappedFreeTimeResponseValues(
                    'enableCappedFreeTime', Number(el.paramValue) > 0 ? true : false, setCappedFreeTimeValues
                  );
                  cappedFreeTimeValuesLocal.enableCappedFreeTime = Number(el.paramValue) > 0 ? true : false;
                  enableCappedFreeTime = Number(el.paramValue) > 0 ? true : false;
                  break;
                  case "RacdbStoreStats":
                    dashboardStoreStats = Number(el.paramValue) > 0 ? 'Y' : 'N';
                    break;
              }
            });
          }
        }
        const response = await getStatsInfo(formattedDate, dashboardStoreStats);
        if(enableCappedFreeTime) {
          getCappedFreeTimePercentage(cappedFreeTimeValuesLocal)
        }
        else{
          setSkeleLoader(true);
        }
        /* eslint-disable no-console */
        console.log('statsBar reponse', response);
        setstatsBar(response.data != undefined ? response.data : []);
        setgroupdedstatsBar(response.data != undefined ? response.data : []);
      }
    };
    PageLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeInterval]);

  async function getCappedFreeTimePercentage(cappedFreeTimeValues: any) {
    setSkeleLoader(false);
    console.log(cappedFreeTimeValues, 'cappedFreeTimeValues');
    const payload = {
      agreementNumbers: null,
      percentage: cappedFreeTimeValues?.cappedFreeTimePercentage ? cappedFreeTimeValues?.cappedFreeTimePercentage.toString() : "10",
      storeNumber: sessionStorage.getItem('storeNumber'),
    }
    const cappedFreeTimeResponse = await getFreeTimeBalance(payload);
    setSkeleLoader(true);
    console.log(cappedFreeTimeResponse, 'cappedFreeTimeResponse');
    if (cappedFreeTimeResponse?.status == 200 && cappedFreeTimeResponse?.data) {
      const monthlyRates = cappedFreeTimeResponse?.data?.monthSummary;
      const SMRRAmount = cappedFreeTimeResponse?.data?.smrPercentageAmount;
      console.log(monthlyRates, 'monthlyRates');
      let usedFreeTimeAmount : number = 0;
      debugger;
      if (cappedFreeTimeValues.cappedFreeTimeRegularFlag) {
        usedFreeTimeAmount += Number(monthlyRates?.ftOtherTotalAmount) || 0;
      }

      if (cappedFreeTimeValues.cappedFreeTimeServiceFlag) {
        usedFreeTimeAmount += Number(monthlyRates?.ftServiceTotalAmount) || 0;
      }

      if (cappedFreeTimeValues.cappedFreeTimeRetentionFlag) {
        usedFreeTimeAmount += Number(monthlyRates?.ftReiTotalAmount) || 0;
      }

      if (cappedFreeTimeValues.cappedFreeTimeInitPaymentFlag) {
        usedFreeTimeAmount += Number(monthlyRates?.ftDelTotalAmount) || 0;
      }

      if (cappedFreeTimeValues.cappedFreeTimePromoFlag) {
        usedFreeTimeAmount += Number(monthlyRates?.ftPromoTotalAmount) || 0;
      }

      console.log(parseFloat(SMRRAmount) - usedFreeTimeAmount,SMRRAmount, usedFreeTimeAmount, 'sumvalues')
      setRemainingAmount(parseFloat(SMRRAmount) - usedFreeTimeAmount);
    }
    else{
      /*setting the remaining amount to 0 */
      setRemainingAmount(0);
    }
  }

  // const getCappedFreeTimeDetails = async (configResponse: any) => {
  //   const configDetails = configResponse?.storeProfileResponse?.configDetails;
    
  //   if (configDetails && configDetails.length) {
  //     const firstConfigDetails = configDetails[0].configDetails;
  
  //     if (firstConfigDetails && firstConfigDetails.length) {
  //       firstConfigDetails.forEach((el) => {
  //         switch (el.paramKeyName) {
  //           case "CappedFreeTimePercentage":
  //             updateCappedFreeTimeResponseValues(
  //               'cappedFreeTimePercentage', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           case "CappedFreeTimeRegular":
  //             updateCappedFreeTimeResponseValues(
  //               'cappedFreeTimeRegular', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           case "CappedFreeTimeService":
  //             updateCappedFreeTimeResponseValues(
  //               'cappedFreeTimeService', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           case "CappedFreeTimeRetention":
  //             updateCappedFreeTimeResponseValues(
  //               'cappedFreeTimeRetention', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           case "CappedFreeTimeInitPayment":
  //             updateCappedFreeTimeResponseValues(
  //               'cappedFreeTimeInitPayment', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           case "CappedFreeTimePromo":
  //             updateCappedFreeTimeResponseValues(
  //               'cappedFreeTimePromo', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           case "EnableCappedFreeTime":
  //             updateCappedFreeTimeResponseValues(
  //               'enableCappedFreeTime', Number(el.paramValue), setCappedFreeTimeValues
  //             );
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //     }
  //   }
  // };
  const updateCappedFreeTimeResponseValues = (key: any, value: any, setFunction: any) => {
    setFunction(prevState => ({
      ...prevState,
      [key]: value
    }));
  };

  const CommitmentsClose = () => {
    setOpenCommitments(false);
  };

  function groupBy(objectArray: any, property: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = moment(obj[property], 'hh:mm A').format('hh:00 A');
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  let Stime;
  let Etime;

  const group =
    statsBar != undefined &&
      statsBar.storeStats != undefined &&
      statsBar.storeStats.todaysCommitment != undefined &&
      statsBar.storeStats.todaysCommitment != [] &&
      statsBar.storeStats.todaysCommitment.length > 0
      ? groupBy(
        statsBar.storeStats.todaysCommitment.sort((a: any, b: any) =>
          moment(a.commitmentTime, 'hh:mm A').format('HH:mm') >
            moment(b.commitmentTime, 'hh:mm A').format('HH:mm')
            ? 1
            : -1
        ),
        'commitmentTime'
      )
      : null;

  const RedirectCommitment = (item: any) => {
    if (
      item != undefined &&
      item.customerId != undefined &&
      item.customerId != null
    ) {
      history.push(`/am/customer/${item.customerId}`);
    }
  };

  const todayComm = () => {
    return (
      <Grid container spacing={0}>
        {openTodaysCommitments()}
      </Grid>
    );
  };

  //eslint-disable-next-line sonarjs/cognitive-complexity
  const openTodaysCommitments = () => {
    return (
      <>
        <Grid className={`${classes.w100} ${classes.legendStyleModal}`}>
          <Grid style={{ float: 'right', display: 'flex' }}>
            <Grid className={`${classes.floatLeft} ${classes.me48}`}>
              <span className={classes.circleOpen} />
              <span>Open</span>
            </Grid>
            <Grid className={`${classes.floatLeft} ${classes.me48}`}>
              <span className={classes.circleBroken} />
              <span>Broken</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.gridClass}>
          <Grid>
            <Grid className={classes.listGroup}>
              <Grid className={classes.row}>
                {statsBar != undefined ? (
                  <Grid className={classes.gridClass}>
                    <Grid>
                      <Grid className={classes.listGroup}>
                        {group != undefined
                          ? Object.keys(group).map((obj: any) => {
                            return (
                              <Grid
                                className={`${classes.row} ${classes.mb3}`}
                                key=""
                              >
                                {groupdedstatsBar != undefined
                                  ? group[obj].map(
                                    (item: any, index: any) => {
                                      if (index < 1) {
                                        Stime = moment(
                                          item.commitmentTime,
                                          'hh:mm A'
                                        ).format('hh:00 A');
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        Etime = moment(
                                          item.commitmentTime,
                                          'hh:mm A'
                                        )
                                          .add(1, 'hour')
                                          .format('hh:00 A');
                                        return (
                                          <Grid
                                            className={
                                              classes.columnTimeslot
                                            }
                                            key=""
                                          >
                                            <Typography
                                              className={classes.timeslot}
                                            >
                                              {Stime} - {Etime}
                                            </Typography>
                                          </Grid>
                                        );
                                      }
                                    }
                                  )
                                  : null}
                                {groupdedstatsBar != undefined
                                  ? group[obj].map(
                                    (item: any, index: any) => {
                                      return (
                                        <Grid
                                          key=""
                                          className={`${classes.columnEvents
                                            } ${index % 4 == 0 && index != 0
                                              ? classes.marginLeftCommitment
                                              : null
                                            }`}
                                          onClick={() =>
                                            RedirectCommitment(item)
                                          }
                                        >
                                          <Typography
                                            className={`${classes.listGroupItem
                                              } ${classes.listGroupItemAction
                                              } ${classes.border} ${item.commitmentStatus ==
                                                'BR' ||
                                                item.isCommitmentTimeCrossed
                                                ? classes.upcomingpanel1
                                                : classes.upcomingpanel
                                              }`}
                                          >
                                            <Grid container>
                                              <Grid item md={8}>
                                                <Typography
                                                  className={`${classes.mb1
                                                    } ${item.commitmentStatus ==
                                                      'BR' ||
                                                      item.isCommitmentTimeCrossed
                                                      ? classes.upcomingeventsUsername1
                                                      : classes.upcomingeventsUsername
                                                    }`}
                                                >
                                                  {item.customerName}
                                                </Typography>
                                              </Grid>
                                              <Grid item md={4}>
                                                <RACChip
                                                  icon={false}
                                                  className={`${classes.chipStyle
                                                    } ${item.commitmentType ==
                                                      'ML'
                                                      ? classes.mailColor
                                                      : item.commitmentType ==
                                                        'PY'
                                                        ? classes.paymentColor
                                                        : item.commitmentType ==
                                                          'RT'
                                                          ? classes.returnColor
                                                          : item.commitmentType ==
                                                            'PO'
                                                            ? classes.payOtherStoreColor
                                                            : null
                                                    }`}
                                                  label={
                                                    item.commitmentType ==
                                                      'ML'
                                                      ? 'Mail'
                                                      : item.commitmentType ==
                                                        'PY'
                                                        ? 'Payment'
                                                        : item.commitmentType ==
                                                          'RT'
                                                          ? 'Return'
                                                          : item.commitmentType ==
                                                            'PO'
                                                            ? 'Pay at Other Store'
                                                            : null
                                                  }
                                                  color="primary"
                                                ></RACChip>
                                              </Grid>
                                            </Grid>
                                            <Grid container>
                                              <Grid item md={5}>
                                                <Typography
                                                  className={classes.mb1}
                                                >
                                                  {item.route}
                                                </Typography>
                                              </Grid>
                                              <Grid item md={7}>
                                                <Typography
                                                  className={classes.right}
                                                >
                                                  Days Past Due{' '}
                                                  {item.pastDays !=
                                                    undefined &&
                                                    item.pastDays != null && item.pastDays > 0
                                                    ? item.pastDays
                                                    : 0}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                            <RACTooltip
                                              title={item.commitmentTime}
                                              key={index}
                                              placement="bottom"
                                            >
                                              <Grid container>
                                                <Grid item md={8}>
                                                  <Typography
                                                    className={classes.mb1}
                                                  >
                                                    Commitment Amount
                                                  </Typography>
                                                </Grid>
                                                <Grid item md={4}>
                                                  <Typography
                                                    className={
                                                      classes.right
                                                    }
                                                  >
                                                    $
                                                    {item.commitmentAmount !=
                                                      undefined &&
                                                      item.commitmentAmount !=
                                                      null
                                                      ? item.commitmentAmount
                                                      : '0.00'}
                                                  </Typography>
                                                </Grid>
                                              </Grid>
                                            </RACTooltip>
                                          </Typography>
                                        </Grid>
                                      );
                                    }
                                  )
                                  : null}
                              </Grid>
                            );
                          })
                          : null}
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid></Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const todayCommitmentsClick = () => {
    setOpenCommitments(true);
  };

  const pastDueTotalClick = () => {
    history.push({
      pathname: '/am/past-due-list',
    });
  };

  return (
    <>
      {/* <Grid  container > */}
      <Grid container className={`${classes.px2} ${classes.w100} ${classes.dTabNone}`}>
        <Card
          className={`${classes.w100} ${classes.card} ${classes.cardBackground}`}
        >
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3} className={classes.alignItems}>
              <Grid item xs={12} md={cappedFreeTimeValues.enableCappedFreeTime ? 2 : 3}>
                <Grid container className={classes.statsBarContent}>
                  <Grid className={classes.totalAorContent}>Total AOR</Grid>
                  {skeleLoader ? (
                    <Grid className={classes.col7}>
                      <Typography className={classes.statsCount}>
                        {statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.totalOnRent != undefined &&
                          statsBar.storeStats.totalOnRent != null &&
                          statsBar.storeStats.totalOnRent != ''
                          ? statsBar.storeStats.totalOnRent
                          : '0'}
                      </Typography>
                      <Typography
                        className={
                          statsBar != undefined &&
                            statsBar.storeStats != undefined &&
                            statsBar.storeStats.arrowResponse != undefined &&
                            statsBar.storeStats.arrowResponse != null &&
                            statsBar.storeStats.arrowResponse != ''
                            ? parseInt(statsBar.storeStats.arrowResponse) >= 0
                              ? classes.increaseBadge
                              : classes.decreaseBadge
                            : classes.decreaseBadge
                        }
                      >
                        {statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.arrowResponse != undefined &&
                          statsBar.storeStats.arrowResponse != null &&
                          statsBar.storeStats.arrowResponse != '' ? (
                          parseInt(statsBar.storeStats.arrowResponse) >= 0 ? (
                            <UpwardArrow className={classes.arrowImage} />
                          ) : (
                            <DownArrow className={classes.arrowImage} />
                          )
                        ) : (
                          <DownArrow className={classes.arrowImage} />
                        )}
                        {statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.arrowResponse != undefined &&
                          statsBar.storeStats.arrowResponse != null &&
                          statsBar.storeStats.arrowResponse != ''
                          ? Math.abs(statsBar.storeStats.arrowResponse)
                          : '0'}
                      </Typography>
                      <Typography className={classes.dayFont}>Today</Typography>
                    </Grid>
                  ) : (
                    <Grid className={classes.col7}>
                      <Skeleton
                        count={2}
                        baseColor="#2179FE"
                        highlightColor="#e7e7e7"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={cappedFreeTimeValues.enableCappedFreeTime ? 2 : 3}>
                <Grid container className={classes.statsBarContent}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <Grid className={classes.deliveryDetails}>
                    Today{`'`}s Deliveries
                  </Grid>
                  {skeleLoader ? (
                    <Grid className={classes.deliveryCount}>
                      {statsBar != undefined &&
                        statsBar.todaysDelivery != undefined &&
                        statsBar.todaysDelivery.todaysDelivery != undefined &&
                        statsBar.todaysDelivery.todaysDelivery != null &&
                        statsBar.todaysDelivery.todaysDelivery != ''
                        ? statsBar.todaysDelivery.todaysDelivery
                        : '0'}
                    </Grid>
                  ) : (
                    <Grid className={classes.deliveryCount}>
                      <Skeleton
                        count={1}
                        baseColor="#2179FE"
                        highlightColor="#e7e7e7"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid container className={classes.statsBarContent}>
                  <Grid className={classes.deliveryDetails}>
                    Past Due Total
                  </Grid>
                  {skeleLoader ? (
                    <Grid className={classes.col9}>
                      <Typography className={classes.statsCount}>
                        {statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.pastDue != undefined &&
                          statsBar.storeStats.pastDue.totalPastDue != undefined &&
                          statsBar.storeStats.pastDue.totalPastDue != ''
                          ? Number(
                            statsBar.storeStats.pastDue.totalPastDue.split(
                              '%'
                            )[0]
                          ).toFixed(1) !== '0.0'
                            ? Number(
                              statsBar.storeStats.pastDue.totalPastDue.split(
                                '%'
                              )[0]
                            ).toFixed(1)
                            : '0'
                          : '0'}
                        %
                        <Navigation
                          onClick={
                            statsBar != undefined &&
                              statsBar.storeStats != undefined &&
                              statsBar.storeStats.pastDue != undefined &&
                              statsBar.storeStats.pastDue.totalPastDue !=
                              undefined &&
                              statsBar.storeStats.pastDue.totalPastDue != ''
                              ? Number(
                                statsBar.storeStats.pastDue.totalPastDue.split(
                                  '%'
                                )[0]
                              ).toFixed(1) !== '0.0'
                                ? pastDueTotalClick
                                : () => { }
                              : () => { }
                          }
                          className={
                            statsBar != undefined &&
                              statsBar.storeStats != undefined &&
                              statsBar.storeStats.pastDue != undefined &&
                              statsBar.storeStats.pastDue.totalPastDue !=
                              undefined &&
                              statsBar.storeStats.pastDue.totalPastDue != ''
                              ? Number(
                                statsBar.storeStats.pastDue.totalPastDue.split(
                                  '%'
                                )[0]
                              ).toFixed(1) != '0.0'
                                ? classes.navImage
                                : classes.navImageDefault
                              : classes.navImageDefault
                          }
                        />
                      </Typography>
                      <Typography className={classes.statsCountMT2}>
                        {statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.pastDue != undefined &&
                          statsBar.storeStats.pastDue.sevenPlus != undefined &&
                          statsBar.storeStats.pastDue.sevenPlus != ''
                          ? Number(
                            statsBar.storeStats.pastDue.sevenPlus.split(
                              '%'
                            )[0]
                          ).toFixed(1) !== '0.0'
                            ? Number(
                              statsBar.storeStats.pastDue.sevenPlus.split(
                                '%'
                              )[0]
                            ).toFixed(1)
                            : '0'
                          : '0'}
                        %{' '}
                        <Typography className={classes.statsPercent}>
                          7+ Days
                        </Typography>
                      </Typography>
                      <Typography className={classes.statsCountMT2}>
                        {statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.pastDue != undefined &&
                          statsBar.storeStats.pastDue.fifteenPlus != undefined &&
                          statsBar.storeStats.pastDue.fifteenPlus != ''
                          ? Number(
                            statsBar.storeStats.pastDue.fifteenPlus.split(
                              '%'
                            )[0]
                          ).toFixed(1) !== '0.0'
                            ? Number(
                              statsBar.storeStats.pastDue.fifteenPlus.split(
                                '%'
                              )[0]
                            ).toFixed(1)
                            : '0'
                          : '0'}
                        %{' '}
                        <Typography className={classes.statsPercent}>
                          15+ Days
                        </Typography>
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid className={classes.col9}>
                      <Skeleton
                        count={2}
                        baseColor="#2179FE"
                        highlightColor="#e7e7e7"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {cappedFreeTimeValues.enableCappedFreeTime ? <Grid item xs={12} md={2}>
                <Grid container className={classes.statsBarContent}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <Grid className={classes.deliveryDetails}>
                    Free Time Balance
                  </Grid>
                  {skeleLoader ? (
                    <Grid className={classes.deliveryCount}>
                      {remainingAmount && remainingAmount > 0
                        ? `$${remainingAmount.toFixed(2)}`
                        : '$0.00'}
                    </Grid>
                  ) : (
                    <Grid className={classes.deliveryCount}>
                      <Skeleton
                        count={1}
                        baseColor="#2179FE"
                        highlightColor="#e7e7e7"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
                : null}
              {/* <Grid item xs={12} md={2}>
                <Grid container className={classes.statsBarContent}>
                  <Grid className={classes.deliveryDetails}>
                    Auto Pay Failure
                  </Grid>
                  {skeleLoader ? (
                    <Grid className={classes.deliveryCount}>
                      {statsBar != undefined &&
                      statsBar.storeStats != undefined &&
                      statsBar.storeStats.autoPayFailure != undefined &&
                      statsBar.storeStats.autoPayFailure != null
                        ? statsBar.storeStats.autoPayFailure
                        : '0'}
                      <Navigation
                        onClick={
                          statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.autoPayFailure != undefined &&
                          statsBar.storeStats.autoPayFailure != null &&
                          statsBar.storeStats.autoPayFailure != 0
                            ? autoPayFailureClick
                            : () => {}
                        }
                        className={
                          statsBar != undefined &&
                          statsBar.storeStats != undefined &&
                          statsBar.storeStats.autoPayFailure != undefined &&
                          statsBar.storeStats.autoPayFailure != null &&
                          statsBar.storeStats.autoPayFailure != 0
                            ? classes.navImage
                            : classes.navImageDefault
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid className={classes.deliveryCount}>
                      <Skeleton
                        count={1}
                        baseColor="#2179FE"
                        highlightColor="#e7e7e7"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid> */}
              <Grid item xs={12} md={2}>
                <Grid container className={classes.statsBarContent}>
                  {/* eslint-disable-next-line react/no-unescaped-entities */}
                  <Grid className={classes.deliveryDetails}>
                    Today{`'`}s Commitments
                  </Grid>
                  {skeleLoader ? (
                    <Grid className={classes.commitmentCount}>
                      {statsBar != undefined &&
                        statsBar.storeStats != undefined &&
                        statsBar.storeStats.todaysCommitment != undefined &&
                        statsBar.storeStats.todaysCommitment != [] &&
                        statsBar.storeStats.todaysCommitment.length > 0
                        ? statsBar.storeStats.todaysCommitment.length
                        : '0'}
                      {statsBar != undefined &&
                        statsBar.storeStats != undefined &&
                        statsBar.storeStats.todaysCommitment != undefined &&
                        statsBar.storeStats.todaysCommitment != [] &&
                        statsBar.storeStats.todaysCommitment.length > 0 ? (
                        <Navigation
                          onClick={todayCommitmentsClick}
                          className={classes.navImage}
                        />
                      ) : null}
                    </Grid>
                  ) : (
                    <Grid className={classes.commitmentCount}>
                      <Skeleton
                        count={1}
                        baseColor="#2179FE"
                        highlightColor="#e7e7e7"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* eslint-disable-next-line react/no-children-prop */}
        <RACModalCard
          closeIcon={true}
          borderRadius="20px"
          isOpen={openCommitments}
          onClose={CommitmentsClose}
          maxWidth={'xl'}
          TitleVariantClassName={classes.commitmentModalTitle}
          title={`Todays Commitments (${statsBar != undefined &&
              statsBar.storeStats != undefined &&
              statsBar.storeStats.todaysCommitment != undefined &&
              statsBar.storeStats.todaysCommitment != [] &&
              statsBar.storeStats.todaysCommitment.length > 0
              ? statsBar.storeStats.todaysCommitment.length
              : '0'
            })`}
          //eslint-disable-next-line react/no-children-prop
          children={todayComm()}
        ></RACModalCard>
      </Grid>
      {/* </Grid> */}
    </>
  );
}
