/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react/no-children-prop */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { fieldDeviceStyles } from '../fieldDeviceStyles';
import {
  RACButton,
  RACModalCard,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  RACCOLOR,
  makeStyles,
} from '@rentacenter/racstrap';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// import { getloadGrid } from './src/api';
import { ReactComponent as Expandicon } from '../../assets/images/fullscreen-mode.svg';
import { ReactComponent as AlertIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/No-records.svg';

import CustomerSignature from '../CustomerSignarure';

import 'react-phone-number-input/style.css';

import { getOnlinetransaction, GetAgreementCustomer } from '../../api/user';
import moment from 'moment'
import { useHistory } from 'react-router-dom';

export type PropsInterface = {
  setTwoFactorCancelClick?: any;
  setTwoFactorCompleted?: any;
  // setEmployeeDetails?: any;
  moduleName?: string;
  currentRole?: string;
};
import { GetCustomerinfo } from '../../api/user';
import { number } from 'yup';
import { PENDING_VERIFICATION_STATUS } from '../../constants/constants';
import { FeatureFlagContext } from '../../context/featureFlagContext';

//UI
const useClasses = makeStyles((theme: any) => ({
  my3: {
    marginTop: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(16),
  },
  RACPOPMsg: {
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
  },
  racpadSubheader: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
  },
  listgroupitem: {
    width: '100%!important',
    padding: theme.typography.pxToRem(2),
  },
  w100: {
    width: '100%',
  },
  dinlineblock: {

    display: 'inline-block',
  },
  onlinetransagr: {
    fontSize: theme.typography.pxToRem(14),
    color: '#6F7F91',
    whiteSpace: 'nowrap',
  },
  pendingDelivery: {
    backgroundColor: '#dd4f89',
    borderRadius: theme.typography.pxToRem(50),
    color: '#fff',
    minWidth: theme.typography.pxToRem(210),
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(0),
    paddingTop: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#c72869',
    },
  },
  carryOutCBtnClass: {
    backgroundColor: '#00A0FF',
    borderRadius: theme.typography.pxToRem(50),
    color: '#fff',
    minWidth: theme.typography.pxToRem(210),
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.typography.pxToRem(0),
    paddingTop: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#00A0FF',
    },
  },
  customModal: {
    '& div': {
      '& div': {
        maxWidth: '1200px',
      },
    },
  },
  textSpaceNorecords: {
    textAlign: 'center',
    width: '100%',
  },
  textcenterNorecordsSomething: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(90),
    width: '100%',

    fontFamily: 'OpenSans-semibold',
    color: '#A5A6A6',

    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  pendingdigital: {
    background: '#FFA61E',
    color: '#fff',
    borderRadius: theme.typography.pxToRem(50),
    minWidth: theme.typography.pxToRem(210),
    fontSize: theme.typography.pxToRem(13),
    paddingTop: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#f79d14',
    },
  },
  deliveryScheduleBtnClass: {
    background: '#00A96B',
    color: '#fff',
    borderRadius: theme.typography.pxToRem(50),
    minWidth: theme.typography.pxToRem(210),
    fontSize: theme.typography.pxToRem(13),
    paddingTop: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#00A96B',
    },
  },

  verificationBtnClass: {
    background: '#B10000',
    color: '#fff',
    borderRadius: theme.typography.pxToRem(50),
    minWidth: theme.typography.pxToRem(210),
    fontSize: theme.typography.pxToRem(13),
    paddingTop: theme.typography.pxToRem(8),
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(5),
    paddingLeft: theme.typography.pxToRem(15),
    float: 'right',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#B10000',
    },
  },

  RACwidgetuser: {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
    color: '#000',
  },
  mb2: {
    marginBottom: theme.typography.pxToRem(10),
  },

  title: {
    color: '#212529',

    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(10),
  },
  padding3: {
    padding: theme.typography.pxToRem(15),
  },
  marginbottom3: {
    marginBottom: theme.typography.pxToRem(15),
  },
  formlabel: {
    marginBottom: theme.typography.pxToRem(8),
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  formcheck: {
    display: 'block',
    minHeight: theme.typography.pxToRem(24),
    paddingleft: theme.typography.pxToRem(24),
    marginBottom: theme.typography.pxToRem(2),
  },
  formcheckinline: {
    display: 'inline-block',
    marginRight: theme.typography.pxToRem(16),
  },
  marginright2: {
    marginRight: theme.typography.pxToRem(8),
  },
  formcheckinput: {
    width: theme.typography.pxToRem(16),
    height: theme.typography.pxToRem(16),
    marginTop: theme.typography.pxToRem(4),
    verticalAlign: 'top',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    border: '1px solid rgba(0, 0, 0, 0.25)',
  },
  padding1: {
    paddingRight: '0.25rem',
    paddingLeft: '0.25rem',
  },
  RACcardsales: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0px 1px 4px 0px  #0000013',
    position: 'relative',
    maxHeight: theme.typography.pxToRem(303),

    '&:hover': {
      '& $RACexpandIcon': {
        display: 'block',
      },
    },
  },
  RACcardHeight: {
    height: theme.typography.pxToRem(303),
  },
  card: {
    borderRadius: theme.typography.pxToRem(16),
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)',
    width: '100%',
  },
  spaceTitle: {
    marginBottom: theme.typography.pxToRem(0),
    paddingBottom: theme.typography.pxToRem(0),
  },
  RACloaderNorecords: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(75),
    fontSize: theme.typography.pxToRem(16),
    color: `${RACCOLOR.RICH_BLACK}`,
    fontWeight: theme.typography.pxToRem(580),
    marginLeft: theme.typography.pxToRem(30),
    marginRight: theme.typography.pxToRem(30),
    fontFamily: 'OpenSans-regular',
  },
  textcenter: {
    textAlign: 'center',
  },
  textcenterNorecords: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(60),
    width: '100%',
    fontFamily: 'OpenSans-regular',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  alerticon: {
    paddingRight: theme.typography.pxToRem(10),
    paddingTop: theme.typography.pxToRem(8),
  },
  fixedheadertableonline: {
    overflowY: 'auto',
    maxHeight: theme.typography.pxToRem(235),
  },
  RACexpandIcon: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
    display: 'none',
  },
  popuptitle: {
    width: 'auto',
    overFlowY: 'auto',
  },
  transactionSpacing: {
    alignItems: 'center',
    marginBottom: '5px',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  pb0: {
    paddingBottom: 0,
  },
  px2: {
    paddingRight: theme.typography.pxToRem(8),
    paddingLeft: theme.typography.pxToRem(8),
  },
  fixedheadertableonlinePopup: {
    overflowY: 'auto',
    maxHeight: theme.typography.pxToRem(450),
  },
  RACexpandIconclose: {
    width: theme.typography.pxToRem(22),
    height: theme.typography.pxToRem(22),
    right: theme.typography.pxToRem(4),
    top: theme.typography.pxToRem(5),
    position: 'absolute',
    cursor: 'pointer',
  },
  p3: {
    padding: theme.typography.pxToRem(16),
  },
  mb3: {
    marginBottom: theme.typography.pxToRem(16),
  },
  formLabel: {
    marginBottom: '0.2rem',
    color: '#111111',
    fontFamily: 'OpenSans-bold',
    fontSize: theme.typography.pxToRem(14),
  },
  formCheck: {
    display: ' block',
    minHeight: '1.5rem',
    paddingLeft: '1.5em',
    marginBottom: ' 0.125rem',
  },
  ms2: {
    marginLeft: theme.typography.pxToRem(8),
  },
  formCheckInline: {
    display: 'inline-block',
    marginRight: '1rem',
  },
  formCheckinput: {
    width: '1em',
    height: '1em',
    marginTop: ' 0.25em',
    verticalAlign: 'top',
    backgroundColor: ' #fff',
    backgroundRepeat: ' no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    border: ' 1px solid rgba(0, 0, 0, 0.25)',
  },
  ms5: {
    marginLeft: theme.typography.pxToRem(48),
  },
  ms1: {
    marginLeft: theme.typography.pxToRem(4),
  },

  mt1: {
    marginTop: theme.typography.pxToRem(4),
  },
  floatLeft: {
    float: 'left',
  },
  mandatoryfield: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
  },
  spaceNowrap: {
    whiteSpace: 'nowrap',
  },
  mt2: {
    marginTop: theme.typography.pxToRem(8),
  },
  txtLabelStyle: {
    fontFamily: 'OpenSans-semibold',
  },
  textEnd: {
    textAlign: 'right',
  },

  pb4: {
    paddingBottom: theme.typography.pxToRem(24),
  },
  px3: {
    paddingRight: theme.typography.pxToRem(16),
    paddingLeft: theme.typography.pxToRem(16),
  },
  mt4: {
    marginTop: theme.typography.pxToRem(24),
  },
  mx1: {
    marginRight: theme.typography.pxToRem(4),
    marginLeft: theme.typography.pxToRem(4),
  },
  textCenter: {
    textAlign: 'center',
  },
  racErrorIcon: {
    width: '50px',
  },
  racErrorFunction: {
    fontFamily: 'OpenSans-semibold',
    marginBottom: '20px',
    marginTop: '10px',
  },
  textcenterImage: {
    textAlign: 'center',
    marginTop: theme.typography.pxToRem(20),
    width: '100%',
    fontFamily: 'OpenSans-semibold',
    color: '#A5A6A6',
    fontSize: theme.typography.pxToRem(14),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  me2: {
    marginRight: theme.typography.pxToRem(8),
  },
  RACLoaderPage: {
    textAlign: 'center',
    marginTop: '250px',
    marginLeft: '30px',
    marginRight: '30px',
  },
  racPinError: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    marginTop: '5px',
    marginLeft: '0.5rem',
    fontFamily: 'OpenSans-semibold',
  },
  floatRight: {
    float: 'right',
  },
  spacerMT5: {
    marginTop: theme.typography.pxToRem(48),
  },
  spacerMB3: {
    marginBottom: theme.typography.pxToRem(24),
  },
  spacerME2: {
    marginRight: theme.typography.pxToRem(16),
  },
  racPinContainer: {
    margin: '0 auto',
    width: '85%',
    paddingLeft: '15px',
  },
  racPinLabel: {
    fontFamily: 'OpenSans-semibold',
    color: `${RACCOLOR.RICH_BLACK}`,
    marginLeft: '0.5rem',
    marginBottom: '3px',
  },
  racLoader: {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, 85%)',
    transition: 'opacity 0.15s linear ',
  },
  logoResponsive: {},
  spacerMargin: {
    margin: theme.typography.pxToRem(8),
  },
  racLoginAlert: {
    color: `${RACCOLOR.MAXIMUM_RED}`,
    fontFamily: 'OpenSans-semibold',
    fontSize: theme.typography.pxToRem(14),
  },
  spacerMT1: {
    marginTop: theme.typography.pxToRem(4),
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  disablePhonenumberInput: {
    backgroundColor: '#d3d3d3 !important',
  },
  formControlPh: {
    display: 'block',
    width: '100%',
    padding: '0.155rem 0.66rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#212529',
    backgroundColor: '#fff',

    border: '1px solid #ced4da',

    appearance: 'none',
    borderRadius: '0.35rem',
    whiteSpace: 'nowrap',

    '&:focus-visible': {
      boxShadow: '0 0 0 0.25rem rgb(0 123 255 / 25%)',
      borderColor: '#80bdff',
      borderRadius: '8px',
      outline: 'none',
    },
  },
  paddingButtons: {
    paddingRight: '45px',
  },
  py4: {
    paddingTop: theme.typography.pxToRem(24),
    paddingBottom: theme.typography.pxToRem(24),
  },
  fontHeading: {
    fontSize: theme.typography.pxToRem(15),

    fontFamily: 'OpenSans-bold',
  },
  semiBold: {
    fontFamily: 'OpenSans-semibold',
  },
  masterLoader: {
    position: 'fixed',
    backgroundColor: `${RACCOLOR.WASHED_BLACK}`,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    opacity: 0.6,
    zIndex: 1050,
    textAlign: 'center',
    margin: 0,
  },
  Loader: {
    display: 'block',
    position: 'fixed',
    zIndex: 1051,
    top: '50%',
    right: '50%',
  },
  me4: {
    marginRight: theme.typography.pxToRem(24),
  },
  iframeClass: {
    height: '500px',
    width: '100%',
  },
  mb1: {
    marginBottom: theme.typography.pxToRem(4),
  },
  ms3: {
    marginLeft: theme.typography.pxToRem(16),
  },
  racpadLink: {
    color: `${RACCOLOR.DEEP_SKY_BLUE}`,
    fontSize: theme.typography.pxToRem(14),
    textDecoration: 'none',
    cursor: 'pointer',
    fontFamily: 'OpenSans-bold',
  },
  textRight: {
    textAlign: 'right',
  },
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
/* eslint-disable */
export default function OnlineTransaction() {
  const history = useHistory();
  const PENDING_INSTALLATION_STATUS = ['INPENDING', 'INSSCH', 'INSCONFM', 'INSCANC', 'INSMISSED']
  const classes1 = fieldDeviceStyles();
  //param pass=>store_id: any

  //Iframe datas

  //popup after IFRAME
  const classes = useClasses();
  const { customerVerification } = useContext(FeatureFlagContext);

  //expand Icon modal
  const [onlinePop, setOnlinePop] = React.useState(false);

  const [masterLoader, setloader] = React.useState(false);
  const [loader1, setloader1] = React.useState(false);

  const [onlineTransactionData, setonlineTransactionData] = React.useState([]);
  const [StatusError, setStatusError] = React.useState('');
  //shared components
  const [maualSignReasonDrpdwnVal, setmaualSignReasonDrpdwnVal] = React.useState([
    {
      value: '',
      label: 'Select',
    },
    {
      value: '3',
      label: 'Technology issue - Digital Signing',
    },
    {
      value: '2',
      label: 'Technology issue - Customer',
    },
    {
      value: '1',
      label: 'Customer requires Paper Signature',
    },
  ]);

  const [customerSignEnabled, setcustomerSignEnabled] = React.useState(false);

  const [customersArray, setcustomersArray]: any = React.useState([]);
  const [agreementId, setAgreementId] = React.useState('');
  const [onlineTransactionEventSearch, setonlineTransactionEventSearch] = React.useState<any>([]);
  const [errorMsgPopup, seterrorMsgPopup] = React.useState<any>(false);


  //to load Iframe API
  useEffect(() => {
    const fetch = async () => {
      setloader1(true);
      let storeNumber = window.sessionStorage.getItem('storeNumber')

      const response: any = await getOnlinetransaction(storeNumber);
      console.log('Lambda responseOnline : ', response);
      setloader1(false);
      if (response.data !== undefined && response.status === 200) {
        if (response.data?.onlineTransactionResponse?.value?.getOnlineTransRes?.length > 0 ) {
          // setonlineTransactionData(props.OnlineGridData);
          setonlineTransactionData(
            response.data?.onlineTransactionResponse?.value?.getOnlineTransRes
              ? response.data?.onlineTransactionResponse?.value?.getOnlineTransRes?.sort((a, b) => b.agreementId - a.agreementId)
              : []
          );
        }
        if (response?.data?.storeSearchEventResponse?.value?.appointments?.length > 0 ) {
          setonlineTransactionEventSearch(response.data.storeSearchEventResponse.value.appointments)
        }
      }

      setStatusError(response.status.toString())

    };
    fetch();
  }, []);


  const ReprintPopupOpen = async (agreementId: string) => {


    setloader(true);
    const result = await GetCustomerinfo(agreementId);

    console.log('Lambda response : ', result.data);
    setloader(false);
    const resultcheck = result.data ? result.data : undefined;
    if (result.status == 200 && resultcheck != undefined) {
      if (result.data.coCustomer.length == 0) {

        console.log('No Co Customer : ', result.data);


        const CustomerObj = [
          {
            customerId: result.data.customer[0].customerId,
            customerName: result.data.customer[0].customerName,
            phoneNumber: result.data.customer[0].phoneNumber,
            emailId: result.data.customer[0].emailAddress,
          },
        ];
        setcustomersArray(CustomerObj);
      }
      //if(result.data.coCustomer.rows !==[])
      else {
        if (result.data.coCustomer[0].isDocumentGenerated === '1') {

          console.log('Co Customer : ', result.data);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
            {
              emailId: result.data.coCustomer[0].emailAddress,
              customerName: result.data.coCustomer[0].customerName,
              phoneNumber: result.data.coCustomer[0].phoneNumber,
              customerId: result.data.coCustomer[0].customerId,
            },
          ];
          setcustomersArray(CustomerObj);
        } else {

          console.log('No Co Customer : ', result.data);

          console.log('customerdatalog', result.data.customer);
          const CustomerObj = [
            {
              customerId: result.data.customer[0].customerId,
              customerName: result.data.customer[0].customerName,
              phoneNumber: result.data.customer[0].phoneNumber,
              emailId: result.data.customer[0].emailAddress,
            },
          ];
          setcustomersArray(CustomerObj);
        }
      }
      setcustomerSignEnabled(true);
    }
  };

  const onClickPendingSignature = async (obj: any) => {
    if (obj?.installerStatus == 'INSCOMPTD' && obj?.storeActionItemId && obj?.purchaseOrderNumber) {
      if(obj?.purchaseorderStatus == 'RCV'){
        history.push({ pathname: `/agreement/info/details/${obj?.customerId}/${obj?.agreementId}`, })
        return
      }
      else{
        history.push({ pathname: `/receivingpo/purchaseorders`, search: `?origin=TB&saiid=${obj?.storeActionItemId}&ponumber=${obj?.purchaseOrderNumber}&custid=${obj?.customerId}&agrid=${obj?.agreementId}` })
        return
      }
    } else if (obj?.installerStatus) {
      history.push({ pathname: `/agreement/info/details/${obj?.customerId}/${obj?.agreementId}`, })
      return
    }
    setAgreementId(obj?.agreementId);
    ReprintPopupOpen(obj?.agreementId)

  };

  /**************************** ONLINE TRANSACTION  ************************** */
  //online transaction grid to bind the datas dynamically from api using map function btn-onlinetrans-pending-delivery
  const buildGrid = () => {

    if (onlineTransactionData.length > 0) {
      return onlineTransactionData.map((obj: any, index: any) => {

        return (
          <Grid key='some' className={` ${classes1.wTab50}`}>
            <Grid

              className={`${classes.listgroupitem} ${classes1.listgroupitemTab}`}
              aria-current='true'
            >
              {PENDING_INSTALLATION_STATUS.includes(obj?.installerStatus) ?
                <Grid container spacing={0}
                  className={classes.transactionSpacing}>

                  <Grid item sm={6} md={4} className={classes.dinlineblock}>
                    <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                      {obj.lastName} {obj.firstName}
                    </Typography>
                    <Typography variant="body2" className={classes.onlinetransagr}>
                      Agr#: {obj.agreementNumber}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} md={8} className={classes.dinlineblock} >
                    <RACButton


                      onClick={() => onClickPendingDelivery(obj)}
                      className={`${classes.pendingdigital} ${classes1.meTab4}`}
                    >
                      {'Pending Installation'}

                    </RACButton>

                  </Grid>
                </Grid> : ['INSCOMPTD'].includes(obj.installerStatus) ?
                  <Grid container spacing={0}
                    className={classes.transactionSpacing}>
                    <Grid item sm={6} md={4} className={classes.dinlineblock}>
                      <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                        {obj.lastName} {obj.firstName}
                      </Typography>
                      <Typography variant="body2"
                        className={classes.onlinetransagr}>
                        Agr#: {obj.agreementNumber}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} md={8} className={classes.dinlineblock} >
                      <RACButton

                        onClick={() => onClickPendingSignature(obj)}
                        className={`${classes[`${['INSCOMPTD'].includes(obj.installerStatus) ? 'deliveryScheduleBtnClass' : 'pendingDelivery'}`]} ${classes1.meTab4}`}>
                        {'Confirm Installation'}
                      </RACButton>

                    </Grid>
                  </Grid> : null}
              {obj.appointmentId === null && !obj?.installerStatus ? (
                <>
                  <Grid container spacing={0}
                    className={classes.transactionSpacing}>

                    <Grid item sm={6} md={4} className={classes.dinlineblock}>
                      <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                        {obj.lastName} {obj.firstName}
                      </Typography>
                      <Typography variant="body2" className={classes.onlinetransagr}>
                        Agr#: {obj.agreementNumber}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} md={8} className={classes.dinlineblock} >
                      <RACButton


                        onClick={() => onClickPendingDelivery(obj)}
                        className={`${classes.pendingDelivery} ${classes1.meTab4}`}
                      >
                        {'Pending Delivery Schedule'}

                      </RACButton>

                    </Grid>
                  </Grid>

                </>
              ) : (((obj.appointmentId !== null && obj.signStatusId === 1) && !obj.installerStatus) ? <>
                <Grid container spacing={0}
                  className={classes.transactionSpacing}>
                  <Grid item sm={6} md={4} className={classes.dinlineblock}>
                    <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                      {obj.lastName} {obj.firstName}
                    </Typography>
                    <Typography variant="body2"
                      className={classes.onlinetransagr}>
                      Agr#: {obj.agreementNumber}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} md={8} className={classes.dinlineblock} >
                    <RACButton

                      onClick={() => onClickPendingSignature(obj)}
                      className={`${classes[`${['INSCOMPTD'].includes(obj.installerStatus) ? 'deliveryScheduleBtnClass' : 'pendingDelivery'}`]} ${classes1.meTab4}`}>
                      {'Pending Digital Signature'}
                    </RACButton>

                  </Grid>
                </Grid>
              </> : null)}

            </Grid>
          </Grid>
        );

      });
    }
  };
  const handleEventSearchItem = async (obj: any) => {
    if (obj?.appointmentInfo?.appointmentType == 'D' || obj?.appointmentInfo?.appointmentType == 'CarryOut') {
      if (obj?.customerInfo?.customerId && obj?.agreement?.agreementId) {
        window.location.href = `agreement/info/delivery/${obj?.customerInfo?.customerId}/${obj?.agreement?.agreementId}`;
      } else {
        try {
          setloader(true)
          const getAgreementCustomerResponse: any = await GetAgreementCustomer(obj?.agreement?.agreementId)
          console.log('getAgreementCustomerResponse', getAgreementCustomerResponse)
          setloader(false)
          if (getAgreementCustomerResponse.status == 200 && getAgreementCustomerResponse.data && getAgreementCustomerResponse.data.customer && getAgreementCustomerResponse.data?.customer.length > 0) {
            window.location.href = `agreement/info/delivery/${getAgreementCustomerResponse?.data?.customer[0]?.customerId}/${getAgreementCustomerResponse?.data?.customer[0]?.agreementId}`;
          }
          if (getAgreementCustomerResponse.status == 400) {
            seterrorMsgPopup(true)
          }
        } catch (e: any) {
          seterrorMsgPopup(true)
        }
      }
    }
  }
  const buildGridForEventSearch = () => {
    if (onlineTransactionEventSearch.length > 0) {
      return onlineTransactionEventSearch.map((obj: any, index: any) => {
        return (
          <Grid key='some'>
            <Grid
              className={classes.listgroupitem}
              aria-current='true'
            >
              {(customerVerification == 1 &&
                ['D', 'CarryOut'].includes(obj?.appointmentInfo?.appointmentType) &&
                PENDING_VERIFICATION_STATUS.includes(obj?.approvals?.verificationStatus)) ?
                (
                  <>
                    <Grid container spacing={0}
                      className={classes.transactionSpacing}>
                      <Grid item md={4} sm={6} className={classes.dinlineblock}>
                        <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                          {obj?.customerInfo?.lastName} {obj?.customerInfo?.firstName}
                        </Typography>
                        <Typography variant="body2" className={classes.onlinetransagr}>
                          Agr#: {obj?.agreement?.agreementNumber}
                        </Typography>
                        <Typography variant="body2" className={classes.onlinetransagr}>
                          {moment(obj?.appointmentInfo.appointmentDate).format('MM/DD/YYYY')} {obj.timeSlotInfo && `${moment(obj.timeSlotInfo.startTime, "HH:mm:ss").format("h:mm A")} ${moment(obj.timeSlotInfo.endTime, "HH:mm:ss").format("h:mm A")}`}
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={6} className={classes.dinlineblock} >
                        <RACButton
                          onClick={() => onClickPendingVerification(obj)}
                          className={classes.verificationBtnClass}
                        >
                          {'Verification Required'}
                        </RACButton>
                      </Grid>
                    </Grid>
                  </>
                )
                : (['D'].includes(obj?.appointmentInfo?.appointmentType)) ?
                  (
                    <>
                      <Grid container spacing={0}
                        className={classes.transactionSpacing}>
                        <Grid item md={4} sm={6} className={classes.dinlineblock}>
                          <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                            {obj?.customerInfo?.lastName} {obj?.customerInfo?.firstName}
                          </Typography>
                          <Typography variant="body2" className={classes.onlinetransagr}>
                            Agr#: {obj?.agreement?.agreementNumber}
                          </Typography>
                          <Typography variant="body2" className={classes.onlinetransagr}>
                            {moment(obj?.appointmentInfo.appointmentDate).format('MM/DD/YYYY')} {obj.timeSlotInfo && `${moment(obj.timeSlotInfo.startTime, "HH:mm:ss").format("h:mm A")} ${moment(obj.timeSlotInfo.endTime, "HH:mm:ss").format("h:mm A")}`}
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={6} className={classes.dinlineblock} >
                          <RACButton
                            onClick={() => handleEventSearchItem(obj)}
                            className={obj?.appointmentInfo?.appointmentType == 'CarryOut' ? classes.carryOutCBtnClass : classes.deliveryScheduleBtnClass}
                          >
                            {obj?.appointmentInfo?.appointmentType == 'CarryOut' ? 'Carry Out Scheduled' : obj?.appointmentInfo?.appointmentType == 'D' ? 'Delivery Scheduled' : null}
                          </RACButton>
                        </Grid>
                      </Grid>
                    </>
                  )
                  : null
              }
            </Grid>
          </Grid>
        );
      });
      // return onlineTransactionEventSearch.map((obj: any, index: any) => {
      //   if (['D'].includes(obj?.appointmentInfo?.appointmentType)) {
      //     return (
      //       <Grid key='some'>
      //         <Grid
      //           className={classes.listgroupitem}
      //           aria-current='true'
      //         >
      //           {/* {obj.type === null ? ( */}
      //           <>
      //             <Grid container spacing={0}
      //               className={classes.transactionSpacing}>
      //               <Grid item md={4} sm={6} className={classes.dinlineblock}>
      //                 <Typography variant="subtitle2" className={classes.RACwidgetuser}>
      //                   {obj?.customerInfo?.lastName} {obj?.customerInfo?.firstName}
      //                 </Typography>
      //                 <Typography variant="body2" className={classes.onlinetransagr}>
      //                   Agr#: {obj?.agreement?.agreementNumber}
      //                 </Typography>
      //                 <Typography variant="body2" className={classes.onlinetransagr}>
      //                   {moment(obj?.appointmentInfo.appointmentDate).format('MM/DD/YYYY')} {obj.timeSlotInfo && `${moment(obj.timeSlotInfo.startTime, "HH:mm:ss").format("h:mm A")} ${moment(obj.timeSlotInfo.endTime, "HH:mm:ss").format("h:mm A")}`}
      //                 </Typography>
      //               </Grid>
      //               <Grid item md={8} sm={6} className={classes.dinlineblock} >
      //                 <RACButton
      //                   onClick={() => handleEventSearchItem(obj)}
      //                   className={obj?.appointmentInfo?.appointmentType == 'CarryOut' ? classes.carryOutCBtnClass : classes.deliveryScheduleBtnClass}
      //                 >
      //                   {obj?.appointmentInfo?.appointmentType == 'CarryOut' ? 'Carry Out Scheduled' : obj?.appointmentInfo?.appointmentType == 'D' ? 'Delivery Scheduled' : null}
      //                 </RACButton>
      //               </Grid>
      //             </Grid>
      //           </>
      //           {/* ) : (obj.appointmentId !== null && obj.signStatusId === 1 ? <>
      //           <Grid container spacing={0}
      //             className={classes.transactionSpacing}>
      //             <Grid item md={4} className={classes.dinlineblock}>
      //               <Typography variant="subtitle2" className={classes.RACwidgetuser}>
      //                 {obj.lastName} {obj.firstName}
      //               </Typography>
      //               <Typography variant="body2"
      //                 className={classes.onlinetransagr}>
      //                 Agr#: {obj.agreementNumber}
      //               </Typography>
      //             </Grid>
      //             <Grid item md={8} className={classes.dinlineblock} >
      //               <RACButton

      //                 onClick={() => onClickPendingSignature(obj.agreementId)}
      //                 className={classes.pendingdigital}>
      //                 Pending Digital Signature
      //               </RACButton>

      //             </Grid>
      //           </Grid>
      //         </> : null)} */}

      //         </Grid>
      //       </Grid>
      //     );
      //   }
      // });
    }
  };
  const onlineTransactionPopup = () => {
    setOnlinePop(true);
  };

  /***************************************EXPAND ONLINE TRANSACTION POPUP ************************************/

  const expandOnlinePop = () => {

    if (onlineTransactionData.length > 0) {
      return <> {
        onlineTransactionData.map((obj: any, index: any) => {
        return (

          <Grid

            className={classes.listgroupitem}
            aria-current='true'
          >
            {PENDING_INSTALLATION_STATUS.includes(obj?.installerStatus) ?
              <Grid container spacing={0}
                className={classes.transactionSpacing}>

                <Grid item sm={6} md={4} className={classes.dinlineblock}>
                  <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                    {obj.lastName} {obj.firstName}
                  </Typography>
                  <Typography variant="body2" className={classes.onlinetransagr}>
                    Agr#: {obj.agreementNumber}
                  </Typography>
                </Grid>
                <Grid item sm={6} md={8} className={classes.dinlineblock} >
                  <RACButton


                    onClick={() => onClickPendingDelivery(obj)}
                    className={`${classes.pendingdigital} ${classes1.meTab4}`}
                  >
                    {'Pending Installation'}

                  </RACButton>

                </Grid>
              </Grid> : ['INSCOMPTD'].includes(obj.installerStatus) ?
                <Grid container spacing={0}
                  className={classes.transactionSpacing}>
                  <Grid item sm={6} md={4} className={classes.dinlineblock}>
                    <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                      {obj.lastName} {obj.firstName}
                    </Typography>
                    <Typography variant="body2"
                      className={classes.onlinetransagr}>
                      Agr#: {obj.agreementNumber}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} md={8} className={classes.dinlineblock} >
                    <RACButton

                      onClick={() => onClickPendingSignature(obj)}
                      className={`${classes[`${['INSCOMPTD'].includes(obj.installerStatus) ? 'deliveryScheduleBtnClass' : 'pendingDelivery'}`]} ${classes1.meTab4}`}>
                      {'Confirm Installation'}
                    </RACButton>

                  </Grid>
                </Grid> : null}
            {obj.appointmentId === null && !obj?.installerStatus ? (
              <>
                <Grid container spacing={0}
                  className={classes.transactionSpacing}>

                  <Grid item md={4} sm={6} className={classes.dinlineblock}>
                    <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                      {obj.lastName} {obj.firstName}
                    </Typography>
                    <Typography variant="body2" className={classes.onlinetransagr}>
                      Agr#: {obj.agreementNumber}
                    </Typography>
                  </Grid>
                  <Grid item md={8} sm={6} className={classes.dinlineblock} >
                    <RACButton


                      onClick={() => onClickPendingDelivery(obj)}
                      className={classes.pendingDelivery}
                    >

                      {'Pending Delivery Schedule'}

                    </RACButton>

                  </Grid>
                </Grid>



              </>
            ) : (((obj.appointmentId !== null && obj.signStatusId === 1) && !obj?.installerStatus) ? <>
              <Grid container spacing={0}
                className={classes.transactionSpacing}>
                <Grid item md={4} className={classes.dinlineblock}>
                  <Typography variant="subtitle2" className={classes.RACwidgetuser}>
                    {obj.lastName} {obj.firstName}
                  </Typography>
                  <Typography variant="body2"
                    className={classes.onlinetransagr}>
                    Agr#: {obj.agreementNumber}
                  </Typography>
                </Grid>
                <Grid item md={8} className={classes.dinlineblock} >
                  <RACButton

                    onClick={() => onClickPendingSignature(obj)}
                    className={`${classes[`${['INSCOMPTD'].includes(obj.installerStatus) ? 'deliveryScheduleBtnClass' : 'pendingdigital'}`]} ${classes1.meTab4}`}>
                    {'Pending Digital Signature'}
                  </RACButton>

                </Grid>
              </Grid>
            </> : null)}
          </Grid>

        );
      })}
      {buildGridForEventSearch()}
      </>
    }
  };
  const ErrorMsgPopup = () => {
    return (
      <Grid item md={12}>
        <Grid item md={12} className={classes.textCenter}>
          <AlertIcon></AlertIcon>
          <Typography
            variant={'body2'}
            className={`${classes.my3} ${classes.RACPOPMsg}`}
          >
            Invalid Data
          </Typography>
        </Grid>
        <Grid
          item
          className={`${classes.textCenter} ${classes.mt4} ${classes.w100}`}
        >
          <RACButton
            variant="contained"
            color="primary"
            onClick={() => seterrorMsgPopup(false)}
          >
            Ok
          </RACButton>
        </Grid>
      </Grid>
    );
  };

  /******************* END OF EXPAND ONLINE POP UP- ENDS HERE ****************/
  const onClickPendingDelivery = async (obj: any) => {
    if (obj?.installerStatus == 'INSCOMPTD' && obj?.storeActionItemId && obj?.purchaseOrderNumber) {
      if(obj?.purchaseorderStatus == 'RCV'){
        history.push({ pathname: `/agreement/info/details/${obj?.customerId}/${obj?.agreementId}`, })
        return
      }
      else{
        history.push({ pathname: `/receivingpo/purchaseorders`, search: `?origin=TB&saiid=${obj?.storeActionItemId}&ponumber=${obj?.purchaseOrderNumber}&custid=${obj?.customerId}&agrid=${obj?.agreementId}` })
        return
      }
    } else if (obj?.installerStatus) {
      history.push({ pathname: `/agreement/info/details/${obj?.customerId}/${obj?.agreementId}`, })
      return
    }
    window.location.href = `agreement/rental/delivery/${obj?.customerId}/${obj?.agreementId}`;
  };

  const onClickPendingVerification = async (obj: any) => {
    history.push({ pathname: `/customer/update/${obj?.customerInfo?.customerId}/customer` })
    return
  }

  const customerSignatureCompletedFn = () => {
    console.log('Customer sign completed');
    setcustomerSignEnabled(false);
  };
  const customerSignatureCanceledFn = () => {

    console.log('Customer sign canceled');

    setcustomerSignEnabled(false);

  };
  const closeClick = () => {
    setOnlinePop(false);
  }
  const onlinePopupDiv = () => {
    return (

      <Grid container spacing={0} className={classes.fixedheadertableonlinePopup} key='uniqueonlinetransaction'>

        <Expandicon
          className={classes.RACexpandIconclose}
          data-bs-toggle='modal'
          data-bs-target='#onlineTransaction'
          onClick={() => closeClick()}
          data-testid='expandonlineClose'
          key='gridcomponentIcon'
        ></Expandicon>
        {expandOnlinePop()}</Grid>

    )
  }

  return (
    <>
      <Grid item lg={4} xl={4} sm={12} md={12} xs={12} className={`${classes.px2} ${classes.mb3} ${classes1.widthtab100}`}>

        {masterLoader ? (

          <Grid className={classes.masterLoader}>

            <Grid className={classes.Loader}>

              <CircularProgress />

            </Grid>

          </Grid>

        ) : null}


        <Card className={`${classes.RACcardsales} ${classes.RACcardHeight} ${classes.card}`}>
          <CardContent>
            {/* <Grid container> */}
            <Grid item md={12} className={classes.popuptitle}>
              <Typography variant="h6" className={`${classes.title} ${classes.spaceTitle} `}>Online Transactions</Typography>
              {loader1 === true ? (
                <Grid className={`${classes.RACloaderNorecords} ${classes.textcenter} ${classes.w100}`}>
                  <CircularProgress />
                </Grid>
              ) : null}

            </Grid>
            {


              StatusError !== '200' && loader1 == false ? <Grid className={classes.textcenterNorecordsSomething}>
                <AlertIcon className={classes.racErrorIcon}>{''}</AlertIcon>
                Something went Wrong!
              </Grid> :
                Object.keys(onlineTransactionData).length === 0 && onlineTransactionEventSearch.length == 0 && loader1 == false ?
                  (<Grid className={classes.textSpaceNorecords}>
                    <Grid className={classes.textcenterNorecords}> <InfoIcon className={classes.racErrorIcon}>
                    </InfoIcon></Grid>
                    <Grid className={classes.textcenterImage}> No Records Found</Grid>


                  </Grid>)
                  : (
                    <Grid item md={12} className={`${classes.fixedheadertableonline} ${classes1.tabFlexWrap}`}>
                      {buildGrid()}
                      {buildGridForEventSearch()}
                    </Grid>)}
            {Object.keys(onlineTransactionData).length !== 0 ?
              <Expandicon
                className={classes.RACexpandIcon}
                data-bs-toggle='modal'
                data-bs-target='#salesleads'
                onClick={() => onlineTransactionPopup()}
                data-testid='expandonlineIcon'
                key='popupdata'
              >
              </Expandicon> : null}
          </CardContent>

        </Card>
      </Grid>
      {/* if no records found , hide expand popup  */}

      <RACModalCard
        isOpen={onlinePop}
        maxWidth="xs"
        onClose={() => closeClick()}
        closeIcon={false}
        children={onlinePopupDiv()}
        dialogOptionalProps={{ open: onlinePop, disableBackdropClick: false }}
        title=" Online Transactions"
        TitleClassName="title"
        className="title "
        borderRadius="20px"
        key='modalKeycard'
        data-testid='titleCard'
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={errorMsgPopup}
        maxWidth="xs"
        closeIcon={true}
        onClose={() => seterrorMsgPopup(false)}
        // eslint-disable-next-line react/no-children-prop
        children={ErrorMsgPopup()}
      />


      {customerSignEnabled ? (

        <CustomerSignature
          customerSignUsingField='dashBoard'
          customerSignUsingModule='rental'
          documentGenerateCompleted={true}
          identifier={Number(agreementId)}
          customersArr={customersArray}
          manualSignOptions={maualSignReasonDrpdwnVal}
          documentTypeArr={[]}
          customerSignatureCompletedFn={() => customerSignatureCompletedFn()}
          customerSignatureCanceledFn={() => customerSignatureCanceledFn()}
        />

      ) : null}



    </>

  );
  
}

